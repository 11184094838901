<mat-card id="assetcontainer">
  <mat-form-field  appearance="outline" class="search">
    <mat-label>Ricerca Planimetria</mat-label>
    <input matInput type="text" [(ngModel)]="searchinput" (keydown.enter)="ricercaPlan()">
    <button mat-button *ngIf="searchinput" matSuffix mat-icon-button aria-label="Clear" (click)="searchinput=''; allassetsrc = allasset;">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <div id="allassetcont">
    <button mat-raised-button color="primary" id="singleasset" [style.background-color]="plan == selectedplan ? '#505ba1' : 'rgb(210, 210, 210)'"
      *ngFor="let plan of allplansrc" (click)="SelPlan(plan)">{{plan}}</button>
  </div>
</mat-card>
