import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/Login/authentication.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: [
    './navbar.component.scss',
    '../../../app.component.scss'
  ]
})
export class NavbarComponent implements OnInit {

  nebulaImg: string = environment.imageUrl + 'nebula.png';
  androidImg: string = environment.imageUrl + 'android.png';
  iosImg: string = environment.imageUrl + 'Apple.png';
  usrimg: string = localStorage.getItem('image');
  userprivilege = localStorage.getItem('priv');
  role = localStorage.getItem('role');

  constructor(private router: Router) { }

  ngOnInit(): void {
  }


  navigateTo(): void {
    localStorage.setItem("CLOUD", "C");
    this.router.navigate(["/cde"], { queryParams: { path: '/REPO/CORPORATE_FOLDER/'} });
  }
}
