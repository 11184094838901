import { Component, ViewEncapsulation, ViewChild, ElementRef, PipeTransform, Pipe, OnInit, Input } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from '@angular/router';
import { HelmService } from 'src/app/services/helm.service';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Component({
  selector: 'app-godot',
  templateUrl: './godot.component.html',
  styleUrls: ['./godot.component.scss']
})
export class GodotComponent implements OnInit {

  @Input()
  prodline: boolean;
  sel3dPath: string;
  prodcode: string;
  filename: string;

  modelsrc = "assets/Godot/Presezzi.html"

  constructor(private route: ActivatedRoute, private helmsrv: HelmService) {
    this.route.queryParams
    .subscribe(params => {
      this.prodline = params.prodline == 'f' ? false : true;
      if(this.prodline){
        this.sel3dPath = params.path;
        helmsrv.LoadSelectedProductline(params.path)
          .subscribe(pl => {

            this.prodcode = pl.code;
            this.filename = pl.code;
          })
      }
      else{
        this.sel3dPath = params.path;
        this.prodcode = this.sel3dPath.replace('/REPO/PRODUCTS/', '');
        this.prodcode = this.prodcode.slice(0,this.prodcode.indexOf('/'));
        this.filename = this.sel3dPath.slice(this.sel3dPath.lastIndexOf('/') + 1, this.sel3dPath.length);
      }
    });
   }

  ngOnInit(): void {
  }

}
