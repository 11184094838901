import { Component, HostListener, OnInit } from '@angular/core';
import { latLng, tileLayer } from 'leaflet';
import * as L from 'leaflet';
import * as geojson from 'geojson';
import { style } from '@angular/animations';
import { Icon, icon, Marker, marker } from 'leaflet';
//import { HomeComponent } from '../home.component';
//import { LinxEyeService } from 'src/app/services/linx-eye.service';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { HelmService } from 'src/app/services/helm.service';
import { Product } from 'src/app/models/shared/product';

@Component({
  selector: 'app-mappa',
  templateUrl: './mappa.component.html',
  styleUrls: ['./mappa.component.scss']
})
export class MappaComponent implements OnInit {

  markers: any = [];

  mappa: L.Map;

  progetti : Product[];
  selectedProd: string;
  selectedProdId: string;

  legenda: boolean = false;

  stato0 : any = [];
  stato1 : any = [];

  visibilita: any[] = new Array(2).fill(true);

  private NaIcon: Icon = icon({
    iconUrl: 'https://clipground.com/images/black-dot-png-6.png',
    // shadowUrl: 'assets/leaflet/marker-shadow.png',
    iconSize: [40, 40], // => random values you have to choose right ones for your case
    // iconAnchor: [1, 1] // => random values too
  });

  private greenIcon: Icon = icon({
    iconUrl: 'http://www.clker.com/cliparts/R/d/z/v/H/1/neon-green-dot-hi.png',
    // shadowUrl: 'assets/leaflet/marker-shadow.png',
    iconSize: [10,10], // => random values you have to choose right ones for your case
    // iconAnchor: [10,10] // => random values too
  });

  private darkGreenIcon: Icon = icon({
    iconUrl: 'https://webstockreview.net/images/clipart-ball-green-3.png',
    // shadowUrl: 'assets/leaflet/marker-shadow.png',
    iconSize: [10,10], // => random values you have to choose right ones for your case
    // iconAnchor: [20, 51] // => random values too
  });

  private yellowIcon: Icon = icon({
    iconUrl: 'http://www.clker.com/cliparts/0/s/x/E/O/d/yellow-dot-md.png',
    // shadowUrl: 'assets/leaflet/marker-shadow.png',
    iconSize: [10,10], // => random values you have to choose right ones for your case
    // iconAnchor: [20, 51] // => random values too
  });

  private orangeIcon: Icon = icon({
    iconUrl: 'https://www.clker.com/cliparts/m/I/n/1/T/P/orange-dot-md.png',
    // shadowUrl: 'assets/leaflet/marker-shadow.png',
    iconSize: [10,10], // => random values you have to choose right ones for your case
    // iconAnchor: [20, 51] // => random values too
  });

  private redIcon: Icon = icon({
    iconUrl: 'http://www.clker.com/cliparts/T/G/b/7/r/A/red-dot-hi.png',
    // shadowUrl: 'assets/leaflet/marker-shadow.png',
    iconSize: [10, 10], // => random values you have to choose right ones for your case
    // iconAnchor: [20, 51] // => random values too
  });

  @HostListener('document:click', ['$event']) clickout(event) { console.log("sono qui",event.target.id);
    if(event.target.id.includes("bottone-")){
      let nome = event.target.id.substring(event.target.id.lastIndexOf("_")+1)
      this.dialogRef.close()
      this.router.navigate(['/lynxeye/prj/', nome])
    }
  }

  constructor(private helmsrv: HelmService, private router: Router,  public dialogRef: MatDialogRef<MappaComponent>) { }

  ngOnInit(): void {

    this.selectedProdId = localStorage.getItem('selectedProd');

    const map = L.map('map').setView([42.3099861,11.5637461], 6);

    L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}', {
      minZoom: 3,
      maxZoom: 10,
      attribution: 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ',
      // apikey: '<your apikey>',
    }).addTo(map);

    this.mappa = map;
    this.addGeoJSonMarker()

/*     var tile_url = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';

        var map = L.map('map', {
          maxZoom: 20,
          minZoom: 20,
          crs: L.CRS.Simple
        }).setView([0, 0], 20);
        //65409x32839
        var southWest = map.unproject([0, 32839], map.getMaxZoom());
        var northEast = map.unproject([65409, 0], map.getMaxZoom());
        map.setMaxBounds(new L.LatLngBounds(southWest, northEast));
        L.tileLayer(tile_url, {
            attribution: 'Map data &copy; Ieuan\'s OpenTTD World',
            //continuousWorld: true,
            tileSize: 256
        }).addTo(map);
        this.mappa = map; */
    console.log("la mappa",this.mappa)
  }

  naviga(progetto){
    const buttonSubmit = document.getElementById('bottone-' + progetto._id);
    console.log(buttonSubmit)
    buttonSubmit.addEventListener('click', () => {
    this.router.navigate(['/lynxeye/prj/', progetto.name])
    // this.router.navigate(['https://demo.bimspace.it/modules/CDE/#/cde?path=%2FBIM%2F', progetto.name])
    });
  }



  caricaMarker(punto, icona, archivio){
    let grandezzaPoupUp = {maxWidth : 550};
    const geojsonPoint: geojson.Point = {
      type: "Point",
      coordinates: [+(punto.lon),+(punto.lat)],
    };
    var marker = L.geoJSON(geojsonPoint, {
      pointToLayer: (point,latlon)=> {
        return L.marker(latlon, {icon: icona})
    }
    });
    let myDescr = L.popup(grandezzaPoupUp)
    .setContent(
      `
      <div style="display:flex; flex-direction: column; justify-content: space-evenly; align-items: center; min-width: 420px;">
        <h4 style="text-align:center; font-weight: bold; font-size: 20px; margin-top: 15px; margin-bottom: 0px;"> ${punto.code} </h4>
        <div id="info" style="display:flex; margin-top: 30px; margin-bottom: 30px; justify-content: space-between; align-items: center; width: 420px;">
          <img style="border: solid 1px grey; border-radius:5px;" src=${punto.image} width='auto' height='180px'>
          <div id="btns" style="display:flex; height:180px; flex-direction: column; justify-content: flex-start; align-items: center;">
            <a style="display: inline-block; box-shadow: 0 3px 5px -1px #0003, 0 6px 10px #00000024, 0 1px 18px #0000001f; background-color: #505ba1;
              color: #fff; box-sizing: border-box; cursor: pointer; outline: none; border: none; white-space: nowrap; vertical-align: baseline; text-align: center; margin: 0; line-height: 36px;
              overflow: visible; transform: translate3d(0, 0, 0);
              transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1),box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
              min-width: 0;
              border-radius: 50%; padding: 0;
              flex-shrink: 0; font-family: Roboto,Helvetica Neue,sans-serif;
              font-size: 14px;
              font-weight: 500; width: 45px!important;
              height: 45px!important;
              display: flex!important;
              justify-content: center!important;
              align-items: center!important; text-decoration: none!important;"
              href="https://nebulateam.bimspace.it/#/cde?path=%2FREPO%2FPRODUCTS%2F${punto.code}%2F" target="_blank" title="Vai a CDE">
                <i class="material-icons">folder</i>
            </a>
            <a style="display: inline-block; box-shadow: 0 3px 5px -1px #0003, 0 6px 10px #00000024, 0 1px 18px #0000001f; background-color: #505ba1;
              margin-top: 15px !important;
              color: #fff; box-sizing: border-box; cursor: pointer; outline: none; border: none; white-space: nowrap; vertical-align: baseline; text-align: center; margin: 0; line-height: 36px;
              overflow: visible; transform: translate3d(0, 0, 0);
              transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1),box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
              min-width: 0;
              border-radius: 50%; padding: 0;
              flex-shrink: 0; font-family: Roboto,Helvetica Neue,sans-serif;
              font-size: 14px;
              font-weight: 500; width: 45px!important;
              height: 45px!important;
              display: flex!important;
              justify-content: center!important;
              align-items: center!important; text-decoration: none!important;"
              href="https://nebulateam.bimspace.it/#/cde?path=%2FREPO%2FPRODUCTS%2F${punto.code}%2F${punto.code}_GALLERY%2F" target="_blank" title="Vai a CDE">
                <i class="material-icons">mms</i>
            </a>
          </div>
        </div>
        <div style="width: 100%;">
          <p>Nome: ${punto.name}</p>
          <p><hr></p>
          <p>Descrizione: ${punto.description}</p>
          <p><hr></p>
          <p>Indirizzo: ${punto.address}</p>
          <p><hr></p>
          <p>Proprietario: ${punto.owner.surname} ${punto.owner.name}</p>
        </div>

      </div>

      `);
    marker.bindPopup(myDescr)
    let x = marker.addTo(this.mappa);
    archivio.push(marker)
    this.markers.push(marker);
    // console.log("MARKERSSSSS",this.markers)
  }

  addGeoJSonMarker() {
    this.helmsrv.LoadAllProducts().subscribe(
      res => {
        this.progetti = res;
        console.log(res);
        for(let punto of this.progetti){
          if(punto.productId == this.selectedProdId){
            this.caricaMarker(punto, this.redIcon, this.stato0)
            this.selectedProd = punto.code;
          }
          else{
            this.caricaMarker(punto, this.NaIcon, this.stato1)
          }
          console.log(punto)
        }
      }
    );
  }

  searchMediaManutenzione(value){
    console.log(value)

  }

  makeManutenzioneVisible(completed: boolean) {
    if (this.visibilita[0] == false) {
      for(let item of this.stato0){
        this.mappa.removeLayer(item)
      }
    } else{
      for(let item of this.stato0){
        item.addTo(this.mappa);
      }
    }
    if (this.visibilita[1] == false) {
      for(let item of this.stato1){
        this.mappa.removeLayer(item)
      }
    } else{
      for(let item of this.stato1){
        item.addTo(this.mappa);
      }
    }
  }

  accendiSpegniLegenda(){
    this.legenda = !this.legenda;
  }

  navigateTo(): void {
    localStorage.setItem("CLOUD", "P");
    this.router.navigate(["/cde"], { queryParams: { path: '/REPO/PRODUCTS/' + this.selectedProd + '/'} });
  }

}
