import { Component, OnInit } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LinkDialogComponent } from 'src/app/components/CDE/dialog/link-dialog/link-dialog.component';
import { ConfirmDialogComponent } from 'src/app/components/shared/confirm-dialog/confirm-dialog.component';
import { CloudItem } from 'src/app/models/cde/cloud-item';
import { ProdMemb } from 'src/app/models/helm/prod-memb';
import { AuthUser } from 'src/app/models/shared/auth-user';
import { AutocompObj } from 'src/app/models/shared/autocomp-obj';
import { ProdLight } from 'src/app/models/shared/prod-light';
import { Prodline } from 'src/app/models/shared/prodline';
import { CdeServiceService } from 'src/app/services/cde-service.service';
import { HelmService } from 'src/app/services/helm.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-productline',
  templateUrl: './edit-productline.component.html',
  styleUrls: [
    './edit-productline.component.scss',
    '../../../../app.component.scss'
  ]
})
export class EditProductlineComponent implements OnInit {

  newImg: string;
  editableProdlineId: string;
  editableProdlineCode: string;
  editableProdline: Prodline = {
    prodlineId: "",
    code: "",
    image: environment.imageUrl + "/defaultproductline.png",
    name: "",
    description: "",
    products: [],
    owner: {
      userId: "",
      name: "",
      surname: "",
      company: {
        companyId: "",
        name: "",
        address: "",
        type: "",
        logo: ""
      },
      privilege: "",
      user_type: ""
    },
    members: []
  }

  esiste: boolean = false;

  links: CloudItem[] = [];

  selectedprod: string;
  prodsForSel: string[] = [];
  prodCtrl = new FormControl();
  filteredprods: Observable<string[]>;

  usersForSel: AutocompObj[] = [];
  ownerCtrl = new FormControl();
  filteredusrsowner: Observable<AutocompObj[]>;
  selectedowner: AutocompObj;

  products: ProdLight[] = [];
  members: ProdMemb[] = [];
  users: AuthUser[];

  tempProdMemb: {product: ProdLight, members: ProdMemb[]}[] = [];

  proddisplayedColumns = ['code', 'name', 'actions'];
  proddataSource = new MatTableDataSource<ProdLight>();

  userproddisplayedColumns = ['memb', 'permission'];
  userproddataSource = new MatTableDataSource<ProdMemb>();

  linkDisplayedColumns = ['folder', 'link', 'actions'];
  linkDataSource = new MatTableDataSource<CloudItem>();

  constructor(
    private helmsrv: HelmService,
    private cdesrv: CdeServiceService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public snackBar: MatSnackBar,
    public dialog: MatDialog
    ) { }

  ngOnInit(): void {
    this.editableProdlineId = this.activatedRoute.snapshot.params.id;
    this.LoadSettings();
  }

  LoadSettings(){
    this.helmsrv.LoadSelectedProductline(this.editableProdlineId)
      .subscribe(res => {
        this.editableProdline = res;
        this.editableProdlineCode = this.editableProdline.code;
        this.helmsrv.LoadAllProductsLight()
          .subscribe((res) => {
            this.products = res
            for(let prod of this.products){
              this.prodsForSel.push(prod.code);
            }
            this.proddataSource.data = this.editableProdline.products;
            this.userproddataSource.data = this.members;
            for(let memb of this.editableProdline.members){
              let member: ProdMemb= {
                member: {id: memb.user.userId, text: `${memb.user.surname} ${memb.user.name}  - ${memb.user.company.name}`},
                permission: memb.permission,
                privilege: memb.user.privilege
              }
              this.members.push(member);
            }
            this.helmsrv.GetLinks(this.editableProdlineId, 'PL')
              .subscribe(res => {
                this.links = res;
                this.linkDataSource.data = this.links;
                this.helmsrv.LoadAllUsersAuth()
                  .subscribe(res => {
                    this.users = res;
                    this.filteredprods = this.prodCtrl.valueChanges
                    .pipe(
                      startWith(''),
                      map(prod => this._filterProd(prod))
                    );
                    this.helmsrv.LoadAllUsersAuth()
                      .subscribe((res) => {
                        this.users = res
                        for(let usr of this.users){
                          this.usersForSel.push({id: usr.userId, text: `${usr.surname} ${usr.name}  - ${usr.company.name}`})
                          if(usr.userId == localStorage.getItem('id')) this.selectedowner = {id: usr.userId, text: `${usr.surname} ${usr.name}  - ${usr.company.name}`};
                        }
                        this.filteredusrsowner = this.ownerCtrl.valueChanges
                        .pipe(
                          startWith(''),
                          map(usr => this._filterUsr(usr))
                        );
                        for(let prod of this.editableProdline.products){
                          this.helmsrv.GetProdMembers(prod.productId)
                            .subscribe(res => {
                              this.tempProdMemb.push({product: prod, members: res});
                            })
                        }
                      });
                  })
              })

          });
      })

  }

  addProd(){
    let prod = this.products.filter(p => p.code == this.selectedprod)[0];
    if(prod != undefined && this.editableProdline.products.filter(p => p.code == prod.code).length == 0){
      this.editableProdline.products.push(prod);
      this.proddataSource.data = this.editableProdline.products;
      this.helmsrv.GetProdMembers(prod.productId)
        .subscribe(res => {
          this.tempProdMemb.push({product: prod, members: res});
          for (let tpm of this.tempProdMemb){
            for(let memb of tpm.members){
              if(this.members.filter(m => m.member.id == memb.member.id).length == 0) this.members.push(memb);
            }
          }
          this.userproddataSource.data = this.members;
        })
    }
    this.prodCtrl.setValue('');
  }

  remProd(prod: ProdLight){
    this.editableProdline.products.splice(this.editableProdline.products.indexOf(prod), 1);
    this.tempProdMemb.splice(this.tempProdMemb.indexOf(this.tempProdMemb.filter(p => p.product.productId == prod.productId)[0]),1);
    this.members = [];
    for (let tpm of this.tempProdMemb){
      for(let memb of tpm.members){
        if(this.members.filter(m => m.member.id == memb.member.id).length == 0) this.members.push(memb);
      }
    }
    this.userproddataSource.data = this.members;
    this.proddataSource.data = this.editableProdline.products;
  }

  readUrl(event: any): void{
    const reader = new FileReader();
    if (event.target.files && event.target.files.length){
      const[file] = event.target.files;
      this.newImg = file;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.newImg = reader.result as string;
        this.editableProdline.image = this.newImg;
      };
    }
  }

  CheckCode(form: NgForm){
    this.editableProdline = {...form.value};
    if(this.newImg != undefined) this.editableProdline.image = this.newImg;
    else this.editableProdline.image = environment.imageUrl + "/defaultproduct.png";
    this.helmsrv.CheckCode(this.editableProdline.code, 'pl')
      .subscribe(res => {
        this.esiste = res;
      })
  }

  openLinkCreation(item: CloudItem): void {
    const dialogRef = this.dialog.open(LinkDialogComponent, {
      width: '40%',
      data: item
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
    });

  }

  openDialog(item: CloudItem): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '250px'
    });
    dialogRef.componentInstance.message = "Sicuro di voler cancellare il link?";
    dialogRef.componentInstance.confirm.subscribe(res => {
      if(res == "SI"){
        this.cdesrv.deleteCdeLink(item.link).subscribe(res=>{
          this.helmsrv.GetLinks(this.editableProdlineId, 'PL')
            .subscribe(res => {
              this.links = res;
              this.linkDataSource.data = this.links;
            })
        }, (err) => {
          console.log(err);
        })
      }
    })
  }

  EditProdline(form: NgForm){
    let selprods = this.editableProdline.products;
    if(this.editableProdline.products.length > 0){
      this.editableProdline = {...form.value};
      this.editableProdline.prodlineId = this.editableProdlineId;
      this.editableProdline.code = this.editableProdlineCode;
      this.editableProdline.owner = this.users.filter(usr => usr.userId == this.selectedowner.id)[0];
        if(this.editableProdline.owner.privilege == 'viewer'){
          this.snackBar.open("Il proprietario non può essere un utente VIEWER", "OK", {
            duration: 5000,
            horizontalPosition: "center",
            verticalPosition: "bottom"
          })
        }
        else{
          if(this.newImg != undefined) this.editableProdline.image = this.newImg;
          if(this.editableProdline.image == environment.imageUrl + "/defaultproductline.png" || this.editableProdline.image == undefined) this.editableProdline.image = "";
          this.editableProdline.members = new Array();
          for(let memb of this.members){
            this.editableProdline.members.push({user: this.users.filter(usr => usr.userId == memb.member.id)[0], permission: memb.permission});
          }
          this.editableProdline.products = selprods;
          this.helmsrv.EditSelectedProductline(this.editableProdline)
            .subscribe(res => {
              this.router.navigateByUrl('/helm/productlines')},
            (error) => {
              this.snackBar.open("Errore editazione linea", "OK", {
                duration: 5000,
                horizontalPosition: "center",
                verticalPosition: "bottom"
              })
              console.log("Errore editazione linea", error);
            });
        }
    }
    else{
      this.snackBar.open("Selezionare almeno un prodotto", "OK", {
        duration: 5000,
        horizontalPosition: "center",
        verticalPosition: "bottom"
      })
    }
  }

  private _filterProd(value: string): string[] {
    if(typeof(value) === 'string'){
      const filterValue = value?.toLowerCase() ?? '';
      return this.prodsForSel.filter(prod => prod.toLowerCase().indexOf(filterValue) > -1);
    }
  }

  private _filterUsr(value: string): AutocompObj[] {
    if(typeof(value) === 'string'){
      const filterValue = value?.toLowerCase() ?? '';
      return this.usersForSel.filter(usr => usr.text.toLowerCase().indexOf(filterValue) > -1);
    }
  }

  public getDisplayFn() {
    return (val) => this.display(val);
  }

  private display(user): string {
    return user ? user.text : user;
  }
}
