import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators'
import { environment } from 'src/environments/environment';
import { ActivityCalendar } from '../models/helm/activity-calendar';
import { ActivityLight } from '../models/helm/activity-light';
import { Commessa } from '../models/helm/commessa';
import { CostSet } from '../models/helm/cost-set';
import { Order } from '../models/helm/order';
import { OrderLight } from '../models/helm/order-light';
import { OrderProd } from '../models/helm/order-prod';
import { OrderStatus } from '../models/helm/order-status';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  option!: HttpHeaders;

  private order !:Order;

  constructor(private http: HttpClient) { }

  //#region Commessa Originale
  GetOrder(){
    return this.order;
  }

  SetOrder(order: Order){
    this.order = order;
  }

  LoadAllOrders(year: string): Observable<Order[]>{
    const ApiUrl = environment.mainUrl + 'orders';
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(ApiUrl, {headers: this.option, params: {year: year}})
      .pipe(
        map(res => {
          var orders = res['orders'];
          console.log(orders)
          for(let order of orders){
            order.date_acquisition = new Date(order.date_acquisition);
            order.date_offer = new Date(order.date_offer);
            order.date_delivery = new Date(order.date_delivery);
            order.date_closing = new Date(order.date_closing);
          }
          return orders;
      }),
        catchError(this.errorhandler)
      );
  }

  LoadAllOrdersLight(year: string): Observable<OrderLight[]>{
    const ApiUrl = environment.mainUrl + 'orderslight';
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(ApiUrl, {headers: this.option, params: {year: year}})
      .pipe(
        map(res => {
          var orders = res['orders'];
          return orders;
      }),
        catchError(this.errorhandler)
      );
  }

  LoadOrderProds(): Observable<OrderProd[]>{
    const ApiUrl = environment.mainUrl + 'orderprod/';
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(ApiUrl, {headers: this.option})
      .pipe(
        map(res => {
          return res['orderprods'];
      }),
        catchError(this.errorhandler)
      );
  }

  LoadPlannedActivities(year: string): Observable<ActivityCalendar[]>{
    const ApiUrl = environment.mainUrl + 'plannedactivity/';
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(ApiUrl, {headers: this.option, params: {year: year}})
      .pipe(
        map(res => {
          let plannedactivities: ActivityCalendar[] = res['activities'];
          for(let act of plannedactivities){
            act.start = new Date(act.start);
            act.end = new Date(act.end);
          }
          return plannedactivities;
      }),
        catchError(this.errorhandler)
      );
  }

  LoadSelectedOrder(orderId : string): Observable<Order>{
    const ApiUrl = environment.mainUrl + 'order/' + orderId;
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(ApiUrl, {headers: this.option})
      .pipe(
        map(res => {
          let dborder: Order =  res['order'];
          dborder.date_offer = dborder.date_offer ? new Date(dborder.date_offer) : null;
          dborder.date_delivery = dborder.date_delivery ? new Date(dborder.date_delivery) : null;
          for(let act of dborder.activity_planned){
            act.start = new Date(act.start);
            act.end = new Date(act.end);
          }
          return dborder;
      }),
        catchError(this.errorhandler)
      );
  }

  getCostSet(): Observable<CostSet[]>{
    const ApiUrl = environment.mainUrl + 'utils/task_cost';
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(ApiUrl, {headers: this.option})
      .pipe(
        map(res => {
          return res['costs'];
      }),
        catchError(this.errorhandler)
      );
  }

  AddCostSet(costset: CostSet): Observable<string>{
    const ApiUrl = environment.mainUrl + 'utils/task_cost';
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.patch(ApiUrl, {newUtil: costset}, {headers: this.option})
      .pipe(
        map(res => {
          return res['message'];
      }),
        catchError(this.errorhandler)
      );
  }

  SaveOrder(): Observable<string>{
    const ApiUrl = environment.mainUrl + 'order';
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post(ApiUrl, this.order ,{headers: this.option})
      .pipe(
        map(res => {
          return res['message'];
      }),
        catchError(this.errorhandler)
      );
  }

  UpdateOrder(): Observable<string>{
    const ApiUrl = environment.mainUrl + 'order';
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.patch(ApiUrl, this.order ,{headers: this.option})
      .pipe(
        map(res => {
          return res['message'];
      }),
        catchError(this.errorhandler)
      );
  }

  UpdateStatus(orderId: string, status: OrderStatus): Observable<string>{
    const ApiUrl = environment.mainUrl + 'updatestatus';
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.patch(ApiUrl, status, {headers: this.option, params: {orderId: orderId}})
      .pipe(
        map(res => {
          return res['message'];
      }),
        catchError(this.errorhandler)
      );
  }

  DeleteOrder(orderId: string): Observable<string>{
    const ApiUrl = environment.mainUrl + 'order/' + orderId;
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.delete(ApiUrl, {headers: this.option})
      .pipe(
        map(res => {
          localStorage.setItem('selectedOrder', 'TUTTE LE COMMESSE');
          return res['message'];
      }),
        catchError(this.errorhandler)
      );
  }
  //#endregion

  //#region Nuova Commessa
  LoadAllCommesse(): Observable<Commessa[]>{
    const ApiUrl = environment.mainUrl + 'commesse';
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(ApiUrl, {headers: this.option})
      .pipe(
        map(res => {
          var orders = res['orders'];
          for(let order of orders){
            order.date_acquisition = new Date(order.date_acquisition);
            order.date_offer = new Date(order.date_offer);
            order.date_delivery = new Date(order.date_delivery);
            order.date_closing = new Date(order.date_closing);
          }
          return orders;
      }),
        catchError(this.errorhandler)
      );
  }

  LoadSelectedCommessa(orderId : string): Observable<Commessa>{
    const ApiUrl = environment.mainUrl + 'commessa/' + orderId;
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(ApiUrl, {headers: this.option})
      .pipe(
        map(res => {
          let dborder: Commessa =  res['order'];
          dborder.date_offer = new Date(dborder.date_offer);
          dborder.date_delivery = new Date(dborder.date_delivery);
          return dborder;
      }),
        catchError(this.errorhandler)
      );
  }

  SaveCommessa(commessa: Commessa): Observable<string>{
    const ApiUrl = environment.mainUrl + 'commessa';
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post(ApiUrl, commessa ,{headers: this.option})
      .pipe(
        map(res => {
          return res['message'];
      }),
        catchError(this.errorhandler)
      );
  }

  UpdateCommess(commessa: Commessa): Observable<string>{
    const ApiUrl = environment.mainUrl + 'commessa';
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.patch(ApiUrl, commessa ,{headers: this.option})
      .pipe(
        map(res => {
          return res['message'];
      }),
        catchError(this.errorhandler)
      );
  }

  DeleteCommessa(orderId: string): Observable<string>{
    const ApiUrl = environment.mainUrl + 'commessa/' + orderId;
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.delete(ApiUrl, {headers: this.option})
      .pipe(
        map(res => {
          return res['message'];
      }),
        catchError(this.errorhandler)
      );
  }
  //#endregion

  /*Gestione errori*/
  errorhandler(error: any){
    console.log(error);
    let msg: string;
    if(error instanceof HttpErrorResponse){
      if(error.status === 0){
        msg = 'App offline'
      }
      else{
        msg = `${error.error.message}`
      }
      return throwError(() => {new Error(msg)});
    }
    return throwError(()=> {new Error(`Si è verificato un errore di tipo: ${error.message}`)});
  }
}
