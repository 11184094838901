import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Seat } from '../models/Intesa/seat';

@Injectable({
  providedIn: 'root'
})
export class IntesaService {

  option!: HttpHeaders;

  constructor(private http: HttpClient) { }

  LoadAllPlans(): Observable<string[]>{
    let selAsset = localStorage.getItem('selectedAsset') == null ? 'all' : localStorage.getItem('selectedAsset');
    const ApiUrl = environment.mainUrl + 'intesa/allplans/' + selAsset;
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(ApiUrl, {headers: this.option})
      .pipe(
        map(res => {
          return res['plans'];
      }),
        catchError(this.errorhandler)
      );
  }

  dowloadPdf(planName: string): any {
    const ApiUrl = environment.mainUrl + 'intesa/plan/' + planName;
    this.option = new HttpHeaders()
      .set('Content-Type','application/json')
        .set('Authorization', 'Bearer ' + (localStorage.getItem('token') || localStorage.getItem('link_token')))
        return this.http.get(ApiUrl, { headers: this.option, responseType: 'blob'});
  }

  getSeats(plan: string): Observable<Seat[]>{
    const ApiUrl = environment.mainUrl + 'intesa/seats/' + plan;
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(ApiUrl, {headers: this.option})
      .pipe(
        map(res => {
          return res['seats'];
      }),
        catchError(this.errorhandler)
      );
  }

  updateSeat(plan: string, seat: Seat): any {
    const ApiUrl = environment.mainUrl + 'intesa/seat';
    this.option = new HttpHeaders()
      .set('Content-Type','application/json')
        .set('Authorization', 'Bearer ' + (localStorage.getItem('token')));
    let params = new HttpParams().set('plan', plan).set('seat', JSON.stringify(seat));
    console.log(this.option)
    return this.http.patch(ApiUrl, {}, { headers: this.option, responseType: 'blob', params: params });
  }

  /*Gestione errori*/
  errorhandler(error: any){
    console.log(error);
    let msg: string;
    if(error instanceof HttpErrorResponse){
      if(error.status === 0){
        msg = 'App offline'
      }
      else{
        msg = `${error.error.message}`
      }
      return throwError(() => {new Error(msg)});
    }
    return throwError(()=> {new Error(`Si è verificato un errore di tipo: ${error.message}`)});
  }
}
