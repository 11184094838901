import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { OrderLight } from 'src/app/models/helm/order-light';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-elenco-orders',
  templateUrl: './elenco-orders.component.html',
  styleUrls: ['./elenco-orders.component.scss']
})
export class ElencoOrdersComponent implements OnInit {

  allorders: OrderLight[]= [];
  selectedorder: OrderLight;
  allorderssrc: OrderLight[] = [];
  searchinput: string = "";
  refyear: string = (new Date()).getFullYear().toString();

  allorder: OrderLight = {
    name: "TUTTE LE COMMESSE",
    code: "TUTTE LE COMMESSE",
    client: null,
    orderId: "TUTTE LE COMMESSE",
    state: null,
    year: null
  }

  @Output() setSelOrder = new EventEmitter<string>();

  constructor(private ordsrv: OrderService) { }

  ngOnInit(): void {
    this.LoadOrdersLight();
  }

  SelOrder(order:OrderLight){
    this.selectedorder = order;
    localStorage.setItem('selectedOrder', order.orderId);
    this.setSelOrder.emit(this.selectedorder.orderId);
  }

  YearChanged(selector){
    this.refyear = selector.value;
    this.allorderssrc = [];
    this.allorders = [];
    localStorage.setItem('selectedOrder', 'TUTTE LE COMMESSE');
    this.setSelOrder.emit('TUTTE LE COMMESSE');
    this.LoadOrdersLight();
  }

  private LoadOrdersLight(){
    this.ordsrv.LoadAllOrdersLight(this.refyear)
      .subscribe({next: res => {
        if(res != undefined && res.length > 0) this.allorders = res;
        this.allorders.unshift(this.allorder);
        this.allorderssrc = this.allorders;
        if(localStorage.getItem('selectedOrder') == null || localStorage.getItem('selectedOrder') == "TUTTE LE COMMESSE"){
          //this.selectedprod == this.allprodsrc.filter(p => p.productId == "TUTTI I PRODOTTI")[0];
          this.selectedorder = this.allorder;
        }
        else{
          this.selectedorder = this.allorders.filter(o => o.orderId == localStorage.getItem('selectedOrder'))[0];
        }
      }, error: err => {
        console.log("Errore caricamento linee", err);
      }})
  }

  searchOrders(){
    this.searchinput == '' ? this.allorderssrc = this.allorders : this.allorderssrc = this.allorders.filter(o => {return o.code.toUpperCase().includes(this.searchinput.toUpperCase())});
  }
}
