<form #fm="ngForm">
  <mat-card class="helmcard">
    <mat-card-header>
      <span class="underline-text-nebula">
        <h5 class="mdc-text left assettitle">Gestione Costi</h5>
      </span>
      <div class="right">
        <button mat-fab class="menubtn" color="primary"(click)="navigate(fm, 'activity')" matTooltip="Attività" matTooltipPosition="below" type="submit">
          <i class="material-icons">event</i></button>
        <button mat-fab class="menubtn" color="primary"(click)="navigate(fm, 'main')" matTooltip="Torna all'anagrafica" matTooltipPosition="below" type="button">
          <i class="material-icons">arrow_back</i></button>
        <button mat-fab class="menubtn" color="primary" matTooltip="Indietro" routerLink="/helm/orders" matTooltipPosition="below">
          <i class="material-icons">close</i></button>
      </div>
    </mat-card-header>
    <mat-card-content>
      <div class="internalMatGrid">
        <mat-grid-list [cols]=4  rowHeight="100px">
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Data offerta</mat-label>
                <input matInput name="date_offer" [matDatepicker]="date_offer_picker" [ngModel]="editableOrder.date_offer" [formControl]="date_offer">
                <mat-datepicker-toggle matSuffix [for]="date_offer_picker"></mat-datepicker-toggle>
                <mat-datepicker #date_offer_picker></mat-datepicker>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Data acquisizione</mat-label>
                <input matInput name="date_acquisition" [matDatepicker]="date_acquisition_picker" [ngModel]="newCost.date_acquisition" [formControl]="date_acquisition">
                <mat-datepicker-toggle matSuffix [for]="date_acquisition_picker"></mat-datepicker-toggle>
                <mat-datepicker #date_acquisition_picker></mat-datepicker>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Data consegna</mat-label>
                <input matInput name="date_delivery" [matDatepicker]="date_delivery_picker" [ngModel]="editableOrder.date_delivery" [formControl]="date_delivery">
                <mat-datepicker-toggle matSuffix [for]="date_delivery_picker"></mat-datepicker-toggle>
                <mat-datepicker #date_delivery_picker></mat-datepicker>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Giornate uomo previste</mat-label>
                <input matInput type="number" name="days_planned" [(ngModel)]="newCost.days_planned">
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Valore offerto</mat-label>
                <span matPrefix>€ &nbsp;</span>
                <input matInput type="number" name="price_offered" [(ngModel)]="newCost.price_offered">
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Valore aggiudicato</mat-label>
                <span matPrefix>€ &nbsp;</span>
                <input matInput type="number" name="price_awarded" [(ngModel)]="editableOrder.price_awarded">
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">

            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Giornate uomo pianificate</mat-label>
                <input matInput type="number" name="days_assigned" [(ngModel)]="newCost.days_assigned" readonly>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Costi Project Management</mat-label>
                <span matPrefix>€ &nbsp;</span>
                <input matInput type="number" name="proj_manag_cost" [(ngModel)]="newCost.proj_manag_cost">
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Costi External Support</mat-label>
                <span matPrefix>€ &nbsp;</span>
                <input matInput type="number" name="ext_supp_cost" [(ngModel)]="newCost.ext_supp_cost">
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Spese Varie</mat-label>
                <span matPrefix>€ &nbsp;</span>
                <input matInput type="number" name="var_expenses" [(ngModel)]="newCost.var_expenses">
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Costi uomo previsti</mat-label>
                <span matPrefix>€ &nbsp;</span>
                <input matInput type="number" name="days_cost" [(ngModel)]="newCost.days_cost">
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Spese rappresentanza</mat-label>
                <span matPrefix>€ &nbsp;</span>
                <input matInput type="number" name="rapp_expenses" [(ngModel)]="newCost.rapp_expenses">
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Costi Attrezzature HW SW</mat-label>
                <span matPrefix>€ &nbsp;</span>
                <input matInput type="number" name="hw_sw_cost" [(ngModel)]="newCost.hw_sw_cost">
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Costi Gestione Cliente</mat-label>
                <span matPrefix>€ &nbsp;</span>
                <input matInput type="number" name="gest_clnt_cost" [(ngModel)]="newCost.gest_clnt_cost">
              </mat-form-field>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
    </mat-card-content>
  </mat-card>
</form>
