import { Component, OnInit } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LinkDialogComponent } from 'src/app/components/CDE/dialog/link-dialog/link-dialog.component';
import { ConfirmDialogComponent } from 'src/app/components/shared/confirm-dialog/confirm-dialog.component';
import { CloudItem } from 'src/app/models/cde/cloud-item';
import { ProdMemb } from 'src/app/models/helm/prod-memb';
import { AuthUser } from 'src/app/models/shared/auth-user';
import { AutocompObj } from 'src/app/models/shared/autocomp-obj';
import { Product } from 'src/app/models/shared/product';
import { CdeServiceService } from 'src/app/services/cde-service.service';
import { HelmService } from 'src/app/services/helm.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-product-helm',
  templateUrl: './edit-product-helm.component.html',
  styleUrls: [
    './edit-product-helm.component.scss',
    '../../../../app.component.scss'
  ]
})
export class EditProductHelmComponent implements OnInit {

  newImg: string;
  editableProdId: string;
  editableProdCode: string;
  editableProd: Product = {
    productId: "",
    code: "",
    image: environment.imageUrl + "/defaultproduct.png",
    address: "",
    name: "",
    description: "",
    owner: {
      userId: "",
      name: "",
      surname: "",
      company: {
        companyId: "",
        name: "",
        address: "",
        type: "",
        logo: ""
      },
      privilege: "",
      user_type: ""
    },
    members: []
  }

  links: CloudItem[] = [];

  users: AuthUser[] = [];
  usersForSel: AutocompObj[] = [];
  ownerForSel: AutocompObj[] = [];
  ownerCtrl = new FormControl();
  filteredusrsowner: Observable<AutocompObj[]>;
  memberCtrl = new FormControl();
  filteredusrsmember: Observable<AutocompObj[]>;
  selectedowner: AutocompObj;
  selectedmember: AutocompObj;

  members: ProdMemb[] = [];

  displayedColumns = ['memb', 'permission', 'actions'];
  dataSource = new MatTableDataSource<ProdMemb>();

  linkDisplayedColumns = ['folder', 'link', 'actions'];
  linkDataSource = new MatTableDataSource<CloudItem>();

  constructor(
    private helmsrv: HelmService,
    private cdesrv: CdeServiceService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public snackBar: MatSnackBar,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.editableProdId = this.activatedRoute.snapshot.params.id;
    this.LoadProduct();
  }



  LoadProduct(){
    this.helmsrv.LoadSelectedProduct(this.editableProdId)
      .subscribe(res => {
        this.editableProd = res;
        this.editableProdCode = this.editableProd.code;
        this.selectedowner = {id: this.editableProd.owner.userId, text: `${this.editableProd.owner.surname} ${this.editableProd.owner.name}  - ${this.editableProd.owner.company.name}`};
        for(let memb of this.editableProd.members){
          let member: ProdMemb= {
            member: {id: memb.user.userId, text: `${memb.user.surname} ${memb.user.name}  - ${memb.user.company.name}`},
            permission: memb.permission,
            privilege: memb.user.privilege
          }
          this.members.push(member);
        }
        this.dataSource.data = this.members;
        this.helmsrv.GetLinks(this.editableProdId, 'P')
          .subscribe(res => {
            this.links = res;
            this.linkDataSource.data = this.links;
            this.helmsrv.LoadAllUsersAuth()
              .subscribe((res) => {
                this.users = res;
                for(let usr of this.users){
                  this.usersForSel.push({id: usr.userId, text: `${usr.surname} ${usr.name}  - ${usr.company.name}`})
                  if(usr.privilege == 'admin' || usr.privilege == 'superuser') this.ownerForSel.push({id: usr.userId, text: `${usr.surname} ${usr.name}  - ${usr.company.name}`})
                }

                this.filteredusrsowner = this.ownerCtrl.valueChanges
                .pipe(
                  startWith(''),
                  map(usr => this._filterOwnUsr(usr))
                );
                this.filteredusrsmember = this.memberCtrl.valueChanges
                .pipe(
                  startWith(''),
                  map(usr => this._filterUsr(usr))
                );

              });
          })

      })

  }

  addMember(){
    if(typeof(this.selectedmember) === 'object'){
      let usr = this.users.filter(u => u.userId == this.selectedmember.id);
      let newmemb = {member: this.selectedmember, privilege: usr[0].privilege, permission: false}
      if(this.members.filter(mem => mem.member.id === newmemb.member.id).length === 0 && this.selectedmember.id != this.selectedowner.id){
        this.members.push(newmemb);
        this.dataSource.data = this.members;
      }
      this.memberCtrl.setValue('');
    }
    else this.memberCtrl.setValue('');
  }

  remMemb(usr: ProdMemb){
    let delusr = this.members.filter(mem => mem.member.id == usr.member.id)[0];
    let index = this.members.indexOf(delusr)
    this.members.splice(index, 1);
    this.dataSource.data = this.members;
  }

  readUrl(event: any): void{
    const reader = new FileReader();
    if (event.target.files && event.target.files.length){
      const[file] = event.target.files;
      this.newImg = file;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.newImg = reader.result as string;
        this.editableProd.image = this.newImg;
      };
    }
  }

  openLinkCreation(item: CloudItem): void {
    const dialogRef = this.dialog.open(LinkDialogComponent, {
      width: '40%',
      data: item
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
    });

  }

  openDialog(item: CloudItem): void {
    console.log(item.link)
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '250px'
    });
    dialogRef.componentInstance.message = "Sicuro di voler cancellare il link?";
    dialogRef.componentInstance.confirm.subscribe(res => {
      if(res == "SI"){
        this.cdesrv.deleteCdeLink(item.link).subscribe(res=>{
          this.helmsrv.GetLinks(this.editableProdId, 'P')
            .subscribe(res => {
              this.links = res;
              this.linkDataSource.data = this.links;
            })
        }, (err) => {
          console.log(err);
        })
      }
    })
  }

  EditProd(form: NgForm){
    if(typeof(this.selectedowner) == 'object'){
      this.editableProd = {...form.value};
      this.editableProd.productId = this.editableProdId;
      this.editableProd.code = this.editableProdCode;
      this.editableProd.owner = this.users.filter(usr => usr.userId == this.selectedowner.id)[0];
      if(this.editableProd.owner.privilege == 'viewer'){
        this.snackBar.open("Il proprietario non può essere un utente VIEWER", "OK", {
          duration: 5000,
          horizontalPosition: "center",
          verticalPosition: "bottom"
        })
      }
      else{
        if(this.newImg != undefined) this.editableProd.image = this.newImg;
        if(this.editableProd.image == "https://nebulateam.bimspace.it/image/profile.png" || this.editableProd.image == undefined) this.editableProd.image = "";
        this.editableProd.members = new Array();
        for(let memb of this.members){
          this.editableProd.members.push({user: this.users.filter(usr => usr.userId == memb.member.id)[0], permission: memb.permission});
        }
        this.helmsrv.EditSelectedProduct(this.editableProd)
          .subscribe(res => {
            this.router.navigateByUrl('/helm/products')},
          (error) => {
            console.log("Errore modifica prodotto", error);
          });
      }
    }
    else{
      this.snackBar.open("Nessun proprietario selezionato", "OK", {
        duration: 5000,
        horizontalPosition: "center",
        verticalPosition: "bottom"
      })
    }
  }

  private _filterUsr(value: string): AutocompObj[] {
    if(typeof(value) === 'string'){
      const filterValue = value?.toLowerCase() ?? '';
      return this.usersForSel.filter(usr => usr.text.toLowerCase().indexOf(filterValue) > -1);
    }
  }

  private _filterOwnUsr(value: string): AutocompObj[] {
    if(typeof(value) === 'string'){
      const filterValue = value?.toLowerCase() ?? '';
      return this.ownerForSel.filter(usr => usr.text.toLowerCase().indexOf(filterValue) > -1);
    }
  }

  public getDisplayFn() {
    return (val) => this.display(val);
  }

  private display(user): string {
    //access component "this" here
    return user ? user.text : user;
  }
}
