import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-commesse-helm',
  templateUrl: './commesse-helm.component.html',
  styleUrls: ['./commesse-helm.component.scss']
})
export class CommesseHelmComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
