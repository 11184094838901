import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { NgForm } from '@angular/forms';
import { map, catchError } from 'rxjs/operators'
import { environment } from 'src/environments/environment';
// const ApiUrl = __dirname + '/loginsrv'
const ApiUrl = environment.mainUrl + 'login'

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private option: HttpHeaders = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
  constructor(private http: HttpClient) { }

  login(datiForm: NgForm): Observable<string>{
    const body = this.body(datiForm);
    return this.http.post(ApiUrl, body, {headers: this.option})
      .pipe(
        map(res => {
          if(res['token']){
            this.setSession(res);
          }
          return res['token'];
      }),
      catchError(this.errorhandler)
      );
  }

  logout(){
    localStorage.removeItem('username');
    localStorage.removeItem('id');
    localStorage.removeItem('name');
    localStorage.removeItem('priv');
    localStorage.removeItem('token');
    localStorage.removeItem('expired');
    localStorage.removeItem('image');
    localStorage.removeItem('selectedProd');
    localStorage.removeItem('selectedProdline');
    localStorage.removeItem('role');
  }

  private setSession(data){
    let expire: number = new Date().getTime() + 60000*60*10;
    localStorage.setItem('username', data['username']);
    localStorage.setItem('id', data['id']);
    localStorage.setItem('name', data['name']);
    localStorage.setItem('priv', data['priv']);
    localStorage.setItem('token', data['token']);
    localStorage.setItem('expired', expire.toString());
    localStorage.setItem('image', data['image']);
    localStorage.setItem('company', data['company']);
    localStorage.setItem('role', data['role']);
    localStorage.setItem('selectedProd', 'TUTTI I PRODOTTI');
    localStorage.setItem('selectedProdline', 'TUTTE LE LINEE');
    localStorage.setItem('selectedOrder', 'TUTTE LE COMMESSE');
  }

  setPublicToken(token: string, view: boolean, read: boolean, write: boolean){
    let expire: number = new Date().getTime() + 60000*60*10;
    localStorage.setItem('link_token', token);
    localStorage.setItem('publicExpired', expire.toString());
  }

  notExpired(): boolean{
    let expire: number = parseInt(localStorage.getItem('expired'));
    if(localStorage.getItem('expired')){
      return new Date().getTime() < expire;
    }
    return false;
  }

  private body(df: NgForm){
    let params = new HttpParams()
      .set('bim', df.value.bim)
      .set('pwd', df.value.pwd);
    return params;
  }


  /*Gestione errori*/
  errorhandler(error: any){
    console.log(error);
    let msg: string;
    if(error instanceof HttpErrorResponse){
      if(error.status === 0){
        msg = 'App offline'
      }
      else{
        msg = `${error.error.message}`
      }
      return throwError(msg);
    }
    return throwError(`Si è verificato un errore di tipo: ${error.message}`);
  }
}
