import {SelectionModel} from '@angular/cdk/collections';
import {FlatTreeControl} from '@angular/cdk/tree';
import {Component, EventEmitter, Injectable, Input, OnInit, Output} from '@angular/core';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {BehaviorSubject} from 'rxjs';
import { TreeItem, TreeNode } from 'src/app/models/shared/tree-item';
import { ChecklistDatabase } from '../tree-view/tree-view.component';


var TREE_DATA: TreeItem[] = [];



@Component({
  selector: 'app-tree-view-no-check',
  templateUrl: './tree-view-no-check.component.html',
  styleUrls: ['./tree-view-no-check.component.scss'],
  providers: [ChecklistDatabase]
})
export class TreeViewNoCheckComponent implements OnInit {

  loaded:boolean = false;
  @Input() treeRoot: TreeItem[];
  @Output() selectedNode = new EventEmitter<string>()
  @Input() query: string;
  searchResults: TreeItem[] = [];

  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<TreeNode, TreeItem>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<TreeItem, TreeNode>();

  /** A selected parent node to be inserted */
  selectedParent: TreeNode | null = null;

  treeControl: FlatTreeControl<TreeNode>;

  treeFlattener: MatTreeFlattener<TreeItem, TreeNode>;

  dataSource: MatTreeFlatDataSource<TreeItem, TreeNode>;

  constructor(private _database: ChecklistDatabase) { }

  ngOnInit(): void {
    TREE_DATA = this.treeRoot;
    this._database.dataChange.next(this.treeRoot);

    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren,
    );
    this.treeControl = new FlatTreeControl<TreeNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    this._database.dataChange.subscribe(data => {
      this.dataSource.data = data;
    });
    this.loaded = true;
  }

  getLevel = (node: TreeNode) => node.level;

  isExpandable = (node: TreeNode) => node.expandable;

  getChildren = (node: TreeItem): TreeItem[] => node.children;

  hasChild = (_: number, _nodeData: TreeNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: TreeNode) => _nodeData.name === '';

  transformer = (node: TreeItem, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.name === node.text ? existingNode : new TreeNode();
    flatNode.name = node.text;
    flatNode.id = node.id;
    flatNode.level = level;
    flatNode.expandable = !!node.children?.length;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  Load(node){
    console.log(node)
    this.selectedNode.emit(node.id);
  }

  SearchNodes(){
    if(this.query == ""){
      this._database.dataChange.next(this.treeRoot);
    }
    else{
      this.searchResults = [];
      this.Search(this.treeRoot);
      this._database.dataChange.next(this.searchResults);
    }
  }

  Search(items: TreeItem[]){
    for(let item of items){
      if(item.text.toUpperCase().includes(this.query.toUpperCase()))
        this.searchResults.push(item);
      if(item.children){
        this.Search(item.children);
      }
    }
  }

  ResetQuery(){
    this.query = "";
    this.SearchNodes();
  }
}
