<form #fm="ngForm" *ngIf="loaded">
  <mat-card class="helmcard">
    <mat-card-header>
      <span class="underline-text-nebula">
        <h5 class="mdc-text left" class="assettitle">Risorse</h5>
      </span>
      <div class="right">
        <button mat-fab class="menubtn" color="primary"[routerLink]="'/helm/orders/view/cost/' + viewOrder.orderId" matTooltip="Tempi e Costi" matTooltipPosition="below" type="submit">
          <i class="material-icons">euro</i></button>
        <button mat-fab class="menubtn" color="primary"[routerLink]="'/helm/orders/view/main/' + viewOrder.orderId" matTooltip="Torna all'anagrafica" matTooltipPosition="below" type="button">
          <i class="material-icons">arrow_back</i></button>
        <button mat-fab class="menubtn" color="primary" matTooltip="Indietro" routerLink="/helm/orders" matTooltipPosition="below">
          <i class="material-icons">close</i></button>
      </div>
    </mat-card-header>
    <mat-card-content>
      <div>
        <span class="underline-text-nebula">
          <h5 class="mdc-text left" class="assettitle">Risorse pianificate</h5>
        </span>
        <table mat-table [dataSource]="dataSource" class="internalTable" >
          <ng-container matColumnDef="user">
            <th mat-header-cell *matHeaderCellDef> Risorsa </th>
            <td mat-cell *matCellDef="let element"> {{element.user.surname}} {{element.user.name}}</td>
          </ng-container>
          <ng-container matColumnDef="start">
            <th mat-header-cell *matHeaderCellDef> Data </th>
            <td mat-cell *matCellDef="let element"> {{element.start | date: 'dd/MM/yyyy'}} </td>
          </ng-container>
          <ng-container matColumnDef="luogo">
            <th mat-header-cell *matHeaderCellDef> Luogo </th>
            <td mat-cell *matCellDef="let element"> {{element.luogo}} </td>
          </ng-container>
          <ng-container matColumnDef="tipoAtt">
            <th mat-header-cell *matHeaderCellDef> Tipo di attività</th>
            <td mat-cell *matCellDef="let element"> {{element.tipoAtt}} </td>
          </ng-container>
          <ng-container matColumnDef="days">
            <th mat-header-cell *matHeaderCellDef> Giornate </th>
            <td mat-cell *matCellDef="let element"> {{element.durata/8}} </td>
          </ng-container>
          <ng-container matColumnDef="hours">
            <th mat-header-cell *matHeaderCellDef> Ore </th>
            <td mat-cell *matCellDef="let element"> {{element.durata}} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
      <div style="margin-top: 40px;">
        <span class="underline-text-nebula">
          <h5 class="mdc-text left" class="assettitle">Interventi effettuati</h5>
        </span>
        <table mat-table [dataSource]="dataSource2" class="internalTable" >
          <ng-container matColumnDef="user">
            <th mat-header-cell *matHeaderCellDef> Risorsa </th>
            <td mat-cell *matCellDef="let element"> {{element.user.surname}} {{element.user.name}}</td>
          </ng-container>
          <ng-container matColumnDef="start">
            <th mat-header-cell *matHeaderCellDef> Data </th>
            <td mat-cell *matCellDef="let element"> {{element.start | date: 'dd/MM/yyyy'}} </td>
          </ng-container>
          <ng-container matColumnDef="luogo">
            <th mat-header-cell *matHeaderCellDef> Luogo </th>
            <td mat-cell *matCellDef="let element"> {{element.luogo}} </td>
          </ng-container>
          <ng-container matColumnDef="tipoAtt">
            <th mat-header-cell *matHeaderCellDef> Tipo di attività</th>
            <td mat-cell *matCellDef="let element"> {{element.tipoAtt}} </td>
          </ng-container>
          <ng-container matColumnDef="days">
            <th mat-header-cell *matHeaderCellDef> Giornate </th>
            <td mat-cell *matCellDef="let element"> {{element.durata/8}} </td>
          </ng-container>
          <ng-container matColumnDef="hours">
            <th mat-header-cell *matHeaderCellDef> Ore </th>
            <td mat-cell *matCellDef="let element"> {{element.durata}} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </mat-card-content>
  </mat-card>
</form>

