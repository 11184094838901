import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Order } from 'src/app/models/helm/order';
import { OrderService } from 'src/app/services/order.service';
import { CostSetDialogComponent } from '../../shared/cost-set-dialog/cost-set-dialog.component';

@Component({
  selector: 'app-vo-cost',
  templateUrl: './vo-cost.component.html',
  styleUrls: [
    './vo-cost.component.scss',
    '../../../../../app.component.scss'
  ]
})
export class VoCostComponent implements OnInit {

  loaded: boolean = false;
  viewOrder: Order;

  date_offer: string;
  date_delivery: string;
  date_acquisition: string;

  constructor(private ordsrv: OrderService, private activatedRoute: ActivatedRoute, public dialog: MatDialog) {
    this.ordsrv.LoadSelectedOrder(this.activatedRoute.snapshot.params.id)
      .subscribe(ord => {
        this.viewOrder = ord;
        this.date_offer = this.viewOrder.date_offer ? this.viewOrder.date_offer.toLocaleDateString() : '';
        this.date_delivery = this.viewOrder.date_delivery ? this.viewOrder.date_delivery.toLocaleDateString() : '';
        this.date_acquisition = this.viewOrder.costs.date_acquisition ? (new Date(this.viewOrder.costs.date_acquisition)).toLocaleDateString() : '';
        this.loaded = true;
        this.SetDaysPlannned();
      })
  }

  SetDaysPlannned(){
    this.viewOrder.costs.days_assigned = 0;
    this.viewOrder.costs.days_done = 0;
    for(let act of this.viewOrder.activity_planned){
      if(act.durata != undefined) this.viewOrder.costs.days_assigned += parseFloat((act.durata/8).toFixed(1));
    }
    for(let act of this.viewOrder.activity_done){
      if(act.durata != undefined) this.viewOrder.costs.days_done += parseFloat((act.durata/8).toFixed(1));
    }
  }

  ngOnInit(): void {
  }
}
