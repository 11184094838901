<div class="maincontainer">
  <app-navbar></app-navbar>
  <mat-grid-list [cols]=14 class="appcontainer" rowHeight='fit'>
    <mat-grid-tile [colspan]=11>
      <app-plan-viewer [selectedPlan]="selectedPlan"></app-plan-viewer>
    </mat-grid-tile>
    <mat-grid-tile [colspan]=3>
      <app-elenco-plan (setSelPlan)="SelProd($event)"></app-elenco-plan>
    </mat-grid-tile>
  </mat-grid-list>
</div>

