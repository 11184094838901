import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from 'src/app/components/shared/confirm-dialog/confirm-dialog.component';
import { Order } from 'src/app/models/helm/order';
import { OrderService } from 'src/app/services/order.service';
import { StatusOrderDialogComponent } from '../../shared/status-order-dialog/status-order-dialog.component';

@Component({
  selector: 'app-ao-table',
  templateUrl: './ao-table.component.html',
  styleUrls: [
    './ao-table.component.scss',
    '../../../../../app.component.scss'
  ]
})
export class AoTableComponent implements OnInit {

  userprivilege = localStorage.getItem('priv');
  allOrders: Order[] = [];
  confirm: boolean = false;
  order: Order;

  annorif: string = (new Date()).getFullYear().toString();

  displayedColumns = ['code', 'company', 'desc', 'comm', 'price_offered', 'price_awarded', 'perc_chiusura', 'erogato', 'da_erogare', 'actions'];
  dataSource = new MatTableDataSource<Order>();
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private ordsrv: OrderService, public dialog: MatDialog, private router: Router) { }

  ngOnInit(): void {
    this.LoadOrders();
    this.ordsrv.SetOrder(this.order);
  }

  ngAfterViewInit(): void{
    this.dataSource.sort = this.sort;
  }

  LoadOrders(){
    this.ordsrv.LoadAllOrders(this.annorif)
      .subscribe(res => {
        this.allOrders = res;
        this.dataSource.data = this.allOrders;
      })
  }

  YearChanged(selector){
    this.annorif = selector.value;
    this.LoadOrders();
  }

  editStatus(selorder: Order): void {
    const dialogRef = this.dialog.open(StatusOrderDialogComponent, {
      width: '600px',
      height: '400px'
    });
    dialogRef.componentInstance.ordstatus = selorder.status;
    dialogRef.componentInstance.orderId = selorder.orderId;
    dialogRef.componentInstance.venduto = selorder.price_awarded;
    dialogRef.componentInstance.confirm.subscribe(res => {
      if(res != undefined && res != ''){
       this.LoadOrders();
      }
    })
  }

  openDialog(orderId: string): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '250px'
    });
    dialogRef.componentInstance.message = "Sicuro di voler cancellare la commessa?";
    dialogRef.componentInstance.confirm.subscribe(res => {
      if(res == "SI"){
        this.ordsrv.DeleteOrder(orderId).subscribe((res)=>{
          console.log(res);
          this.LoadOrders();
        }, (err) => {
          console.log(err);
        })
      }
    })
  }

  navigateTo(code: string): void {
    localStorage.setItem("CLOUD", "O");
    this.router.navigate(["/cde"], { queryParams: { path: '/REPO/ORDERS/' + code + '/'} });
  }
}
