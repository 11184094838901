import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from 'src/app/components/shared/confirm-dialog/confirm-dialog.component';
import { Commessa } from 'src/app/models/helm/commessa';
import { OrderService } from 'src/app/services/order.service';


@Component({
  selector: 'app-all-commesse',
  templateUrl: './all-commesse.component.html',
  styleUrls: ['./all-commesse.component.scss']
})
export class AllCommesseComponent implements OnInit, AfterViewInit {

  userprivilege = localStorage.getItem('priv');
  allOrders: Commessa[] = [];
  confirm: boolean = false;
  order: Commessa;

  displayedColumns = ['code', 'name', 'company', 'date_delivery', 'price_awarded', 'actions'];
  dataSource = new MatTableDataSource<Commessa>();
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private ordsrv: OrderService, public dialog: MatDialog, private router: Router) { }

  ngOnInit(): void {
    this.LoadOrders();
  }

  ngAfterViewInit(): void{
    this.dataSource.sort = this.sort;
  }

  LoadOrders(){
    this.ordsrv.LoadAllCommesse()
      .subscribe(res => {

        this.allOrders = res;
        this.dataSource.data = this.allOrders;
      })
  }

  openDialog(orderId: string): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '250px'
    });
    dialogRef.componentInstance.message = "Sicuro di voler cancellare la commessa?";
    dialogRef.componentInstance.confirm.subscribe(res => {
      if(res == "SI"){
        this.ordsrv.DeleteCommessa(orderId).subscribe((res)=>{

          this.LoadOrders();
        }, (err) => {
          console.log(err);
        })
      }
    })
  }

  navigateTo(code: string): void {
    localStorage.setItem("CLOUD", "O");
    this.router.navigate(["/cde"], { queryParams: { path: '/REPO/ORDERS/' + code + '/'} });
  }
}
