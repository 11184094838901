import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';
import { Seat } from 'src/app/models/Intesa/seat';
import { AutocompObj } from 'src/app/models/shared/autocomp-obj';
import { IntesaService } from 'src/app/services/intesa.service';
import { NgxExtendedPdfViewerComponent, NgxExtendedPdfViewerService, pdfDefaultOptions } from 'ngx-extended-pdf-viewer';



@Component({
  selector: 'app-plan-viewer',
  templateUrl: './plan-viewer.component.html',
  styleUrls: ['./plan-viewer.component.scss']
})
export class PlanViewerComponent implements OnInit, OnChanges {

  @ViewChild(NgxExtendedPdfViewerComponent) pdfViewer: NgxExtendedPdfViewerComponent;

  loaded: boolean = false;
  @Input() selectedPlan: string;
  blob: Blob;
  allseats: Array<Seat>;
  zoom: string = 'page-fit';


  selseat: Seat = {
    areacategory: '',
    code: '',
    room: '',
    roomcategory: '',
    seatcategory: '',
    x: 0,
    y: 0,
    avaiable: []
  }

  seatCtrl = new FormControl();
  filteredseats: Observable<AutocompObj[]>;
  selectedseat: AutocompObj;
  seatForSel: AutocompObj[] = [];

  constructor(private intsrv: IntesaService, private pdfService: NgxExtendedPdfViewerService)
  {
    pdfDefaultOptions.maxCanvasPixels = -1;
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes)
    this.LoadPlan();
    this.zoom = 'page-fit';
  }

  ngOnInit(): void {
    this.LoadPlan();
  }

  LoadPlan(){
    this.intsrv.dowloadPdf(this.selectedPlan)
    .subscribe(res => {
        this.blob = new Blob([res], {type: res.type});
        this.intsrv.getSeats(this.selectedPlan)
          .subscribe( res => {
            console.log(res)
            this.allseats = res;
            this.seatForSel = [];
            for(let seat of res){
              this.seatForSel.push({id: seat.code, text: seat.code})
            }

            this.filteredseats = this.seatCtrl.valueChanges
            .pipe(
              startWith(''),
              map(seat => this._filterSeat(seat))
            );

            this.loaded = true;
          })
    })
  }

  public LoadSeat(){
    console.log(this.selectedseat)
    if(this.selectedseat !== null && this.selectedseat !== undefined && typeof(this.selectedseat) !== 'string' && this.selectedseat.id !== ''){
      this.selseat = this.allseats.filter(s => s.code === this.selectedseat.id)[0]
      this.SearchAndZoom(this.selseat.code);
    }
    else{
      this.selseat = {
        areacategory: '',
        code: '',
        room: '',
        roomcategory: '',
        seatcategory: '',
        x: 0,
        y: 0,
        avaiable: []
      }
      this.zoom = 'page-fit';
    }
  }

  public SaveSeat(form: NgForm){
    let newseat:Seat = {...form.value}
    let lastsel = newseat.code;
    this.selseat.seatcategory = newseat.seatcategory;
    this.intsrv.updateSeat(this.selectedPlan, this.selseat)
      .subscribe(res => {
        this.blob = new Blob([res], {type: res.type});

        this.intsrv.getSeats(this.selectedPlan)
          .subscribe( res => {
            this.allseats = res;
            this.seatForSel = [];
            for(let seat of res){
              this.seatForSel.push({id: seat.code, text: seat.code})
            }

            this.filteredseats = this.seatCtrl.valueChanges
            .pipe(
              startWith(''),
              map(seat => this._filterSeat(seat))
            );

            this.selseat = this.allseats.filter(s => s.code === lastsel)[0]
            this.selectedseat = this.seatForSel.filter(s => s.id === lastsel)[0]
            console.log(this.selseat)
            this.SearchAndZoom(this.selseat.code);
            this.loaded = true;
          })
      })
  }

  private _filterSeat(value: string): AutocompObj[] {
    if(typeof(value) === 'string'){
      const filterValue = value?.toLowerCase() ?? '';
      return this.seatForSel.filter(seat => seat.text.toLowerCase().indexOf(filterValue) > -1);
    }
  }

  SearchAndZoom(toSearch: string){
    this.zoom = '700%';
    this.pdfService.find(toSearch, {highlightAll: true})
  }

  public getDisplayFn() {
    return (val) => this.display(val);
  }

  private display(seat): string {
    //access component "this" here
    console.log(seat)
    return seat ? seat.text : seat;
  }
}
