<mat-card id="assetinfocontainer" *ngIf="loaded">
  <mat-card-header>
    <span class="underline-text-nebula">
      <h5 class="nebula-dark left" class="assettitle">{{selectedProdline.code}}</h5>
    </span>
  </mat-card-header>
  <img mat-card-image src="{{selectedProdline.image}}" class="responsive-img"/>
  <mat-card-content>
    <section style="height: 200px; overflow-y: auto;">
      <table style="table-layout: fixed; height: 150px;" id="elecoinfo">
        <thead class="no-line" style="visibility: collapse">
            <tr>
                <th style="width: 40%;">Key</th>
                <th style="width: 5%;">Key</th>
                <th style="width: 55%;">Value</th>
            </tr>
        </thead>
        <tbody>
          <tr *ngIf="selectedProdline.name">
            <td class="singleinfo">Nome</td><td class="singleinfo">:</td>
            <td class="singleinfo">{{selectedProdline.name}}</td>
          </tr>
          <tr *ngIf="selectedProdline.description">
            <td class="singleinfo">Descrizione</td><td class="singleinfo">:</td>
            <td class="singleinfo">{{selectedProdline.description}}</td>
          </tr>
          <tr *ngIf="selectedProdline.owner">
            <td class="singleinfo">Proprietario</td><td class="singleinfo">:</td>
            <td class="singleinfo">{{selectedProdline.owner.surname}} {{selectedProdline.owner.name}}</td>
          </tr>
  <!--         <tr *ngIf="selectedProdline.products.length > 0">
            <td class="singleinfo">Prodotti</td><td class="singleinfo">:</td>
            <td *ngFor="let prod of selectedProdline.products" class="singleinfo">{{prod.code}}</td>
          </tr> -->
          <tr *ngFor="let prod of selectedProdline.products">
            <td *ngIf="prod == selectedProdline.products[0]" class="singleinfo">Prodotti</td><td *ngIf="prod == selectedProdline.products[0]" class="singleinfo">:</td>
            <td *ngIf="prod != selectedProdline.products[0]" class="singleinfo"></td><td *ngIf="prod != selectedProdline.products[0]" class="singleinfo"></td>
            <td class="singleinfo">{{prod.code}}<br></td>
          </tr>
        </tbody>
      </table>
    </section>



  </mat-card-content>
</mat-card>
