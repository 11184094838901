import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Order } from 'src/app/models/helm/order';
import { ProdMemb } from 'src/app/models/helm/prod-memb';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-vo-main',
  templateUrl: './vo-main.component.html',
  styleUrls: [
    './vo-main.component.scss',
    '../../../../../app.component.scss'
  ]
})
export class VoMainComponent implements OnInit {

  loaded: boolean = false;
  viewOrder: Order;

  date_offer: string;
  date_delivery: string;
  owner: string;
  commerciale: string;

  members: ProdMemb[] = [];
  displayedColumns = ['memb', 'permission'];
  dataSource = new MatTableDataSource<ProdMemb>();

  constructor(private ordsrv: OrderService, private activatedRoute: ActivatedRoute) {
    this.ordsrv.LoadSelectedOrder(this.activatedRoute.snapshot.params.id)
      .subscribe(ord => {
        this.viewOrder = ord;
        this.date_offer = this.viewOrder.date_offer ? this.viewOrder.date_offer.toLocaleDateString() : '';
        this.date_delivery = this.viewOrder.date_delivery ? this.viewOrder.date_delivery.toLocaleDateString() : '';
        this.owner = `${this.viewOrder.manager.surname} ${this.viewOrder.manager.name}`
        this.commerciale = this.viewOrder.commerciale ? `${this.viewOrder.commerciale.surname} ${this.viewOrder.commerciale.name}` : '';
        this.loaded = true;
        for(let mem of this.viewOrder.members){
          this.members.push({member: {id: mem.user.userId, text: `${mem.user.surname} ${mem.user.name}  - ${mem.user.company.name}`}, privilege: mem.user.privilege, permission: mem.permission})
        }
        this.dataSource.data = this.members;


      })
   }

  ngOnInit(): void {
  }

}
