<mat-card class="helmcard">
  <mat-card-header>
    <span class="underline-text-nebula">
      <h5 class="mdc-text left" class="assettitle">Commesse</h5>
    </span>
    <div class="right">
      <mat-form-field class="row">
        <mat-label>Anno di riferimento</mat-label>
        <mat-select #ysel name="annorif" [(ngModel)]="annorif" (valueChange)="YearChanged(ysel)">
          <mat-option value="2020">2020</mat-option>
          <mat-option value="2021">2021</mat-option>
          <mat-option value="2022">2022</mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-fab class="menubtn" color="primary" matTooltip="Elenco" routerLink="/helm/orders/all/table" matTooltipPosition="below">
        <i class="material-icons">table_chart</i></button>
      <button mat-fab class="menubtn" color="primary" matTooltip="Nuova commessa" routerLink="/helm/orders/new" matTooltipPosition="below">
        <i class="material-icons">add</i></button>
      <button mat-fab class="menubtn" color="primary" matTooltip="Indietro" routerLink="/helm" matTooltipPosition="below">
        <i class="material-icons">close</i></button>
    </div>
  </mat-card-header>
  <mat-card-content>
    <mat-grid-list cols="3" rowHeight="100px">
      <mat-grid-tile>
        <div class="btn-group">
          <button mat-button color="primary" mwlCalendarPreviousView [view]="view" [excludeDays]="excludeDays"
            [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()"> Precedente </button>
          <button mat-button color="primary" mwlCalendarToday [(viewDate)]="viewDate" > Oggi </button>
          <button mat-button color="primary" mwlCalendarNextView [view]="view" [excludeDays]="excludeDays"
            [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()"> Successivo </button>
        </div>
      </mat-grid-tile>
      <mat-grid-tile>
        <h2 class="acad_dark_gray">{{ viewDate | calendarDate:(view + 'ViewTitle'):'it':weekStartsOn:excludeDays }}</h2>
      </mat-grid-tile>
      <mat-grid-tile>
        <!-- <div class="btn-group">
          <button mat-button color="primary" (click)="setView(CalendarView.Month)"
            [class.active]="view === CalendarView.Month"> Mese </button>
          <button mat-button color="primary" (click)="setView(CalendarView.Week)"
            [class.active]="view === CalendarView.Week"> Settimana </button>
          <button mat-button color="primary" (click)="setView(CalendarView.Day)"
            [class.active]="view === CalendarView.Day"> Giorno </button>
        </div> -->
      </mat-grid-tile>
    </mat-grid-list>
    <div [ngSwitch]="view" *ngIf="loaded">
      <mwl-calendar-month-view
        *ngSwitchCase="CalendarView.Month"
        locale="it"
        [viewDate]="viewDate"
        [events]="events"
        [refresh]="refresh"
        [activeDayIsOpen]="activeDayIsOpen"
        [weekStartsOn]="weekStartsOn"
        [weekendDays]="weekendDays"
        [excludeDays]="excludeDays"
        (dayClicked)="dayClicked($event.day)"
        (eventClicked)="handleEvent('Clicked', $event.event)"
        (eventTimesChanged)="eventTimesChanged($event)"
      >
      </mwl-calendar-month-view>
      <!-- <mwl-calendar-week-view
        *ngSwitchCase="CalendarView.Week"
        [viewDate]="viewDate"
        [events]="events"
        [refresh]="refresh"
        (eventClicked)="handleEvent('Clicked', $event.event)"
        (eventTimesChanged)="eventTimesChanged($event)"
      >
      </mwl-calendar-week-view>
      <mwl-calendar-day-view
        *ngSwitchCase="CalendarView.Day"
        [viewDate]="viewDate"
        [events]="events"
        [refresh]="refresh"
        (eventClicked)="handleEvent('Clicked', $event.event)"
        (eventTimesChanged)="eventTimesChanged($event)"
      >
      </mwl-calendar-day-view> -->
    </div>
  </mat-card-content>
</mat-card>
