import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';
import { AutocompObj } from 'src/app/models/shared/autocomp-obj';
import { LastLevelCode as LastLevelCode } from 'src/app/models/syscoder/last-level-codes';
import { SingleCode } from 'src/app/models/syscoder/single-code';
import { CoderService } from 'src/app/services/coder.service';
import {Clipboard} from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-coder-generator',
  templateUrl: './coder-generator.component.html',
  styleUrls: ['./coder-generator.component.scss']
})
export class CoderGeneratorComponent implements OnInit, OnChanges {

  loaded = false;
  codeCtrl = new FormControl();
  filteredcodes: Observable<AutocompObj[]>;
  selectedcode: AutocompObj;
  codesForSel: AutocompObj[] = [];

  allcodes: LastLevelCode[];
  newcode: LastLevelCode;

  historycodes: LastLevelCode[];
  userid: string = localStorage.getItem('id');


  constructor(private codesrv: CoderService, private clipboard: Clipboard, private snackBar: MatSnackBar) { }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes)
  }

  ngOnInit(): void {
    this.codesrv.loadAllCodes()
    .subscribe(res => {
        this.allcodes = res
        this.codesForSel = [];
        for(let code of res){
          this.codesForSel.push({id: code.id, text: code.description})
        }

        this.filteredcodes = this.codeCtrl.valueChanges
        .pipe(
          startWith(''),
          map(code => this._filterCode(code))
        );

        this.codesrv.loadHistoryCodes()
          .subscribe(hc => {
            this.historycodes = hc
            this.loaded = true;
          })
    })
  }

  private _filterCode(value: string): AutocompObj[] {
    if(typeof(value) === 'string'){
      const filterValue = value?.toLowerCase() ?? '';
      return this.codesForSel.filter(code => code.text.toLowerCase().indexOf(filterValue) > -1);
    }
  }

  public getDisplayFn() {
    return (val) => this.display(val);
  }

  private display(code): string {
    //access component "this" here
    console.log(code)
    if(code){
      this.selectedcode = code;
      this.LoadCode()
    }
    return code ? code.text : code;
  }

  LoadCode(){
    if(this.selectedcode !== null && this.selectedcode !== undefined && typeof(this.selectedcode) !== 'string' && this.selectedcode.id !== ''){
      this.newcode = this.allcodes.filter(c => c.id === this.selectedcode.id)[0]
    }
    else{
      this.newcode = null;
    }
  }

  RegisterCode(){
    this.codesrv.saveCode(this.newcode)
      .subscribe(() => {
        this.codesrv.loadHistoryCodes()
          .subscribe(hc => {
            this.historycodes = hc
            this.clipboard.copy(this.newcode.code)
            this.snackBar.open(`Codice ${this.newcode.code} registrato e copiato negli appunti`, '', {duration: 3000})
          })
      })
  }

  CopyCode(code: LastLevelCode){
    this.clipboard.copy(code.code)
    this.snackBar.open(`Codice ${code.code} copiato negli appunti`, '', {duration: 3000})
  }
}
