<mat-card class="helmcard">
  <mat-card-header>
    <span class="underline-text-nebula">
      <h5 class="mdc-text left" class="assettitle">Commesse</h5>
    </span>
    <div class="right">
      <mat-form-field class="row">
        <mat-label>Anno di riferimento</mat-label>
        <mat-select #ysel name="annorif" [(ngModel)]="annorif" (valueChange)="YearChanged(ysel)">
          <mat-option value="2020">2020</mat-option>
          <mat-option value="2021">2021</mat-option>
          <mat-option value="2022">2022</mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-fab class="menubtn" color="primary" matTooltip="Calendario" routerLink="/helm/orders/all/calendar" matTooltipPosition="below">
        <i class="material-icons">calendar_today</i></button>
      <button *ngIf="userprivilege == 'admin'" mat-fab class="menubtn" color="primary" matTooltip="Nuova commessa" routerLink="/helm/orders/new" matTooltipPosition="below">
        <i class="material-icons">add</i></button>
      <button mat-fab class="menubtn" color="primary" matTooltip="Indietro" routerLink="/helm" matTooltipPosition="below">
        <i class="material-icons">close</i></button>
    </div>
  </mat-card-header>
  <mat-card-content>
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef> Codice </th>
        <td mat-cell *matCellDef="let element"> {{element.code}} </td>
      </ng-container>
      <ng-container matColumnDef="company">
        <th mat-header-cell *matHeaderCellDef> Cliente </th>
        <td mat-cell *matCellDef="let element"> {{element.client.name}} </td>
      </ng-container>
      <ng-container matColumnDef="desc">
        <th mat-header-cell *matHeaderCellDef> Descrizione </th>
        <td mat-cell *matCellDef="let element"> {{element.description}} </td>
      </ng-container>
      <ng-container matColumnDef="comm">
        <th mat-header-cell *matHeaderCellDef> Commerciale </th>
        <td mat-cell *matCellDef="let element"> {{element.commerciale ? element.commerciale.surname : ''}} {{element.commerciale ? element.commerciale.name : ''}}</td>
      </ng-container>
      <ng-container matColumnDef="price_offered">
        <th mat-header-cell *matHeaderCellDef> Offerto </th>
        <td mat-cell *matCellDef="let element"> {{element.costs.price_offered | currency: ' € '}} </td>
      </ng-container>
      <ng-container matColumnDef="price_awarded">
        <th mat-header-cell *matHeaderCellDef> Venduto </th>
        <td mat-cell *matCellDef="let element"> {{element.price_awarded | currency: ' € '}} </td>
      </ng-container>
      <ng-container matColumnDef="perc_chiusura">
        <th mat-header-cell *matHeaderCellDef> Chiusura commessa </th>
        <td mat-cell *matCellDef="let element"> {{element.status.perc_chiusura}} % </td>
      </ng-container>
      <ng-container matColumnDef="erogato">
        <th mat-header-cell *matHeaderCellDef> Erogato </th>
        <td mat-cell *matCellDef="let element"> {{element.status.erogato | currency: ' € '}} </td>
      </ng-container>
      <ng-container matColumnDef="da_erogare">
        <th mat-header-cell *matHeaderCellDef> Da Erogare </th>
        <td mat-cell *matCellDef="let element"> {{element.status.da_erogare | currency: ' € '}} </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Azioni </th>
        <td mat-cell *matCellDef="let element" style="text-align: center;">
          <div>
            <a *ngIf="userprivilege != 'viewer'" matTooltip="Visualizza repository" matTooltipPosition="below" style="cursor: pointer;" (click)="navigateTo(element.code)"><i class="material-icons nebula-dark">folder</i></a>

            <a *ngIf="userprivilege == 'admin'" style="cursor: pointer;" matTooltip="Edita status" matTooltipPosition="below" (click)="editStatus(element)"><i class="material-icons nebula-dark">percent</i></a>
          </div>
          <div>
            <a *ngIf="userprivilege != 'viewer'" matTooltip="Visualizza commessa" matTooltipPosition="below" [routerLink]="['/helm/orders/view/main/', element.orderId]"><i class="material-icons nebula-dark">remove_red_eye</i></a>
            <a *ngIf="userprivilege == 'admin'" matTooltip="Edita commessa" matTooltipPosition="below" [routerLink]="['/helm/orders/edit/main/', element.orderId]"><i class="material-icons nebula-dark">edit</i></a>
            <a *ngIf="userprivilege == 'admin'" matTooltip="Elimina commessa" matTooltipPosition="below" routerLink="/helm/orders" (click)="openDialog(element.orderId)"><i class="material-icons nebula-dark">delete</i></a>
          </div>


        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </mat-card-content>
</mat-card>
