<mat-card id="calendar-container" *ngIf="loaded">
  <mat-card-header id="sec-header">
    <span class="underline-text-nebula">
      <h5 class="nebula-dark left" class="assettitle">{{selOrderWbs.orderCode}}</h5>
    </span>
    <div class="right" *ngIf="selOrderWbs.wbsPlanned.allwbs.length > 0">
      <button *ngIf="!editing" mat-fab class="menubtn" color="primary" (click)="SetWbsEnv('info')" matTooltip="Lista WBS" matTooltipPosition="below">
        <i class="material-icons">list</i></button>
    </div>
  </mat-card-header>
  <mat-card-content>
    <div id="calendar-container" *ngIf="loaded">
      <mat-grid-list cols="3" rowHeight="100px">
        <mat-grid-tile>
          <div class="btn-group">
            <button mat-button color="primary" mwlCalendarPreviousView [view]="view" [excludeDays]="excludeDays"
              [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()"> Precedente </button>
            <button mat-button color="primary" mwlCalendarToday [(viewDate)]="viewDate" > Oggi </button>
            <button mat-button color="primary" mwlCalendarNextView [view]="view" [excludeDays]="excludeDays"
              [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()"> Successivo </button>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <h2 class="acad_dark_gray">{{ viewDate | calendarDate:(view + 'ViewTitle'):'it':weekStartsOn:excludeDays }}</h2>
        </mat-grid-tile>
        <mat-grid-tile>
          <!-- <div class="btn-group">
            <button mat-button color="primary" (click)="setView(CalendarView.Month)"
              [class.active]="view === CalendarView.Month"> Mese </button>
            <button mat-button color="primary" (click)="setView(CalendarView.Week)"
              [class.active]="view === CalendarView.Week"> Settimana </button>
            <button mat-button color="primary" (click)="setView(CalendarView.Day)"
              [class.active]="view === CalendarView.Day"> Giorno </button>
          </div> -->
        </mat-grid-tile>
      </mat-grid-list>
      <div [ngSwitch]="view">
        <mwl-calendar-month-view
          *ngSwitchCase="CalendarView.Month"
          locale="it"
          [viewDate]="viewDate"
          [events]="events"
          [refresh]="refresh"
          [activeDayIsOpen]="activeDayIsOpen"
          [weekStartsOn]="weekStartsOn"
          [weekendDays]="weekendDays"
          [excludeDays]="excludeDays"
          (dayClicked)="dayClicked($event.day)"
          (eventClicked)="handleEvent('Clicked', $event.event)"
          (eventTimesChanged)="eventTimesChanged($event)"
        >
        </mwl-calendar-month-view>
    <!--     <mwl-calendar-week-view
          *ngSwitchCase="CalendarView.Week"
          [viewDate]="viewDate"
          [events]="events"
          [refresh]="refresh"
          [weekStartsOn]="weekStartsOn"
          [weekendDays]="weekendDays"
          [excludeDays]="excludeDays"
          (eventClicked)="handleEvent('Clicked', $event.event)"
          (eventTimesChanged)="eventTimesChanged($event)"
        >
        </mwl-calendar-week-view> -->
    <!--     <mwl-calendar-day-view
          *ngSwitchCase="CalendarView.Day"
          [viewDate]="viewDate"
          [events]="events"
          [refresh]="refresh"
          (eventClicked)="handleEvent('Clicked', $event.event)"
          (eventTimesChanged)="eventTimesChanged($event)"
        >
        </mwl-calendar-day-view> -->
      </div>
    </div>

  </mat-card-content>
</mat-card>
