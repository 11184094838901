
<form #fm="ngForm">
  <mat-dialog-actions align="end">
    <button mat-fab class="menubtn" color="primary" matTooltip="Salva" matTooltipPosition="below" (click)="SaveStatus(fm)" [disabled]="fm.invalid" type="submit">
      <i class="material-icons">save</i></button>
    <button mat-fab class="menubtn" color="primary" matTooltip="Indietro" mat-dialog-close matTooltipPosition="below">
      <i class="material-icons">close</i></button>
  </mat-dialog-actions>

  <mat-dialog-content>
      <div class="internalMatGrid">
        <mat-grid-list [cols]=3  rowHeight="60px">
          <mat-grid-tile [colspan]=3>
            <div class="title" style="margin-top: 30px; ">
              <span class="underline-text-nebula">
                <h4 class="mdc-text left assettitle">Edita status commessa</h4>
              </span>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=3> </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Chiusura commessa</mat-label>
                <span matPrefix>% &nbsp;</span>
                <input matInput type="number" name="perc_chiusura" [(ngModel)]="ordstatus.perc_chiusura" required>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Erogato</mat-label>
                <span matPrefix>€ &nbsp;</span>
                <input matInput type="number" name="erogato" #er [ngModel]="ordstatus.erogato" required (change)="Ricalcola('erogato', er)">
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Da erogare</mat-label>
                <span matPrefix>€ &nbsp;</span>
                <input matInput type="number" name="da_erogare" #daer [ngModel]="ordstatus.da_erogare" required (change)="Ricalcola('da_erogare', daer)">
              </mat-form-field>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
  </mat-dialog-content>
</form>

