import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HelmService } from 'src/app/services/helm.service';
import { ThreejsService } from 'src/app/services/threejs.service';
import { Viewerx3dService } from 'src/app/services/viewerx3d.service';
import * as THREE from 'three';

@Component({
  selector: 'app-viewer-three-js',
  templateUrl: './viewer-three-js.component.html',
  styleUrls: ['./viewer-three-js.component.scss']
})
export class ViewerThreeJSComponent implements OnInit, AfterViewInit {

  @Input()
  prodline: boolean;
  sel3dPath: string;
  prodcode: string;
  filename: string;
  hidecanvas: string = '';
  link: boolean = false;

  markup: boolean = false;
  markupImg: any;

  // HELPER PROPERTIES
  @ViewChild('canvas')
  private canvasRef : ElementRef;

  @ViewChild('spinner')
  private spinnerRef : ElementRef;

  @ViewChild('cube')
  private cubeRef : ElementRef;


  public constructor(private threejsrv: ThreejsService, private route: ActivatedRoute, private helmsrv: HelmService) {
    this.route.queryParams
    .subscribe(params => {
      this.prodline = params.prodline == 'f' ? false : true;
      //this.link = params.link ? true : false;
      this.link = false
      this.prodline= false
      if(this.prodline){
        this.sel3dPath = params.path;
        helmsrv.LoadSelectedProductline(params.path)
          .subscribe(pl => {

            this.prodcode = pl.code;
            this.filename = pl.code;
          })
      }
      else{
        this.sel3dPath = params.path;
        this.prodcode = this.sel3dPath.replace('/REPO/PRODUCTS/', '');
        this.prodcode = this.prodcode.slice(0,this.prodcode.indexOf('/'));
        this.filename = this.sel3dPath.slice(this.sel3dPath.lastIndexOf('/') + 1, this.sel3dPath.length);

      }

    });
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {

    this.threejsrv.createScene(this.sel3dPath, this.prodline, this.cubeRef, this.canvasRef, this.spinnerRef, this.link);
    // this.threejsrv.animate();
  }


    //#region  Markup
    loadMarkup(){
      this.markupImg = this.threejsrv.snapshot();
      this.markup = true;
      this.hidecanvas = 'display: none';
    }

    exitMarkup(value: string){
      if(value == 'Close'){
        this.markup = false;
        this.hidecanvas = '';
      }
    }
    //#endregion
}
