import * as THREE from 'three';
import {ElementRef, Injectable, NgZone, OnDestroy} from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, map, Observable, throwError } from 'rxjs';
import {FBXLoader} from 'three/examples/jsm/loaders/FBXLoader.js'
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js'
import {SVGLoader} from 'three/examples/jsm/loaders/SVGLoader.js'
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls.js'
import {OutlineEffect } from 'three/examples/jsm/effects/OutlineEffect.js'

const DEFAULT_CAMERA = '[default]';


@Injectable({
  providedIn: 'root'
})
export class ThreejsService  implements OnDestroy {
  productLine: boolean;
  filepath: string;
  prodId: string;
  link: boolean;

  defaultCamera: any;
  activeCamera: any;
  canvas: HTMLCanvasElement;
  spinner: HTMLCanvasElement;
  cube: HTMLElement;
  lateralview : boolean = false;
  renderer: THREE.WebGLRenderer;
  scene: THREE.Scene;
  allfiles: any[] = [];
  allobjectsceen: any[] = [];
  mat: THREE.Matrix4 = new THREE.Matrix4();
  controls: OrbitControls;
  pickPosition: any = {};
  state: any;
  gui: any;
  center: any;
  size: any;
  box: any;
  cameraCtrl = null;
  cameraFolder = null;
  animFolder = null;
  animCtrls = [];
  morphFolder = null;
  morphCtrls = [];
  skeletonHelpers = [];
  gridHelper = null;
  axesHelper = null;
  background = null;
  content = null;
  prevTime = 0;
  mixer = null;
  clips;
  lights = [];
  options = {
      model: '',
      preset: '',
      cameraPosition: null,
      spinner: null
  }
  mesh: any;

  MAP_NAMES = [
    'map',
    'aoMap',
    'emissiveMap',
    'glossinessMap',
    'metalnessMap',
    'normalMap',
    'roughnessMap',
    'specularMap',
  ];

  private file: Blob;

  option!: HttpHeaders;
  private frameId: number = null;

  public constructor(private ngZone: NgZone, private http: HttpClient) {
  }

  public ngOnDestroy(): void {
    if (this.frameId != null) {
      cancelAnimationFrame(this.frameId);
    }
    if (this.renderer != null) {
      this.renderer.dispose();
      this.renderer = null;
      this.canvas = null;
    }
  }

  public createScene(path: string, productLine:boolean, cube: ElementRef<HTMLElement>,
    canvas: ElementRef<HTMLCanvasElement>, spinner: ElementRef<HTMLCanvasElement>, link: boolean): THREE.WebGLRenderer {
    this.filepath = path;
    this.productLine = productLine;
    this.link = link;
    this.state = {
      environment: 'Footprint Court (HDR)',
      background: false,
      playbackSpeed: 1.0,
      actionStates: {},
      camera: DEFAULT_CAMERA,
      wireframe: false,
      skeleton: true,
      grid: false,

      // Lights
      addLights: true,
      exposure: 1,
      textureEncoding: 'sRGB',
      ambientIntensity: 1.3,
      ambientColor: 0xccccCC,
      directIntensity: 0.8 * Math.PI, // TODO(#116)
      directColor: 0x880808,
      bgColor1: '#ffffff',
      bgColor2: '#353535'
    };

    this.spinner = spinner.nativeElement;
    this.canvas = canvas.nativeElement;
    this.cube = cube.nativeElement;

    this.renderer = new THREE.WebGLRenderer({
      preservingDrawingBuffer: false,
      canvas: this.canvas,
      alpha: true,    // transparent background
      antialias: true, // smooth edges
      //powerPreference: "high-performance"
    });

    this.renderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight);

    // create the scene
    this.scene = new THREE.Scene();

    this.defaultCamera = new THREE.PerspectiveCamera(
      75, this.canvas.clientWidth / this.canvas.clientHeight, 0.1, 1000
    );
    this.defaultCamera.position.z = 5;
    this.scene.add(this.defaultCamera);

    this.showSpinner();
    this.canvas.addEventListener('resize', this.resize.bind(this), false);

/*     this.GetFBXModel(this.filepath)
    .subscribe(res => {
      if(this.productLine){
        this.loadFBXpl(res)
          .then( () => {
            this.setFBXContentPL();
            this.setCamera();
            this.hideSpinner();
            this.loadCube();
            this.canvas.addEventListener('click', this.setPickPosition.bind(this));
          });

      } else {
        this.loadFBXp(res[0])
        .then( () => {
          this.setCamera();
          this.hideSpinner();
          this.loadCube();
          this.canvas.addEventListener('click', this.setPickPosition.bind(this));
        });
      }

    }); */

/*    this.GetGltfModel(this.filepath)
      .subscribe(res => {
        if(this.productLine){
          this.loadGLTFpl(res)
            .then( () => {
              this.setGltfContentPL();
              this.setCamera();
              this.hideSpinner();
              this.loadCube();
              this.canvas.addEventListener('click', this.setPickPosition.bind(this));
            });

        } else {
          this.loadGLTFp(res)
          .then( () => {
            this.setCamera();
            this.hideSpinner();
            this.loadCube();
            this.canvas.addEventListener('click', this.setPickPosition.bind(this));
          });
        }

      });*/

/*       this.GetGltfModel(this.filepath)
      .subscribe(res => {
        if(this.productLine){
          this.loadGLTFpl(res)
            .then( () => {
              this.setGltfContentPL();
              this.setCamera();
              this.hideSpinner();
              this.loadCube();
              this.canvas.addEventListener('click', this.setPickPosition.bind(this));
            });

        } else {
          this.loadGLTFp(res)
          .then( () => {
            this.setCamera();
            this.hideSpinner();
            this.loadCube();
            this.canvas.addEventListener('click', this.setPickPosition.bind(this));
          });
        }



      }); */

      this.GetSvg(this.filepath)
      .subscribe(res => {
        console.log(res);
        this.loadSVG(res)
          .then( () => {
            this.hideSpinner();
            this.canvas.addEventListener('click', this.setPickPosition.bind(this));
          });

      });
    return this.renderer;
  }

  //#region SVG
  GetSvg(path: string): Observable<any>{
    let ApiUrl;
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    ApiUrl = environment.mainUrl + 'prod3dfile';
    return this.http.get(ApiUrl, { params: {path: path, link: this.link}, headers:this.option})
  }

  loadSVG(path: string) {
    // Load.
    return new Promise((resolve, reject) => {
        const loader = new SVGLoader();
        loader.parse(path)

        const fillMaterial = new THREE.MeshBasicMaterial({ color: "#F3FBFB" });
        const stokeMaterial = new THREE.LineBasicMaterial({
          color: "#00A5E6",
        });

        loader.load(path, (file) => {
          console.log(file);
          const scene = file;
          const clips = file.animations || [];
          this.scene.add(file);
          //this.setSVGfContent(scene, clips);
          resolve(file);
        }, undefined, reject);
      });
  }

  setSVGfContent(object, clips) {
    this.clear();
    this.scene.add(object);
    //console.info('[FBX Viewer] THREE.Scene exported as `window.scene`.');
  }
  //#endregion

  //#region FBX
  GetFBXModel(path: string): Observable<any>{
    let ApiUrl;
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    ApiUrl = environment.mainUrl + 'fbxfile';
    if(this.productLine){
      return this.http.get(ApiUrl, { params: {prodline: true, abs_path: path}, headers:this.option})
    } else{
      return this.http.get(ApiUrl, { params: {prodline: false, abs_path: path}, headers:this.option})
    }
  }

  loadFBXpl(allpath: string[]) {
    const promises = [];
    allpath.forEach(path => {
      const promise = this.waitLoading(path);
      promises.push(promise);});
    return Promise.all(promises).then(() => {});
  }

  waitLoadingFBX(path: string){
    return new Promise((resolve, reject) => {
      const loader = new FBXLoader();
      loader.load(path, (file) => {
        this.allfiles.push(file);
        resolve(file);
      }, undefined, reject);

    });
  }

  setFBXContentPL() {

    for(let file of this.allfiles){
      this.allobjectsceen.push(file.scene);
      this.scene.add(file.scene);
      this.content = file.scene;

      this.state.addLights = true;
      this.content.traverse((node) => {
          if (node.isLight) {
              this.state.addLights = false;
          }
      });

      this.setClips([]);
      this.updateLights();
      this.updateTextureEncoding();
      this.updateDisplay();
      this.animate();
    }


    //console.info('[FBX Viewer] THREE.Scene exported as `window.scene`.');
  }

  loadFBXp(path: string) {
    // Load.
    return new Promise((resolve, reject) => {
        const loader = new FBXLoader();

        loader.load(path, (file) => {
          const scene = file;
          const clips = file.animations || [];
          this.setFBXContentP(scene, clips);
          resolve(file);
        }, undefined, reject);
      });
  }

  setFBXContentP(object, clips) {
    this.clear();
    console.log(object);

    this.box = new THREE.Box3().setFromObject(object);
    this.center = this.box.getCenter(new THREE.Vector3());
    object.position.x += (object.position.x - this.center.x);
    object.position.y += (object.position.y - this.center.y);
    object.position.z += (object.position.z - this.center.z);

    this.allobjectsceen.push(object);
    this.scene.add(object);
    this.content = object;

    this.state.addLights = true;
    this.content.traverse((node) => {
        if (node.isLight) {
            this.state.addLights = false;
            this.lights.push(node);
        }
    });

    console.log(this.lights);

    this.setClips(clips);
    this.updateLights();
    this.updateTextureEncoding();
    this.updateDisplay();
    this.animate();
    console.log(this.lights);
    //console.info('[FBX Viewer] THREE.Scene exported as `window.scene`.');
  }
  //#endregion

  //#region GLTF
  GetGltfModel(path: string): Observable<any>{
    let ApiUrl;
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));

    if(this.productLine){
      ApiUrl = environment.mainUrl + 'prodlin3dfiles';
      return this.http.get(ApiUrl, { params: {id: path, link: this.link}, headers:this.option})
    } else{
      ApiUrl = environment.mainUrl + 'prod3dfile';
      return this.http.get(ApiUrl, { params: {path: path, link: this.link}, headers:this.option})
    }
  }

  loadGLTFpl(allpath: string[]) {
    const promises = [];
    allpath.forEach(path => {
      const promise = this.waitLoading(path);
      promises.push(promise);});
    return Promise.all(promises).then(() => {});
  }

  waitLoading(path: string){
    return new Promise((resolve, reject) => {
      const loader = new GLTFLoader();
      loader.load(path, (file) => {
        this.allfiles.push(file);
        resolve(file);
      }, undefined, reject);

    });
  }

  setGltfContentPL() {

    for(let file of this.allfiles){
      this.allobjectsceen.push(file.scene);
      this.scene.add(file.scene);
      this.content = file.scene;

      this.state.addLights = true;
      this.content.traverse((node) => {
          if (node.isLight) {
              this.state.addLights = false;
          }
      });

      this.setClips([]);
      this.updateLights();
      this.updateTextureEncoding();
      this.updateDisplay();
      this.animate();
    }


    //console.info('[FBX Viewer] THREE.Scene exported as `window.scene`.');
  }

  loadGLTFp(path: string) {
    // Load.
    return new Promise((resolve, reject) => {
        const loader = new GLTFLoader();

        loader.load(path, (file) => {
          const scene = file;
          const clips = file.animations || [];
          this.setGltfContentP(scene, clips);
          resolve(file);
        }, undefined, reject);
      });
  }

  setGltfContentP(object, clips) {
    this.clear();
    //console.log(object);

    this.box = new THREE.Box3().setFromObject(object.scene);
    this.center = this.box.getCenter(new THREE.Vector3());
    object.scene.position.x += (object.scene.position.x - this.center.x);
    object.scene.position.y += (object.scene.position.y - this.center.y);
    object.scene.position.z += (object.scene.position.z - this.center.z);

    this.allobjectsceen.push(object.scene);
    this.scene.add(object.scene);
    this.content = object.scene;

    this.state.addLights = true;
    this.content.traverse((node) => {
        if (node.isLight) {
            this.state.addLights = false;
        }
    });

    this.setClips(clips);
    this.updateLights();
    this.updateTextureEncoding();
    this.updateDisplay();
    this.animate();
    //console.info('[FBX Viewer] THREE.Scene exported as `window.scene`.');
  }
  //#endregion

  //#region SVG

  //#endregion
  //#region VIEWER UTILS
  animate(): void {
    // We have to run this outside angular zones,
    // because it could trigger heavy changeDetection cycles.
    this.ngZone.runOutsideAngular(() => {
      if (document.readyState !== 'loading') {
        this.render();
      } else {
        this.canvas.addEventListener('DOMContentLoaded', () => {
          this.render();
        });
      }

      this.canvas.addEventListener('resize', () => {
        this.resize();
      });
    });
  }

  renderSVG () {
/*     requestAnimationFrame(this.render);
    mesh.rotation.y += 0.01;
    renderer.render(scene, camera); */
  };

  render(): void {
    this.frameId = requestAnimationFrame(() => {
      this.render();
    });

    /* this.cube.rotation.x += 0.01;
    this.cube.rotation.y += 0.01; */

    this.renderer.render(this.scene, this.defaultCamera);

/*     var effect = new OutlineEffect( this.renderer );
    effect.render(this.scene, this.defaultCamera); */

    // var ef = new
  }

  resize(): void {
    const width = this.canvas.clientWidth;
    const height = this.canvas.clientHeight;

    this.defaultCamera.aspect = width / height;
    this.defaultCamera.updateProjectionMatrix();

    this.renderer.setSize(width, height);
  }

  clear() {

    if (!this.content) {
        return;
    }

    this.scene.remove(this.content);

    // dispose geometry
    this.content.traverse((node) => {

        if (!node.isMesh) {
            return;
        }

        node.geometry.dispose();

    });

    // dispose textures
    this.traverseMaterials(this.content, (material) => {

        this.MAP_NAMES.forEach((map) => {

            if (material[map]) {
                material[map].dispose();
            }

        });

    });

  }

  getCubeMapTexture(environment) {
    const {path, format} = environment;

    // no envmap
    if (!path) {
        return Promise.resolve({envMap: null, cubeMap: null});
    }

    const cubeMapURLs = [
        path + 'posx' + format, path + 'negx' + format,
        path + 'posy' + format, path + 'negy' + format,
        path + 'posz' + format, path + 'negz' + format
    ];

    // standard
    const envMap = new THREE.CubeTextureLoader().load(cubeMapURLs);
    //envMap.format = THREE.RGBFormat;
    return Promise.resolve({envMap, cubeMap: envMap});
  }

  updateDisplaySVG() {
    if (this.skeletonHelpers.length) {
      this.skeletonHelpers.forEach((helper) => this.scene.remove(helper));
    }

    if (this.state.grid !== Boolean(this.gridHelper)) {
        if (this.state.grid) {
            this.gridHelper = new THREE.GridHelper(5,1);
            this.axesHelper = new THREE.AxesHelper();
            this.axesHelper.renderOrder = 999;
            this.axesHelper.onBeforeRender = (renderer) => renderer.clearDepth();
            this.scene.add(this.gridHelper);
            this.scene.add(this.axesHelper);
        } else {
            this.scene.remove(this.gridHelper);
            this.scene.remove(this.axesHelper);
            this.gridHelper = null;
            this.axesHelper = null;
        }
    }
  }

  updateDisplay() {
    if (this.skeletonHelpers.length) {
      this.skeletonHelpers.forEach((helper) => this.scene.remove(helper));
    }


    this.traverseMaterials(this.content, (material) => {
      material.wireframe = this.state.wireframe;
    });

    this.content.traverse((node) => {
        if (node.isMesh && node.skeleton && this.state.skeleton) {
          const helper = new THREE.SkeletonHelper(node.skeleton.bones[0].parent);
          helper.material.linewidth = 3;
          this.scene.add(helper);
          this.skeletonHelpers.push(helper);
        }
    });

    if (this.state.grid !== Boolean(this.gridHelper)) {
        if (this.state.grid) {
            this.gridHelper = new THREE.GridHelper(5,1);
            this.axesHelper = new THREE.AxesHelper();
            this.axesHelper.renderOrder = 999;
            this.axesHelper.onBeforeRender = (renderer) => renderer.clearDepth();
            this.scene.add(this.gridHelper);
            this.scene.add(this.axesHelper);
        } else {
            this.scene.remove(this.gridHelper);
            this.scene.remove(this.axesHelper);
            this.gridHelper = null;
            this.axesHelper = null;
        }
    }
  }

  traverseMaterials(object, callback) {
    object.traverse((node) => {
        if (!node.isMesh) {
            return;
        }
        const materials = Array.isArray(node.material)
            ? node.material
            : [node.material];
        materials.forEach(callback);
    });
  }

  updateTextureEncoding() {
    const encoding = this.state.textureEncoding === 'sRGB'
        ? THREE.sRGBEncoding
        : THREE.LinearEncoding;
    this.traverseMaterials(this.content, (material) => {
        if (material.map) {
            material.map.encoding = encoding;
        }
        if (material.emissiveMap) {
            material.emissiveMap.encoding = encoding;
        }
        if (material.map || material.emissiveMap) {
            material.needsUpdate = true;
        }
    });
  }

  setClips(clips) {
    if (this.mixer) {
        this.mixer.stopAllAction();
        this.mixer.uncacheRoot(this.mixer.getRoot());
        this.mixer = null;
    }

    this.clips = clips;
    if (!clips.length) {
        return;
    }

    this.mixer = new THREE.AnimationMixer(this.content);
  }

  setCamera() {
    let allsizes: number[] = [];
    let allcenters = [];

    for(let oscene of this.allobjectsceen){
      let box = new THREE.Box3().setFromObject(oscene);
      allsizes.push(box.getSize(new THREE.Vector3()).length());
      let center = box.getCenter(new THREE.Vector3());
      allcenters.push(center);
    }

    let maxsize: number = Math.max(...allsizes);
    //var maxsize: number = allsizes[0];
    this.size = maxsize;

    var maincenter = new THREE.Vector3();
    maincenter.x = this.median(allcenters, 'x');
    maincenter.y = this.median(allcenters, 'y');
    maincenter.z = this.median(allcenters, 'z');

    this.center = maincenter;
    this.defaultCamera.position.copy(this.center);
    this.defaultCamera.position.x += this.size / 2;
    this.defaultCamera.position.y += this.size / 2;
    this.defaultCamera.position.z += this.size / 2;
    this.defaultCamera.lookAt(this.center);
    this.defaultCamera.near = this.size / 100;
    this.defaultCamera.far = this.size * 100;

    this.defaultCamera.name = "PERSPECTIVE";

    this.defaultCamera.updateProjectionMatrix();

    this.controls = new OrbitControls(this.defaultCamera, this.renderer.domElement);
    this.controls.autoRotate = false;
    this.controls.autoRotateSpeed = -10;
    this.controls.enablePan = true;
    this.controls.maxDistance = this.size * 10;
    this.controls.enabled = true;
    this.controls.update();

    this.activeCamera = this.defaultCamera;
    //console.log(this.defaultCamera)

    this.render();

  }

  median(values, coord){
    if(values.length == 1 ) return values[0][coord];

    let sum = 0;
    for(let val of values){
      sum += val[coord];
    }

    return sum / values.length;
  }

  maxboxval(boxes, coord){

    if(boxes.length == 1 ) return boxes[0].max[coord];

    let allval = [];
    for(let box of boxes){
      allval.push(box.max[coord]);
    }

    return Math.max(...allval);
  }

  minboxval(boxes, coord){

    if(boxes.length == 1 ) return boxes[0].min[coord];

    let allval = [];
    for(let box of boxes){
      allval.push(box.min[coord]);
    }

    return Math.min(...allval);
  }

  degInRad(deg) {
    return deg * Math.PI / 180;
  }

  updateLights() {
    const state = this.state;
    const lights = this.lights;

    if (state.addLights && !lights.length) {
        this.addLights();
    } else if (!state.addLights && lights.length) {
        this.removeLights();
    }

    this.renderer.toneMappingExposure = state.exposure;

    if (lights.length === 2) {
        lights[0].intensity = state.ambientIntensity;
        lights[0].color.setHex(state.ambientColor);
        lights[1].intensity = state.directIntensity;
        lights[1].color.setHex(state.directColor);
    }
  }

  addLights() {
    const state = this.state;
    const hemiLight = new THREE.HemisphereLight();
    hemiLight.name = 'hemi_light';
    hemiLight.intensity = state.ambientIntensity;
    hemiLight.color.setHex(state.ambientColor);
    this.scene.add(hemiLight);
    this.lights.push(hemiLight);
    return;
  }

  removeLights() {
      this.lights.forEach((light) => light.parent.remove(light));
      this.lights.length = 0;
  }
  //#endregion

  //#region SPINNER
  showSpinner() {
    this.spinner.style.display = '';
    this.cube.style.display = 'none';
  }

  hideSpinner() {
    this.spinner.style.display = 'none';
    this.cube.style.display = '';

  }
  //#endregion

  //#region MARKUP
  public snapshot(): string{
    this.renderer.render(this.scene, this.defaultCamera);
    return this.renderer.domElement.toDataURL("image/png");
  }
  //#endregion

  //#region CUBE
  loadCube(): void{
    this.renderer.setAnimationLoop(() => {
      this.mat.extractRotation( this.defaultCamera.matrixWorldInverse );
      this.cube.style.transform = `translateZ(-300px) ${this.getCameraCSSMatrix( this.mat )}`;
    })

    this.ngZone.runOutsideAngular(() => {
      for(let face in this.cube.children){
        if(this.cube.children[face].attributes){
          if(this.cube.children[face].getAttribute('id') == "cfront"){
            this.setFace( this.cube.children[face] as HTMLElement, 'F')
          }
          else if(this.cube.children[face].getAttribute('id') == "cback"){
            this.setFace( this.cube.children[face] as HTMLElement, 'Ba')
          }
          else if(this.cube.children[face].getAttribute('id') == "ctop"){
            this.setFace( this.cube.children[face] as HTMLElement, 'T')
          }
          else if(this.cube.children[face].getAttribute('id') == "cbottom"){
            this.setFace( this.cube.children[face] as HTMLElement, 'Bo')
          }
          else if(this.cube.children[face].getAttribute('id') == "cleft"){
            this.setFace( this.cube.children[face] as HTMLElement, 'L')
          }
          else if(this.cube.children[face].getAttribute('id') == "cright"){
            this.setFace( this.cube.children[face] as HTMLElement, 'R')
          }
        }
      }
    })
  }

  setFace(cubeFace : HTMLElement, face : string){
    let oldbkg = cubeFace.style.backgroundColor;
    cubeFace.addEventListener('mouseenter', () => {
      //cubeFace.style.opacity = '0.9';
      cubeFace.style.backgroundColor = 'red'
    });

    cubeFace.addEventListener('mouseleave', () => {
      cubeFace.style.opacity = '1';
      cubeFace.style.backgroundColor = oldbkg
    });

    cubeFace.addEventListener('click', () => {
      this.goToDefaultView(face);
    });
  }

  getCameraCSSMatrix(matrix) {

    var elements = matrix.elements;

    return 'matrix3d(' +
      this.epsilon(elements[0]) + ',' +
      this.epsilon(-elements[1]) + ',' +
      this.epsilon(elements[2]) + ',' +
      this.epsilon(elements[3]) + ',' +
      this.epsilon(elements[4]) + ',' +
      this.epsilon(-elements[5]) + ',' +
      this.epsilon(elements[6]) + ',' +
      this.epsilon(elements[7]) + ',' +
      this.epsilon(elements[8]) + ',' +
      this.epsilon(-elements[9]) + ',' +
      this.epsilon(elements[10]) + ',' +
      this.epsilon(elements[11]) + ',' +
      this.epsilon(elements[12]) + ',' +
      this.epsilon(-elements[13]) + ',' +
      this.epsilon(elements[14]) + ',' +
      this.epsilon(elements[15]) +
      ')';

  }

  epsilon( value ) {

    return Math.abs( value ) < 1e-10 ? 0 : value;

  }

  public goToDefaultView(side: string){
    if(this.lateralview){
      this.defaultCamera = new THREE.PerspectiveCamera(
        75, this.canvas.clientWidth / this.canvas.clientHeight, 0.1, 1000
      );
      this.setCamera();
      this.lateralview = false;
    }
    else{
      let allsizes: number[] = [];
      let allcenters = [];
      let allboxes = [];

      //console.log(this.allobjectsceen)
      for(let oscene of this.allobjectsceen){
        let box = new THREE.Box3().setFromObject(oscene);
        allboxes.push(box);
        allsizes.push(box.getSize(new THREE.Vector3()).length());
        let center = box.getCenter(new THREE.Vector3());
        allcenters.push(center);
      }

      let maxsize: number = Math.max(...allsizes);
      this.size = maxsize;

/*       var maincenter = new THREE.Vector3();
      maincenter.x = this.median(allcenters, 'x');
      maincenter.y = this.median(allcenters, 'y');
      maincenter.z = this.median(allcenters, 'z');
      this.center = maincenter; */

      this.defaultCamera = new THREE.OrthographicCamera( );

      switch(side){
        case "T":
          this.defaultCamera.position.x = this.center.x;
          this.defaultCamera.position.y = this.maxboxval(allboxes, 'y') + this.size;
          this.defaultCamera.position.z = this.center.z;
          this.defaultCamera.rotation.x = this.degInRad(0);
          this.defaultCamera.rotation.y = this.degInRad(0);
          this.defaultCamera.rotation.z = this.degInRad(0);
        break;
        case "Bo":
          this.defaultCamera.position.x = this.center.x;
          this.defaultCamera.position.y = this.minboxval(allboxes, 'y') - this.size;
          this.defaultCamera.position.z = this.center.z;
        break;
        case "L":
          this.defaultCamera.position.x = this.minboxval(allboxes, 'x') - this.size;
          this.defaultCamera.position.y = this.center.y;
          this.defaultCamera.position.z = this.center.z;
        break;
        case "R":
          this.defaultCamera.position.x = this.maxboxval(allboxes, 'x') + this.size;
          this.defaultCamera.position.y = this.center.y;
          this.defaultCamera.position.z = this.center.z;


        break;
        case "F":
          this.defaultCamera.position.x = this.center.x;
          this.defaultCamera.position.y = this.center.y;
          this.defaultCamera.position.z = this.maxboxval(allboxes, 'z') + this.size;
        break;
        case "Ba":
          this.defaultCamera.position.x = this.center.x;
          this.defaultCamera.position.y = this.center.y;
          this.defaultCamera.position.z = this.minboxval(allboxes, 'z') - this.size;
        break;
      }

      this.defaultCamera.left = -this.size / 2;
      this.defaultCamera.right = this.size / 2;
      this.defaultCamera.bottom = -this.size / 2;
      this.defaultCamera.top = this.size / 2;
      this.defaultCamera.near = this.size / 100;
      this.defaultCamera.far = this.size * 100;
      this.defaultCamera.lookAt(this.center);

      this.defaultCamera.updateProjectionMatrix();

      this.controls = new OrbitControls(this.defaultCamera, this.renderer.domElement);
      this.controls.autoRotate = false;
      this.controls.autoRotateSpeed = -10000000;
      this.controls.enableRotate = false;
      this.controls.enablePan = true;
      this.controls.maxDistance = this.size * 10;
      this.controls.enabled = true;
      this.controls.update();

      this.activeCamera = this.defaultCamera;
      this.render();

      this.lateralview = true;
    }


  }
  //#endregion

  //#region PICKER
  setPickPosition(event) {

      const pos = this.getCanvasRelativePosition(event);

      this.pickPosition.x = (pos.x / this.canvas.width ) *  2 - 1;
      this.pickPosition.y = (pos.y / this.canvas.height) * -2 + 1;  // note we flip Y

      const pickHelper = new PickHelper();
      //console.log(this.activeCamera);
      pickHelper.pick(this.pickPosition, this.scene, this.activeCamera, this.prevTime);
  }

  clearPickPosition() {

    // unlike the mouse which always has a position
    // if the user stops touching the screen we want
    // to stop picking. For now we just pick a value
    // unlikely to pick something
    this.pickPosition.x = -100000;
    this.pickPosition.y = -100000;
  }

  getCanvasRelativePosition(event) {
    const rect = this.canvas.getBoundingClientRect();
    return {
        x: (event.clientX - rect.left) * this.canvas.width  / rect.width,
        y: (event.clientY - rect.top ) * this.canvas.height / rect.height,
    };
  }
  //#endregion
}

export class PickHelper {
  raycaster: THREE.Raycaster;
  pickedObject;
  pickedObjectSavedColor;

  constructor() {
    this.raycaster = new THREE.Raycaster();
    this.pickedObject = null;
    this.pickedObjectSavedColor = 0;
  }

  pick(normalizedPosition, scene, camera, time) {
      time *= 0.001;
    // restore the color if there is a picked object
    if (this.pickedObject) {
      this.pickedObject.material.emissive.setHex(this.pickedObjectSavedColor);
      this.pickedObject = undefined;
    }
    // cast a ray through the frustum
    this.raycaster.setFromCamera(normalizedPosition, camera);

    // get the list of objects the ray intersected
    const intersectedObjects = this.raycaster.intersectObjects(scene.children, true);
    //console.log(intersectedObjects);

    if (intersectedObjects.length) {

      // pick the first object. It's the closest one
      this.pickedObject = intersectedObjects[0].object;
      console.log(this.pickedObject.name);
      // save its color
      //this.pickedObjectSavedColor = this.pickedObject.material.emissive.getHex();
      // set its emissive color to flashing red/yellow
      //this.pickedObject.material.emissive.setHex((time * 8) % 2 > 1 ? 0xFFFF00 : 0xFF0000);
    }
  }
}
