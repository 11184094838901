import { Component, OnInit } from '@angular/core';
import { Order } from 'src/app/models/helm/order';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-new-orders',
  templateUrl: './new-orders.component.html',
  styleUrls: ['./new-orders.component.scss']
})
export class NewOrdersComponent implements OnInit {

  newOrder: Order = {
    orderId: "",
    name: "",
    code: "",
    description: "",
    year: (new Date()).getFullYear(),
    status: {
      da_erogare: 0,
      erogato: 0,
      perc_chiusura: 0
    },
    manager: {
      userId: "",
      name: "",
      surname: "",
      company: {
        companyId: "",
        name: "",
        address: "",
        logo: "",
        type: ""
      },
      privilege: "",
      user_type: ""
    },
    commerciale: {
      userId: "",
      name: "",
      surname: "",
      company: {
        companyId: "",
        name: "",
        address: "",
        logo: "",
        type: ""
      },
      privilege: "",
      user_type: ""
    },
    client: {
      companyId: "",
      name: "",
      address: "",
      logo: "",
      type: ""},
    note: "",
    date_offer: new Date(),
    date_delivery: new Date(),
    price_awarded: undefined,
    cost_tot: undefined,
    costs:{
      days_cost: undefined,
      date_acquisition: new Date(),
      days_assigned: 0,
      days_done: 0,
      ext_supp_cost: undefined,
      days_planned: undefined,
      price_offered: undefined,
      gest_clnt_cost: undefined,
      hw_sw_cost: undefined,
      proj_manag_cost: undefined,
      rapp_expenses: undefined,
      var_expenses: undefined
    },
    activity_planned: [],
    activity_done: [],
    members: new Array()
  }

  constructor(private ordsrv: OrderService) {this.ordsrv.SetOrder(this.newOrder);
    }

  ngOnInit(): void {

  }

}
