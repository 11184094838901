<mat-card id="assetinfocontainer" *ngIf="loaded">
  <mat-card-header id="sec-header">
    <span class="underline-text-nebula">
      <h5 class="nebula-dark left" class="assettitle">{{selOrderWbs.orderCode}}</h5>
    </span>
    <div class="right" *ngIf="selOrderWbs.wbsPlanned.allwbs.length > 0">
      <button *ngIf="!editing" mat-fab class="menubtn" color="primary" (click)="ZoomChart('perc')" matTooltip="Zoom Grafico percentuale" matTooltipPosition="below">
        <i class="material-icons">percent</i></button>
      <button *ngIf="!editing" mat-fab class="menubtn" color="primary" (click)="ZoomChart('money')" matTooltip="Zoom Grafico economico" matTooltipPosition="below">
        <i class="material-icons">euro</i></button>
      <button *ngIf="!editing" mat-fab class="menubtn" color="primary" (click)="wbsType = 'executed'; fileInput.click()" matTooltip="Upload consuntivo" matTooltipPosition="below">
        <input #fileInput type="file" id="loadFileInput" hidden (change)="UploadTemplate($event)" accept=".xlsx"/>
        <i class="material-icons">file_upload</i></button>
      <button *ngIf="!editing" mat-fab class="menubtn" color="primary" (click)="EditWbs()" matTooltip="Edita WBS" matTooltipPosition="below">
        <i class="material-icons">edit</i></button>
      <button *ngIf="!editing" mat-fab class="menubtn" color="warn" (click)="ResetWbs()" matTooltip="Resetta WBS" matTooltipPosition="below">
        <i class="material-icons">restart_alt</i></button>
      <button *ngIf="!editing" mat-fab class="menubtn" color="primary" (click)="SetWbsEnv('calendar')" matTooltip="Calendario WBS" matTooltipPosition="below">
        <i class="material-icons">event</i></button>
      <button *ngIf="editing" mat-fab class="menubtn" color="primary" (click)="SaveEditing()" matTooltip="Salva modifiche WBS" matTooltipPosition="below">
        <i class="material-icons">save</i></button>
      <button *ngIf="editing" mat-fab class="menubtn" color="primary" (click)="editing = false" matTooltip="Annulla modifiche WBS" matTooltipPosition="below">
        <i class="material-icons">close</i></button>
<!--       <button mat-fab class="menubtn" color="primary" [routerLink]="'/activities/ticket/new/' + selprodId" matTooltip="Registra attività" matTooltipPosition="below">
        <i class="material-icons">add</i></button> -->
    </div>
  </mat-card-header>
  <mat-card-content>
    <div *ngIf="selOrderWbs.wbsPlanned.allwbs.length == 0" id="noentry">
      <h3 >Nessuna WBS pianificata per la commessa selezionata</h3>
      <div id="exlbtns">
        <button mat-fab class="menubtn" style="margin-right: 5px;" color="primary"matTooltip="Download template"  (click)="DownloadTemplate()" matTooltipPosition="below">
          <i class="material-icons">file_download</i></button>
        <button mat-fab class="menubtn" style="margin-left: 5px;" color="primary" matTooltip="Upload template wbs" (click)="wbsType = 'planned'; fileInput.click()"  matTooltipPosition="below">
          <input #fileInput type="file" id="loadFileInput" hidden (change)="UploadTemplate($event)" accept=".xlsx"/>
          <i class="material-icons">file_upload</i>
        </button>
      </div>

    </div>
    <div *ngIf="selOrderWbs.wbsPlanned.allwbs.length > 0" id="entry">
      <div class="row" style="margin-top: 20px;">
        <div id="wbschart">
          <ngx-charts-line-chart
            [view]="view"
            [scheme]="colorScheme"
            [legend]="legend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxis]="xAxis"
            [yAxis]="yAxis"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [timeline]="timeline"
            autoScale = true
            [results]="chartdata"
            [yScaleMin] = yScaleMin
            [yScaleMax] = yScaleMax>
        </ngx-charts-line-chart>
        </div>
        <div id="wbsinfo">
            <form *ngIf="selectedPlannedWbs != null && editing && selectedPlannedWbs.type === 'executed'" style="border:1px solid red; background-color: #ff000038;">
              <div class="col">
                <mat-form-field appearance="outline">
                  <mat-label>Costo</mat-label>
                  <span matPrefix>€ &nbsp;</span>
                  <input matInput type="number" name="cost" [(ngModel)]="selectedPlannedWbs.cost" style="text-align: right;">
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field appearance="outline">
                  <mat-label>Inizio</mat-label>
                  <input matInput name="start" [matDatepicker]="start_picker" [(ngModel)]="selectedPlannedWbs.start" [formControl]="start">
                  <mat-datepicker-toggle matSuffix [for]="start_picker"></mat-datepicker-toggle>
                  <mat-datepicker #start_picker ></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field appearance="outline">
                  <mat-label>Fine</mat-label>
                  <input matInput name="end" [matDatepicker]="end_picker" [(ngModel)]="selectedPlannedWbs.end" [formControl]="end" >
                  <mat-datepicker-toggle matSuffix [for]="end_picker"></mat-datepicker-toggle>
                  <mat-datepicker #end_picker></mat-datepicker>
                </mat-form-field>
              </div>
            </form>
            <form *ngIf="selectedPlannedWbs != null && editing && selectedPlannedWbs.type === 'planned'" style="border:1px solid #505ba1; background-color: #505ba11c;">
              <div class="col">
                <mat-form-field appearance="outline">
                  <mat-label>Costo</mat-label>
                  <span matPrefix>€ &nbsp;</span>
                  <input matInput type="number" name="cost" [(ngModel)]="selectedPlannedWbs.cost" style="text-align: right;">
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field appearance="outline">
                  <mat-label>Inizio</mat-label>
                  <input matInput name="start" [matDatepicker]="start_picker" [(ngModel)]="selectedPlannedWbs.start" [formControl]="start">
                  <mat-datepicker-toggle matSuffix [for]="start_picker"></mat-datepicker-toggle>
                  <mat-datepicker #start_picker ></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field appearance="outline">
                  <mat-label>Fine</mat-label>
                  <input matInput name="end" [matDatepicker]="end_picker" [(ngModel)]="selectedPlannedWbs.end" [formControl]="end" >
                  <mat-datepicker-toggle matSuffix [for]="end_picker"></mat-datepicker-toggle>
                  <mat-datepicker #end_picker></mat-datepicker>
                </mat-form-field>
              </div>
            </form>
            <form *ngIf="selectedPlannedWbs != null && !editing"
              [style]="wbsVisualized === 'executed' ? 'border:1px solid red; background-color: #ff000038;' : 'border:1px solid #505ba1; background-color: #505ba11c;'">
              <div class="col">
                <mat-form-field appearance="outline">
                  <mat-label>Costo</mat-label>
                  <span matPrefix>€ &nbsp;</span>
                  <input matInput type="number" name="cost" [(ngModel)]="selectedPlannedWbs.cost" style="text-align: right; " readonly>
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field appearance="outline">
                  <mat-label>Inizio</mat-label>
                  <input matInput name="start" [ngModel]="startString" readonly type="text">
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field appearance="outline">
                  <mat-label>Fine</mat-label>
                  <input matInput name="end" [ngModel]="endString" readonly type="text" >
                </mat-form-field>
              </div>
            </form>
        </div>
      </div>

      <div class="row" style="height: 100px; align-items: flex-end;">
        <mat-form-field appearance="outline">
          <mat-label>Tipo WBS</mat-label>
          <mat-select name="wbsvis" [(ngModel)]="wbsVisualized" (selectionChange)="SetWbsVisType()">
            <mat-option value="planned">Pianificate</mat-option>
            <mat-option value="executed">Eseguite</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="selectedPlannedWbs !== null" style="width: 70%; ">
          <mat-label>Wbs</mat-label>
          <input matInput type="text" name="name" [(ngModel)]="selectedPlannedWbs.code + ' - ' + selectedPlannedWbs.description" readonly>
        </mat-form-field>
      </div>



      <div id="wbstree" [style]="wbsVisualized === 'executed' ? 'border:1px solid red;' : 'border:1px solid #505ba1;'">
        <app-tree-view-no-check *ngIf="wbsVisualized === 'executed'" [(treeRoot)]="treeWbsVis" [query]="query" (selectedNode)="GetSelectedWbsInfo($event)"></app-tree-view-no-check>
        <app-tree-view-no-check *ngIf="wbsVisualized === 'planned'" [(treeRoot)]="treeWbsVis" [query]="query" (selectedNode)="GetSelectedWbsInfo($event)"></app-tree-view-no-check>
      </div>

    </div>
  </mat-card-content>
</mat-card>
