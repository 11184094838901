import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LastLevelCode } from '../models/syscoder/last-level-codes';
import { SingleCode } from '../models/syscoder/single-code';

@Injectable({
  providedIn: 'root'
})
export class CoderService {

  option!: HttpHeaders;

  constructor(private http: HttpClient) { }

  loadAllCodes(): Observable<LastLevelCode[]>{
    const ApiUrl = environment.mainUrl + 'coder/lastlevel';
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(ApiUrl, {headers: this.option})
      .pipe(
        map(res => {
          console.log(res)
          return res['lastlevel'];
      }),
        catchError(this.errorhandler)
      );
  }

  loadHistoryCodes(): Observable<LastLevelCode[]>{
    const ApiUrl = environment.mainUrl + 'coder/history';
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(ApiUrl, {headers: this.option})
      .pipe(
        map(res => {
          console.log(res)
          return res['codes'];
      }),
        catchError(this.errorhandler)
      );
  }

  saveCode(code: LastLevelCode): any {
    const ApiUrl = environment.mainUrl + 'coder/code';
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + (localStorage.getItem('token')));
    console.log(this.option)
    return this.http.post(ApiUrl, code, { headers: this.option, responseType: 'blob' });
  }

  loadCodesLeveled(): Observable<SingleCode[][]>{
    const ApiUrl = environment.mainUrl + 'coder/allcodes';
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(ApiUrl, {headers: this.option})
      .pipe(
        map(res => {
          console.log(res)
          return res['levels'];
      }),
        catchError(this.errorhandler)
      );
  }

  addNewCode(code: SingleCode): any {
    const ApiUrl = environment.mainUrl + 'coder/newcode';
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + (localStorage.getItem('token')));
    console.log(this.option)
    return this.http.post(ApiUrl, code, { headers: this.option, responseType: 'blob' });
  }

    /*Gestione errori*/
    errorhandler(error: any){
      console.log(error);
      let msg: string;
      if(error instanceof HttpErrorResponse){
        if(error.status === 0){
          msg = 'App offline'
        }
        else{
          msg = `${error.error.message}`
        }
        return throwError(() => {new Error(msg)});
      }
      return throwError(()=> {new Error(`Si è verificato un errore di tipo: ${error.message}`)});
    }
}
