import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-placer-home',
  templateUrl: './placer-home.component.html',
  styleUrls: ['./placer-home.component.scss']
})
export class PlacerHomeComponent implements OnInit {
  selectedPlan: string;
  constructor() { }

  ngOnInit(): void {
    this.selectedPlan = localStorage.getItem('selectedPlan')
  }

  SelProd(plan:string){
    if(plan != "") this.selectedPlan = plan;
  }
}
