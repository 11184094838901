import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appDnd]'
})
export class DndDirective {

  constructor() { }
  @HostBinding('class.fileover')  fileOver: boolean;
  @Output() fileDropped = new EventEmitter<any>()
  files: any[] = [];

  @HostListener('dragover', ['$event']) onDragOver = (evt: DragEvent) => {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = true;
  }

  @HostListener('dragleave', ['$event']) public onDragLeave = (evt: DragEvent) => {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
  }

  @HostListener('drop', ['$event']) public onDrop = async (evt: DragEvent) => {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;

    const { dataTransfer } = evt;
    console.log(Array.from(dataTransfer.items))
    if (dataTransfer.items) {
      const entries = Array.from(dataTransfer.items)
      .filter(item => item.kind === 'file')
      .map(item => item.webkitGetAsEntry());

      this.buildArray(entries).then(() => {
        dataTransfer.items.clear();
        this.fileDropped.emit(this.files);
        this.files = [];
      });
    } else {
      const files = dataTransfer.files;
      dataTransfer.clearData();
      this.fileDropped.emit(Array.from(files));
    }
  }

  private parseFileEntry(fileEntry) {
    return new Promise((resolve, reject) => {
      fileEntry.file(
        file => {
          resolve(new File([file], fileEntry.fullPath.substring(1)));
        },
        err => {
          reject(err);
        }
      );
    });
  }

  private async parseDirectoryEntry(directoryEntry) {
/*     const directoryReader = directoryEntry.createReader();
 */
  return this.buildArray(await this.enumerateDirectoryWithManyFiles(directoryEntry))
    /* return new Promise((resolve, reject) => {
      directoryReader.readEntries(
        entries => {
          console.log(entries.length)
          this.parseDirectoryEntry(directoryEntry);
          resolve(this.buildArray(entries))
        },
        err => {
          reject(err);
        }
      );
    }); */
  }

  private buildArray(entries) {
    const promises = [];

    entries.forEach(entry => {
      if (entry.isFile) {
        const promise = this.parseFileEntry(entry).then(file => {
          this.files.push(file)
        });
        promises.push(promise);
      } else if (entry.isDirectory) {
        const promise = this.parseDirectoryEntry(entry).then(directories => {
          console.log(directories)
        });
        promises.push(promise);
    }});
    return Promise.all(promises).then(() => {});
  }

  private async enumerateDirectoryWithManyFiles(directoryEntry) {
      let reader = directoryEntry.createReader();
      let resultEntries = [];
      var rif = this;
      console.log(resultEntries)
      let read = async function() {
          let entries = await rif.readEntriesAsync(reader) as Array<any>;
          if (entries.length > 0) {
              resultEntries = resultEntries.concat(entries);
              await read();
          }
      }

    await read();
    return resultEntries;
  }

  private readEntriesAsync(reader) {
    return new Promise((resolve, reject) => {
        reader.readEntries(entries => {
            resolve(entries);
        }, error => reject(error));
    })
  }

}
