import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CalendarDateFormatter, CalendarEvent, CalendarEventAction, CalendarView, DAYS_OF_WEEK } from 'angular-calendar';
import { isSameDay, isSameMonth } from 'date-fns';
import { Subject } from 'rxjs';
import { ActivityCalendar } from 'src/app/models/helm/activity-calendar';
import { CustomDateFormatter } from 'src/app/models/shared/custom-date-formatter.provider';
import { WbsInfo } from 'src/app/models/wbs/wbs-info';
import { OrderService } from 'src/app/services/order.service';
import { WbsService } from 'src/app/services/wbs.service';

@Component({
  selector: 'app-calendar-wbs',
  templateUrl: './calendar-wbs.component.html',
  styleUrls: ['./calendar-wbs.component.scss'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter
    }
  ]
})
export class CalendarWbsComponent{

  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  annorif: string = (new Date()).getFullYear().toString();

  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();

  loaded: boolean = false;
  plannedactivities: ActivityCalendar[] = [];
  @Input() selectedOrderId: string;
  @Output() setWbsEnvType = new EventEmitter<string>();
  selOrderWbs: WbsInfo;

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [];

  refresh: Subject<any> = new Subject();

  events: CalendarEvent[] = [];

  activeDayIsOpen: boolean = false;

  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  excludeDays: number[] = [0];

  weekendDays: number[] = [DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY];

  constructor(private wbssrv: WbsService) { }

  ngOnChanges(){
    this.LoadWbsInfo();
  }

  LoadWbsInfo(){
    if(this.selectedOrderId != 'TUTTE LE COMMESSE' && this.selectedOrderId != undefined && this.selectedOrderId != ''){
      this.wbssrv.LoadWbsInfo(this.selectedOrderId)
      .subscribe({next: (res) => {
        this.selOrderWbs = res;
        console.log(this.selOrderWbs);
        this.AddEvents();
        this.loaded = true;
      }, error: (error) => {
        console.log(error);
      }})
    }
  }

  AddEvents(){
    let wbsefirst = this.selOrderWbs.wbsExecuted.allwbs.filter(wb => wb.code.split('.').length == 1);
    for(let wb of wbsefirst){
      this.events.push({
        start: new Date(wb.start),
        end: new Date(wb.end),
        title: `${wb.code} - ${wb.description}`,
        color: {primary: 'red', secondary: '#ff000038'},
        actions: this.actions,
        allDay: true,
        meta: {orderId: wb.wbsId}
      })
    }

    let wbspfirst = this.selOrderWbs.wbsPlanned.allwbs.filter(wb => wb.code.split('.').length == 1);
    for(let wb of wbspfirst){
      this.events.push({
        start: new Date(wb.start),
        end: new Date(wb.end),
        title: `${wb.code} - ${wb.description}`,
        color: {primary: '#505ba1', secondary: '#505ba1cc'},
        actions: this.actions,
        allDay: true,
        meta: {orderId: wb.wbsId}
      })
    }
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    if(action == 'Clicked'){
      console.log(event.meta)

    }
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  SetWbsEnv(type: string){
    this.setWbsEnvType.emit(type);
  }
}
