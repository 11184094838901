<div id="cont" style="height: 100%; width: 100%;">
  <div mat-dialog-title>
    <div id="role-title">
      <span class="underline-text-nebula">
        <h2>{{code}}</h2>
      </span>
      <button mat-fab class="menubtn" color="primary" mat-dialog-close>
        <i class="material-icons">close</i></button>
    </div>
  </div>
  <mat-dialog-content >
    <div id="wbschart">
      <ngx-charts-line-chart
        [view]="view"
        [scheme]="colorScheme"
        [legend]="legend"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxis]="xAxis"
        [yAxis]="yAxis"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        [timeline]="timeline"
        autoScale = true
        [results]="chartdata"
        [yScaleMin] = yScaleMin
        [yScaleMax] = yScaleMax>
    </ngx-charts-line-chart>
    </div>
  </mat-dialog-content>
</div>


