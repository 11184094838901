import { Component, OnInit } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ActivityLight } from 'src/app/models/helm/activity-light';
import { CompanyLight } from 'src/app/models/helm/company-light';
import { Order } from 'src/app/models/helm/order';
import { OrderCost } from 'src/app/models/helm/order-cost';
import { OrderProd } from 'src/app/models/helm/order-prod';
import { AuthUser } from 'src/app/models/shared/auth-user';
import { AutocompObj } from 'src/app/models/shared/autocomp-obj';
import { HelmService } from 'src/app/services/helm.service';
import { OrderService } from 'src/app/services/order.service';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import * as _moment from 'moment';
import { MY_FORMATS } from 'src/app/models/shared/datepickerformat';
import { ProdMemb } from 'src/app/models/helm/prod-memb';
import { MatTableDataSource } from '@angular/material/table';

const moment =  _moment;

@Component({
  selector: 'app-eo-main',
  templateUrl: './eo-main.component.html',
  styleUrls: [
    './eo-main.component.scss',
    '../../../../../app.component.scss'
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
  ]
})
export class EoMainComponent implements OnInit {
  loaded: boolean = false;
  date_offer = new FormControl(moment());
  date_delivery = new FormControl(moment());

  editableOrder?: Order;
  activitySet: ActivityLight[];
  costsSet: OrderCost;

  users: AuthUser[] = [];
  usersForSel: AutocompObj[] = [];
  ownerCtrl = new FormControl();
  filteredusrsowner: Observable<AutocompObj[]>;
  selectedowner: AutocompObj;

  members: ProdMemb[] = [];

  memberCtrl = new FormControl();
  filteredusrsmember: Observable<AutocompObj[]>;
  selectedmember: AutocompObj;

  commercialCtrl = new FormControl();
  filteredcommercial: Observable<AutocompObj[]>;
  selectedcommercial: AutocompObj;

  companies: CompanyLight[]= [];
  companiesForSel: AutocompObj[] = [];
  companiesCtrl = new FormControl();
  filteredcompanies: Observable<AutocompObj[]>;
  selectedcompany: AutocompObj;

  displayedColumns = ['memb', 'permission', 'actions'];
  dataSource = new MatTableDataSource<ProdMemb>();


  constructor(private helmsrv: HelmService, private ordsrv: OrderService, private router: Router, public snackBar: MatSnackBar, private activatedRoute: ActivatedRoute) {
    this.editableOrder = this.ordsrv.GetOrder();
    if(this.editableOrder != undefined){
      this.editableOrder = this.ordsrv.GetOrder();
      this.activitySet = this.editableOrder.activity_planned;
      this.costsSet = this.editableOrder.costs;
      this.LoadSettings();
    }
    else{
      this.ordsrv.LoadSelectedOrder(this.activatedRoute.snapshot.params.id)
        .subscribe(ord => {
          console.log(ord);
          this.ordsrv.SetOrder(ord);
          this.editableOrder = ord;
          this.activitySet = ord.activity_planned;
          this.costsSet = ord.costs;
          this.LoadSettings();
        })
    }
   }

  ngOnInit(): void {

  }

  LoadSettings(){
    this.helmsrv.LoadAllUsersAuth()
      .subscribe((res) => {
        this.users = res
        for(let usr of this.users){
          this.usersForSel.push({id: usr.userId, text: `${usr.surname} ${usr.name}  - ${usr.company.name}`})
          if(this.editableOrder.manager.userId == "" && usr.userId == localStorage.getItem('id'))
            this.selectedowner = {id: usr.userId, text: `${usr.surname} ${usr.name}  - ${usr.company.name}`};
        }
        this.filteredusrsowner = this.ownerCtrl.valueChanges
        .pipe(
          startWith(''),
          map(usr => this._filterUsr(usr))
        );
        this.filteredusrsmember = this.memberCtrl.valueChanges
        .pipe(
          startWith(''),
          map(usr => this._filterUsr(usr))
        );
        this.filteredcommercial = this.commercialCtrl.valueChanges
        .pipe(
          startWith(''),
          map(usr => this._filterUsr(usr))
        );
        this.helmsrv.LoadAllCompaniesLight()
        .subscribe(res => {
          this.companies = res;
          for(let comp of this.companies){
            this.companiesForSel.push({id: comp.companyId, text: comp.name});
          }
          this.filteredcompanies = this.companiesCtrl.valueChanges
          .pipe(
            startWith(''),
            map(comp => this._filterComp(comp))
          );
        })
      });
      if(this.selectedowner == undefined && this.editableOrder.manager.userId != undefined)
        this.selectedowner = {id: this.editableOrder.manager.userId, text: `${this.editableOrder.manager.surname} ${this.editableOrder.manager.name}  - ${this.editableOrder.manager.company.name}`};
      if(this.selectedcommercial == undefined && this.editableOrder.commerciale != undefined&& this.editableOrder.commerciale.userId != undefined)
        this.selectedcommercial = {id: this.editableOrder.commerciale.userId, text: `${this.editableOrder.commerciale.surname} ${this.editableOrder.commerciale.name}  - ${this.editableOrder.commerciale.company.name}`};
      if(this.selectedcompany == undefined && this.editableOrder.client != undefined)
        this.selectedcompany = {id: this.editableOrder.client.companyId, text: this.editableOrder.client.name};
      if(this.members.length == 0 && this.editableOrder.members.length > 0){
        for(let mem of this.editableOrder.members){
          this.members.push({member: {id: mem.user.userId, text: `${mem.user.surname} ${mem.user.name}  - ${mem.user.company.name}`}, privilege: mem.user.privilege, permission: mem.permission})
        }
        this.dataSource.data = this.members;
      }
      let cst = this.costsSet;
      this.editableOrder.cost_tot = 0 + cst.proj_manag_cost + cst.ext_supp_cost + cst.var_expenses + cst.days_cost + cst.rapp_expenses + cst.hw_sw_cost + cst.gest_clnt_cost;
      this.loaded = true;
  }


  navigate(form: NgForm, area: string){
    let orderId = this.editableOrder.orderId;
    let doff = this.date_offer.value;
    let ddel = this.date_delivery.value;
    this.editableOrder = {...form.value};
    this.editableOrder.orderId = orderId;
    this.editableOrder.date_offer = doff;
    this.editableOrder.date_delivery = ddel;
    if(this.selectedowner != undefined && typeof(this.selectedowner) == 'object')
      this.editableOrder.manager = this.users.filter(usr => usr.userId == this.selectedowner.id)[0];
    if(this.selectedcommercial != undefined && typeof(this.selectedcommercial) == 'object')
      this.editableOrder.commerciale = this.users.filter(usr => usr.userId == this.selectedcommercial.id)[0];
    if(this.selectedcompany != undefined && typeof(this.selectedcompany) == 'object')
      this.editableOrder.client = this.companies.filter(comp => comp.companyId == this.selectedcompany.id)[0];
    this.editableOrder.members = [];
    for(let memb of this.members){
      this.editableOrder.members.push({user: this.users.filter(usr => usr.userId == memb.member.id)[0], permission: memb.permission});
    }
    this.editableOrder.activity_planned = this.activitySet;
    this.editableOrder.costs = this.costsSet;
    this.ordsrv.SetOrder(this.editableOrder);
    this.router.navigateByUrl(`/helm/orders/edit/${area}/${this.editableOrder.orderId}`);
  }

  AddeditableOrder(form: NgForm){
    if(typeof(this.selectedowner) != 'object'){
      this.snackBar.open("Nessun proprietario selezionato", "OK", {
        duration: 5000,
        horizontalPosition: "center",
        verticalPosition: "bottom"
      })
    }
    else{
      let id = this.editableOrder.orderId;
      let doff = this.date_offer.value;
      let ddel = this.date_delivery.value;
      this.editableOrder = {...form.value};
      this.editableOrder.orderId = id;
      this.editableOrder.date_offer = doff;
      this.editableOrder.date_delivery = ddel;
      if(this.selectedowner != undefined && typeof(this.selectedowner) == 'object')
        this.editableOrder.manager = this.users.filter(usr => usr.userId == this.selectedowner.id)[0];
        if(this.selectedcommercial != undefined && typeof(this.selectedcommercial) == 'object')
        this.editableOrder.commerciale = this.users.filter(usr => usr.userId == this.selectedcommercial.id)[0];
      if(this.selectedcompany != undefined && typeof(this.selectedcompany) == 'object')
        this.editableOrder.client = this.companies.filter(comp => comp.companyId == this.selectedcompany.id)[0];
      this.editableOrder.members = [];
      for(let memb of this.members){
        this.editableOrder.members.push({user: this.users.filter(usr => usr.userId == memb.member.id)[0], permission: memb.permission});
      }
      this.editableOrder.activity_planned = this.activitySet;
      this.editableOrder.costs = this.costsSet;
      this.ordsrv.SetOrder(this.editableOrder);

      this.ordsrv.UpdateOrder()
        .subscribe(res => {
          console.log(res);
          this.router.navigateByUrl(`/helm/orders`);
        })
    }
  }

  addMember(){
    if(typeof(this.selectedmember) === 'object'){
      let usr = this.users.filter(u => u.userId == this.selectedmember.id);
      let newmemb = {member: this.selectedmember, privilege: usr[0].privilege, permission: false}
      if(this.members.filter(mem => mem.member.id === newmemb.member.id).length === 0 && this.selectedmember.id != this.selectedowner.id){
        this.members.push(newmemb);
        this.dataSource.data = this.members;
      }
      this.memberCtrl.setValue('');
    }
    else this.memberCtrl.setValue('');
  }

  remMemb(usr: ProdMemb){
    let delusr = this.members.filter(mem => mem.member.id == usr.member.id)[0];
    let index = this.members.indexOf(delusr)
    this.members.splice(index, 1);
    this.dataSource.data = this.members;
  }


  private _filterUsr(value: string): AutocompObj[] {
    if(typeof(value) === 'string'){
      const filterValue = value?.toLowerCase() ?? '';
      return this.usersForSel.filter(usr => usr.text.toLowerCase().indexOf(filterValue) > -1);
    }
  }

  private _filterComp(value: string): AutocompObj[] {
    if(typeof(value) === 'string'){
      const filterValue = value?.toLowerCase() ?? '';
      return this.companiesForSel.filter(comp => comp.text.toLowerCase().indexOf(filterValue) > -1);
    }
  }

  public getDisplayFn() {
    return (val) => this.display(val);
  }

  private display(user): string {
    //access component "this" here
    return user ? user.text : user;
  }
}
