<div class="flex-container">
  <mat-card id="tree-card">
    <mat-card-title id="tree-card-title">
      <span class="underline-text-nebula">
        <h5 class="nebula-dark left" class="assettitle">{{selectedPlan}}</h5>
      </span>
    </mat-card-title>
    <mat-card-content *ngIf="loaded">
      <form #fm="ngForm">
        <div id="seatsel">
          <mat-form-field id="role" class="row">
            <mat-label>Seleziona postazione</mat-label>
            <input matInput type="text" name="seat" [(ngModel)]="selectedseat" [matAutocomplete]="autoMem" [formControl]="seatCtrl" >
            <mat-autocomplete  #autoMem="matAutocomplete" [displayWith]="getDisplayFn()">
              <mat-option *ngFor="let seat of filteredseats | async" [value]="seat">{{seat.text}}</mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <button mat-fab class="menubtn" color="primary" matTooltip="Visualizza info postazione" matTooltipPosition="below" (click)="LoadSeat()">
            <i class="material-icons">visibility</i></button>
        </div>
        <div id="seatinfo" *ngIf="selseat !== null && selseat !== undefined && selseat.code !== ''">
          <mat-form-field class="row">
            <mat-label>Postazione</mat-label>
            <input matInput type="text" name="code" [ngModel]="selseat.code" readonly>
          </mat-form-field>
          <mat-form-field class="row">
            <mat-label>Codice Stanza</mat-label>
            <input matInput type="text" name="room" [ngModel]="selseat.room" readonly>
          </mat-form-field>
          <mat-form-field class="row">
            <mat-label>Area</mat-label>
            <input matInput type="text" name="areacategory" [ngModel]="selseat.areacategory" readonly>
          </mat-form-field>
          <mat-form-field class="row">
            <mat-label>Stanza</mat-label>
            <input matInput type="text" name="roomcategory" [ngModel]="selseat.roomcategory" readonly>
          </mat-form-field>
          <mat-form-field class="row">
            <mat-label>Categoria</mat-label>
            <mat-select name="seatcategory" [(ngModel)]="selseat.seatcategory" required>
              <mat-option *ngFor="let cat of selseat.avaiable" [value]="cat">{{cat}}</mat-option>
            </mat-select>
          </mat-form-field>
          <button mat-fab class="menubtn" color="primary" matTooltip="Salva" matTooltipPosition="below" (click)="SaveSeat(fm)" [disabled]="fm.invalid" type="submit">
            <i class="material-icons">save</i></button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
  <mat-card id="viewer-container">
    <mat-card-content>
      <ngx-extended-pdf-viewer *ngIf="blob" [src]="blob" [useBrowserLocale]="true" [zoom]="zoom" [customToolbar]="additionalButtons" [sidebarVisible]="false"
        [textLayer]="true" [showHandToolButton]="true" height="95%" width="100%"></ngx-extended-pdf-viewer>
    </mat-card-content>
  </mat-card>
</div>

<ng-template #additionalButtons>
  <div id="toolbarViewer">
    <div id="toolbarViewerLeft">
      <pdf-find-button [showFindButton]="true" [textLayer]="true"></pdf-find-button>
    </div>
    <pdf-zoom-toolbar ></pdf-zoom-toolbar> <!-- toolbar viewer middle -->
    <div id="toolbarViewerRight"></div>
  </div>
</ng-template>
