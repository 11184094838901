import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { WbsInfo } from '../models/wbs/wbs-info';
import { WbsPlanned } from '../models/wbs/wbs-planned';

@Injectable({
  providedIn: 'root'
})
export class WbsService {

  option!: HttpHeaders;

  constructor(private http: HttpClient) { }

  LoadWbsInfo(orderId: string): Observable<WbsInfo>{
    const ApiUrl = environment.mainUrl + 'wbsinfo';
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(ApiUrl, {headers: this.option, params: {orderId: orderId}})
      .pipe(
        map(res => {
          return res as WbsInfo;
      }),
        catchError(this.errorhandler)
      );
  }

  UploadWbsTemplate(formData:FormData, orderId: string, wbstype: string): Observable<string[]>{
    const AppUrl = environment.mainUrl + 'uploadwbs';
    this.option = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post(AppUrl, formData, {headers:this.option, params: {orderId: orderId, wbstype: wbstype}})
      .pipe(
        map((res:any) => {
          return res['message'];
        }),
        catchError(this.errorhandler)
      )
  }

  EditWbs(wbs:WbsPlanned): Observable<string[]>{
    const AppUrl = environment.mainUrl + 'editwbs';
    this.option = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.patch(AppUrl, wbs, {headers:this.option})
      .pipe(
        map((res:any) => {
          return res['message'];
        }),
        catchError(this.errorhandler)
      )
  }

  DeleteAllWbs(orderId:string){
    const AppUrl = environment.mainUrl + 'deleteallwbs';
    this.option = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.delete(AppUrl, {headers:this.option, params: {orderId: orderId}})
      .pipe(
        map((res:any) => {
          return res['message'];
        }),
        catchError(this.errorhandler)
      )
  }

    /*Gestione errori*/
    errorhandler(error: any){
      console.log(error);
      let msg: string;
      if(error instanceof HttpErrorResponse){
        if(error.status === 0){
          msg = 'App offline'
        }
        else{
          msg = `${error.error.message}`
        }
        return throwError(() => {new Error(msg)});
      }
      return throwError(()=> {new Error(`Si è verificato un errore di tipo: ${error.message}`)});
    }
}
