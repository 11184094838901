import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators'
import { environment } from 'src/environments/environment';
import { CloudItem } from '../models/cde/cloud-item';
import { Company } from '../models/helm/company';
import { CompanyLight } from '../models/helm/company-light';
import { ProdMemb } from '../models/helm/prod-memb';
import { ProdlinesLight } from '../models/helm/prodlines-light';
import { InfoLight } from '../models/helm/ResSrv/info-light';
import { SrvConfirm } from '../models/helm/ResSrv/srv-confirm';
import { User } from '../models/helm/user';
import { AuthUser } from '../models/shared/auth-user';
import { ProdLight } from '../models/shared/prod-light';
import { Prodline } from '../models/shared/prodline';
import { Product } from '../models/shared/product';

@Injectable({
  providedIn: 'root'
})
export class HelmService {

  option!: HttpHeaders;

  constructor(private http: HttpClient) { }

  //#region Home
  LoadInfoLight(): Observable<InfoLight>{
    var homeInfoLight: InfoLight = {intuser: [], extuser:[], products:[], productline:[], orders:[], companies:[]};
    const ApiUrl = environment.mainUrl + 'reponew';
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get<InfoLight>(ApiUrl, {headers: this.option})
      .pipe(
        map(res => {
          homeInfoLight.intuser = res['uint'];
          homeInfoLight.extuser = res['uext'];
          homeInfoLight.products = res['products'];
          homeInfoLight.productline = res['productline'];
          homeInfoLight.orders = res['orders'];
          homeInfoLight.companies = res['companies'];
          return homeInfoLight;
      }),
        catchError(this.errorhandler)
      );
  }
  //#endregion

  //#region Utenti
  LoadAllUsers(tipo:string) : Observable<User[]>{
    const ApiUrl = environment.mainUrl + 'users/all/' + tipo;
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(ApiUrl, {headers: this.option})
      .pipe(
        map(res => {
          return res['users'];
      }),
        catchError(this.errorhandler)
      );
  }

  LoadAllUsersAuth() : Observable<AuthUser[]>{
    const ApiUrl = environment.mainUrl + 'users/authuser/all';
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(ApiUrl, {headers: this.option})
      .pipe(
        map(res => {
          return res['users'];
      }),
        catchError(this.errorhandler)
      );
  }

  AddNewUser(user: User) : Observable<string>{
    const ApiUrl = environment.mainUrl + 'appuser/';
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post(ApiUrl, user, {headers: this.option}).pipe(
      map(res => {
        return res['message'];
      }),
        catchError(this.errorhandler)
      );
  }



  LoadSelectedUser(userId: string) : Observable<User>{
    const ApiUrl = environment.mainUrl + 'appuser/' + userId;
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(ApiUrl, {headers: this.option}).pipe(
      map(res => {
        return res['user'];
      }),
        catchError(this.errorhandler)
      );
  }

  EditSelectedUser(user: User) : Observable<string>{
    const ApiUrl = environment.mainUrl + 'user/';
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post(ApiUrl, user, {headers: this.option}).pipe(
      map(res => {
        return res['message'];
      }),
        catchError(this.errorhandler)
      );
  }

  DeleteUser(userId: string) : Observable<User[]>{
    const ApiUrl = environment.mainUrl + 'user/' + userId;
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.delete(ApiUrl, {headers: this.option})
      .pipe(
        map(res => {
          return res['message'];
      }),
        catchError(this.errorhandler)
      );
  }
  //#endregion

  //#region Aziende
  LoadAllCompanies() : Observable<Company[]>{
    const ApiUrl = environment.mainUrl + 'companies/';
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(ApiUrl, {headers: this.option})
      .pipe(
        map(res => {
          return res['companies'];
      }),
        catchError(this.errorhandler)
      );
  }

  LoadAllCompaniesLight() : Observable<CompanyLight[]>{
    const ApiUrl = environment.mainUrl + 'companieslight/';
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(ApiUrl, {headers: this.option})
      .pipe(
        map(res => {
          return res['companies'];
      }),
        catchError(this.errorhandler)
      );
  }

  AddNewCompany(company: Company) : Observable<string>{
    const ApiUrl = environment.mainUrl + 'company/';
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post(ApiUrl, company, {headers: this.option}).pipe(
      map(res => {
        return res['message'];
      }),
        catchError(this.errorhandler)
      );
  }

  LoadSelectedCompany(companyId: string) : Observable<Company>{
    const ApiUrl = environment.mainUrl + 'company/' + companyId;
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(ApiUrl, {headers: this.option}).pipe(
      map(res => {
        return res['company'];
      }),
        catchError(this.errorhandler)
      );
  }

  EditSelectedCompany(company: Company) : Observable<string>{
    const ApiUrl = environment.mainUrl + 'company/';
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.patch(ApiUrl, company, {headers: this.option}).pipe(
      map(res => {
        return res['message'];
      }),
        catchError(this.errorhandler)
      );
  }

  DeleteCompany(companyId: string) : Observable<Company[]>{
    const ApiUrl = environment.mainUrl + 'company/' + companyId;
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.delete(ApiUrl, {headers: this.option})
      .pipe(
        map(res => {
          return res['message'];
      }),
        catchError(this.errorhandler)
      );
  }
  //#endregion

  CheckCode(code: string, type:string) : Observable<boolean>{
    const ApiUrl = environment.mainUrl + 'checkcode/' + code + '/' + type;
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(ApiUrl, {headers: this.option}).pipe(
      map(res => {
        return res['esiste'];
      }),
        catchError(this.errorhandler)
      );
  }

  CheckOrderProd(id: string): Observable<SrvConfirm>{
    const ApiUrl = environment.mainUrl + 'checkprod/' + id ;
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(ApiUrl, {headers: this.option}).pipe(
      map(res => {
        return res as SrvConfirm;
      }),
        catchError(this.errorhandler)
      );
  }

  GetLinks(prod_id: string, prod_type: string): Observable<CloudItem[]>{
    const ApiUrl = `${environment.mainUrl}sharelinks/${prod_type}/${prod_id}`;
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(ApiUrl, {headers: this.option}).pipe(
      map(res => {
        return res['links'];
      }),
        catchError(this.errorhandler)
      );
  }

  //#region Products
  AddNewProduct(prod: Product) : Observable<string>{
    const ApiUrl = environment.mainUrl + 'product';
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post(ApiUrl, prod, {headers: this.option}).pipe(
      map(res => {
        return res['message'];
      }),
        catchError(this.errorhandler)
      );
  }

  LoadAllProducts() : Observable<Product[]>{
    const ApiUrl = environment.mainUrl + 'product/';
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(ApiUrl, {headers: this.option})
      .pipe(
        map(res => {
          return res['products'];
      }),
        catchError(this.errorhandler)
      );
  }

  LoadAllProductsLight(): Observable<ProdLight[]>{
    const ApiUrl = environment.mainUrl + 'productlight/';
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(ApiUrl, {headers: this.option})
      .pipe(
        map(res => {
          return res['products'];
      }),
        catchError(this.errorhandler)
      );
  }

  LoadSelectedProduct(productId: string) : Observable<Product>{
    const ApiUrl = environment.mainUrl + 'product/' + productId;
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(ApiUrl, {headers: this.option}).pipe(
      map(res => {
        return res['product'];
      }),
        catchError(this.errorhandler)
      );
  }

  EditSelectedProduct(product: Product) : Observable<string>{
    const ApiUrl = environment.mainUrl + 'product/';
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.patch(ApiUrl, product, {headers: this.option}).pipe(
      map(res => {
        return res['message'];
      }),
        catchError(this.errorhandler)
      );
  }

  DeleteProduct(productId: string) : Observable<string>{
    const ApiUrl = environment.mainUrl + 'product/' + productId;
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.delete(ApiUrl, {headers: this.option})
      .pipe(
        map(res => {
          localStorage.setItem('selectedProd', 'TUTTI I PRODOTTI');
          return res['message'];
      }),
        catchError(this.errorhandler)
      );
  }
  //#endregion

   //#region Productlines
   AddNewProductline(productline: Prodline) : Observable<string>{
    const ApiUrl = environment.mainUrl + 'productline';
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post(ApiUrl, productline, {headers: this.option}).pipe(
      map(res => {
        return res['message'];
      }),
        catchError(this.errorhandler)
      );
  }

  GetProdMembers(productId: string) : Observable<ProdMemb[]>{
    const ApiUrl = environment.mainUrl + 'userprod/' + productId;
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(ApiUrl, {headers: this.option})
      .pipe(
        map(res => {
          let members : ProdMemb[] = [];
          for(let memb of res['members']){
            members.push({member: {id: memb.user.userId, text: memb.user.surname + memb.user.name}, privilege: memb.user.privilege, permission: memb.permission, literalpermission: memb.permission ? 'Lettura/Scrittura' : 'Lettura'});
          }
          return members;
      }),
        catchError(this.errorhandler)
      );
  }

  LoadAllProductlines() : Observable<Prodline[]>{
    const ApiUrl = environment.mainUrl + 'productlines/';
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(ApiUrl, {headers: this.option})
      .pipe(
        map(res => {
          return res['productlines'];
      }),
        catchError(this.errorhandler)
      );
  }

  LoadAllProductlinesLight(): Observable<ProdlinesLight[]>{
    const ApiUrl = environment.mainUrl + 'productlineslight/';
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(ApiUrl, {headers: this.option})
      .pipe(
        map(res => {
          return res['productlines'];
      }),
        catchError(this.errorhandler)
      );
  }

  LoadSelectedProductline(productlineId: string) : Observable<Prodline>{
    const ApiUrl = environment.mainUrl + 'productline/' + productlineId;
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(ApiUrl, {headers: this.option}).pipe(
      map(res => {
        return res['prodline'];
      }),
        catchError(this.errorhandler)
      );
  }

  EditSelectedProductline(productline: Prodline) : Observable<string>{
    const ApiUrl = environment.mainUrl + 'productline/';
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.patch(ApiUrl, productline, {headers: this.option}).pipe(
      map(res => {
        return res['message'];
      }),
        catchError(this.errorhandler)
      );
  }

  DeleteProductline(productlineId: string) : Observable<string>{
    const ApiUrl = environment.mainUrl + 'productline/' + productlineId;
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.delete(ApiUrl, {headers: this.option})
      .pipe(
        map(res => {
          localStorage.setItem('selectedProdline', 'TUTTE LE LINEE');
          return res['message'];
      }),
        catchError(this.errorhandler)
      );
  }
  //#endregion


   /*Gestione errori*/
   errorhandler(error: any){
    console.log(error);
    let msg: string;
    if(error instanceof HttpErrorResponse){
      if(error.status === 0){
        msg = 'App offline'
      }
      else{
        msg = `${error.error.message}`
      }
      return throwError(() => {new Error(msg)});
    }
    return throwError(()=> {new Error(`Si è verificato un errore di tipo: ${error.message}`)});
  }
}
