import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-wbs',
  templateUrl: './wbs.component.html',
  styleUrls: ['./wbs.component.scss']
})
export class WbsComponent implements OnInit {

  nebulaImg: string = environment.imageUrl + 'nebula.png';
  selectedOrderId: string;
  wbsEnviromentType: string = 'info';

  constructor() { }

  ngOnInit(): void {
    this.selectedOrderId = localStorage.getItem('selectedOrder');
  }

  SelOrder(orderId:string){
    this.selectedOrderId = orderId;
  }

  SelEnviroment(type:string){
    this.wbsEnviromentType = type;
  }
}
