import { Component, OnInit, Input} from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ActivityLight } from 'src/app/models/helm/activity-light';
import { CompanyLight } from 'src/app/models/helm/company-light';
import { Order } from 'src/app/models/helm/order';
import { OrderCost } from 'src/app/models/helm/order-cost';
import { ProdMemb } from 'src/app/models/helm/prod-memb';
import { AuthUser } from 'src/app/models/shared/auth-user';
import { AutocompObj } from 'src/app/models/shared/autocomp-obj';
import { HelmService } from 'src/app/services/helm.service';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-no-main',
  templateUrl: './no-main.component.html',
  styleUrls: [
    './no-main.component.scss',
    '../../../../../app.component.scss'
  ]
})
export class NoMainComponent implements OnInit {
  esiste: boolean = false;
  newOrder: Order;
  activitySet: ActivityLight[];
  costsSet: OrderCost;

  users: AuthUser[] = [];
  usersForSel: AutocompObj[] = [];
  ownerCtrl = new FormControl();
  filteredusrsowner: Observable<AutocompObj[]>;
  selectedowner: AutocompObj;

  members: ProdMemb[] = [];

  memberCtrl = new FormControl();
  filteredusrsmember: Observable<AutocompObj[]>;
  selectedmember: AutocompObj;

  commercialCtrl = new FormControl();
  filteredcommercial: Observable<AutocompObj[]>;
  selectedcommercial: AutocompObj;

  displayedColumns = ['memb', 'permission', 'actions'];
  dataSource = new MatTableDataSource<ProdMemb>();

  companies: CompanyLight[]= [];
  companiesForSel: AutocompObj[] = [];
  companiesCtrl = new FormControl();
  filteredcompanies: Observable<AutocompObj[]>;
  selectedcompany: AutocompObj;

  constructor(private helmsrv: HelmService, private ordsrv: OrderService, private router: Router, public snackBar: MatSnackBar) {
    this.newOrder = this.ordsrv.GetOrder();
   }

  ngOnInit(): void {
    this.activitySet = this.newOrder.activity_planned;
    this.costsSet = this.newOrder.costs;
    this.LoadSettings();
  }

  LoadSettings(){
    this.helmsrv.LoadAllUsersAuth()
      .subscribe((res) => {
        this.users = res
        for(let usr of this.users){
          this.usersForSel.push({id: usr.userId, text: `${usr.surname} ${usr.name}  - ${usr.company.name}`})
          if(this.newOrder.manager.userId == "" && usr.userId == localStorage.getItem('id'))
            this.selectedowner = {id: usr.userId, text: `${usr.surname} ${usr.name}  - ${usr.company.name}`};
        }
        this.filteredusrsowner = this.ownerCtrl.valueChanges
        .pipe(
          startWith(''),
          map(usr => this._filterUsr(usr))
        );
        this.filteredusrsmember = this.memberCtrl.valueChanges
        .pipe(
          startWith(''),
          map(usr => this._filterUsr(usr))
        );
        this.filteredcommercial = this.commercialCtrl.valueChanges
        .pipe(
          startWith(''),
          map(usr => this._filterUsr(usr))
        );
        this.helmsrv.LoadAllCompaniesLight()
        .subscribe(res => {
          this.companies = res;
          for(let comp of this.companies){
            this.companiesForSel.push({id: comp.companyId, text: comp.name});
          }
          this.filteredcompanies = this.companiesCtrl.valueChanges
          .pipe(
            startWith(''),
            map(comp => this._filterComp(comp))
          );
        })
      });
      if(this.selectedowner == undefined && this.newOrder.manager.userId != undefined)
        this.selectedowner = {id: this.newOrder.manager.userId, text: `${this.newOrder.manager.surname} ${this.newOrder.manager.name}  - ${this.newOrder.manager.company.name}`};
      if(this.selectedcommercial == undefined && this.newOrder.commerciale != undefined&& this.newOrder.commerciale.userId != undefined)
        this.selectedcommercial = {id: this.newOrder.commerciale.userId, text: `${this.newOrder.commerciale.surname} ${this.newOrder.commerciale.name}  - ${this.newOrder.commerciale.company.name}`};
      if(this.selectedcompany == undefined && this.newOrder.client != undefined)
        this.selectedcompany = {id: this.newOrder.client.companyId, text: this.newOrder.client.name};
      if(this.members.length == 0 && this.newOrder.members.length > 0){
        for(let mem of this.newOrder.members){
          this.members.push({member: {id: mem.user.userId, text: `${mem.user.surname} ${mem.user.name}  - ${mem.user.company.name}`}, privilege: mem.user.privilege, permission: mem.permission})
        }
        this.dataSource.data = this.members;
      }
      let cst = this.costsSet;
      this.newOrder.cost_tot = 0 + cst.proj_manag_cost + cst.ext_supp_cost + cst.var_expenses + cst.days_cost + cst.rapp_expenses + cst.hw_sw_cost + cst.gest_clnt_cost;
  }


  navigate(form: NgForm, area: string){
    this.newOrder = {...form.value};
    if(this.selectedowner != undefined && typeof(this.selectedowner) == 'object')
      this.newOrder.manager = this.users.filter(usr => usr.userId == this.selectedowner.id)[0];
    if(this.selectedcommercial != undefined && typeof(this.selectedcommercial) == 'object')
      this.newOrder.commerciale = this.users.filter(usr => usr.userId == this.selectedcommercial.id)[0];
    if(this.selectedcompany != undefined && typeof(this.selectedcompany) == 'object')
      this.newOrder.client = this.companies.filter(comp => comp.companyId == this.selectedcompany.id)[0];
    this.newOrder.members = [];
    for(let memb of this.members){
      this.newOrder.members.push({user: this.users.filter(usr => usr.userId == memb.member.id)[0], permission: memb.permission});
    }
    this.newOrder.activity_planned = this.activitySet;
    this.newOrder.costs = this.costsSet;
    this.ordsrv.SetOrder(this.newOrder);
    this.router.navigateByUrl('/helm/orders/new/' + area);
  }

  AddNewOrder(form: NgForm){
    if(typeof(this.selectedowner) != 'object'){
      this.snackBar.open("Nessun proprietario selezionato", "OK", {
        duration: 5000,
        horizontalPosition: "center",
        verticalPosition: "bottom"
      })
    }
    else if(this.esiste){
      this.snackBar.open("Il codice inserito appartiene già ad un altra commessa", "OK", {
        duration: 5000,
        horizontalPosition: "center",
        verticalPosition: "bottom"
      })
    }
    else{
      this.newOrder = {...form.value};
      if(this.selectedowner != undefined && typeof(this.selectedowner) == 'object')
        this.newOrder.manager = this.users.filter(usr => usr.userId == this.selectedowner.id)[0];
      if(this.selectedcommercial != undefined && typeof(this.selectedcommercial) == 'object')
        this.newOrder.commerciale = this.users.filter(usr => usr.userId == this.selectedcommercial.id)[0];
      if(this.selectedcompany != undefined && typeof(this.selectedcompany) == 'object')
        this.newOrder.client = this.companies.filter(comp => comp.companyId == this.selectedcompany.id)[0];
      this.newOrder.members = [];
      for(let memb of this.members){
        this.newOrder.members.push({user: this.users.filter(usr => usr.userId == memb.member.id)[0], permission: memb.permission});
      }
      this.newOrder.activity_planned = this.activitySet;
      this.newOrder.costs = this.costsSet;
      this.ordsrv.SetOrder(this.newOrder);

      this.ordsrv.SaveOrder()
        .subscribe(res => {
          this.router.navigateByUrl('/helm/orders');
        })
    }
  }

  CheckCode(form: NgForm){
    this.newOrder = {...form.value};
    this.helmsrv.CheckCode(this.newOrder.code, 'o')
      .subscribe(res => {
        this.esiste = res;
      })
  }

  addMember(){
    if(typeof(this.selectedmember) === 'object'){
      let usr = this.users.filter(u => u.userId == this.selectedmember.id);
      let newmemb = {member: this.selectedmember, privilege: usr[0].privilege, permission: false}
      if(this.members.filter(mem => mem.member.id === newmemb.member.id).length === 0 && this.selectedmember.id != this.selectedowner.id){
        this.members.push(newmemb);
        this.dataSource.data = this.members;
      }
      this.memberCtrl.setValue('');
    }
    else this.memberCtrl.setValue('');
  }

  remMemb(usr: ProdMemb){
    let delusr = this.members.filter(mem => mem.member.id == usr.member.id)[0];
    let index = this.members.indexOf(delusr)
    this.members.splice(index, 1);
    this.dataSource.data = this.members;
  }

  private _filterUsr(value: string): AutocompObj[] {
    if(typeof(value) === 'string'){
      const filterValue = value?.toLowerCase() ?? '';
      return this.usersForSel.filter(usr => usr.text.toLowerCase().indexOf(filterValue) > -1);
    }
  }

  private _filterComp(value: string): AutocompObj[] {
    if(typeof(value) === 'string'){
      const filterValue = value?.toLowerCase() ?? '';
      return this.companiesForSel.filter(comp => comp.text.toLowerCase().indexOf(filterValue) > -1);
    }
  }

  public getDisplayFn() {
    return (val) => this.display(val);
  }

  private display(user): string {
    //access component "this" here
    return user ? user.text : user;
  }

}
