import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CostSet } from 'src/app/models/helm/cost-set';
import { Order } from 'src/app/models/helm/order';
import { OrderCost } from 'src/app/models/helm/order-cost';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-no-cost',
  templateUrl: './no-cost.component.html',
  styleUrls: [
    './no-cost.component.scss',
    '../../../../../app.component.scss'
  ]
})
export class NoCostComponent implements OnInit {

  newOrder: Order;
  newCost: OrderCost;
  allcostset: CostSet[];


  constructor(private ordsrv: OrderService, private router: Router, public dialog: MatDialog) {
    this.newOrder = this.ordsrv.GetOrder();
  }

  ngOnInit(): void {
    this.newCost = this.newOrder.costs;
    this.SetDaysPlannned();
    if(this.newCost.ext_supp_cost == 0) this.newCost.ext_supp_cost = undefined;
    if(this.newCost.gest_clnt_cost == 0) this.newCost.gest_clnt_cost = undefined;
    if(this.newCost.hw_sw_cost == 0) this.newCost.hw_sw_cost = undefined;
    if(this.newCost.days_cost == 0) this.newCost.days_cost = undefined;
    if(this.newCost.proj_manag_cost == 0) this.newCost.proj_manag_cost = undefined;
    if(this.newCost.rapp_expenses == 0) this.newCost.rapp_expenses = undefined;
    if(this.newCost.var_expenses == 0) this.newCost.var_expenses = undefined;
  }

  SetDaysPlannned(){
    this.newCost.days_assigned = 0;
    //this.newCost.costs_planned = 0;
    for(let act of this.newOrder.activity_planned){
      if(act.durata != undefined) this.newCost.days_assigned = this.newCost.days_assigned + parseFloat((act.durata/8).toFixed(1));
      //if(act.cost != undefined) this.newCost.costs_planned = this.newCost.costs_planned + act.cost;
    }
  }

  navigate(form: NgForm, area: string){
    this.newOrder.costs = {
      date_acquisition: this.newCost.date_acquisition,
      days_assigned: this.newCost.days_assigned,
      days_done: this.newCost.days_done ? this.newCost.days_done : 0,
      days_cost: this.newCost.days_cost ? this.newCost.days_cost : 0,
      days_planned: this.newCost.days_planned,
      ext_supp_cost: this.newCost.ext_supp_cost ? this.newCost.ext_supp_cost : 0,
      gest_clnt_cost: this.newCost.gest_clnt_cost ? this.newCost.gest_clnt_cost : 0,
      hw_sw_cost: this.newCost.hw_sw_cost ? this.newCost.hw_sw_cost : 0,
      price_offered: this.newCost.price_offered,
      proj_manag_cost: this.newCost.proj_manag_cost ? this.newCost.proj_manag_cost : 0,
      rapp_expenses: this.newCost.rapp_expenses ? this.newCost.rapp_expenses : 0,
      var_expenses: this.newCost.var_expenses ? this.newCost.var_expenses : 0
    } ;
    // this.newOrder = {...form.value};
    this.ordsrv.SetOrder(this.newOrder);
    this.router.navigateByUrl('/helm/orders/new/'+ area);
  }
}
