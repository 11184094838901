<form #fm="ngForm" *ngIf="loaded">
  <mat-card class="helmcard">
    <mat-card-header>
      <span class="underline-text-nebula">
        <h5 class="mdc-text left" class="assettitle">Anagrafica Commessa</h5>
      </span>
      <div class="right">
        <button mat-fab class="menubtn" color="primary"[routerLink]="'/helm/orders/view/cost/' + viewOrder.orderId" matTooltip="Tempi e Costi" matTooltipPosition="below" type="button">
          <i class="material-icons">euro</i></button>
        <button mat-fab class="menubtn" color="primary"*ngIf="viewOrder.activity_planned.length > 0" [routerLink]="'/helm/orders/view/activity/' + viewOrder.orderId" matTooltip="Attività" matTooltipPosition="below" type="submit">
          <i class="material-icons">event</i></button>
        <button mat-fab class="menubtn" color="primary" matTooltip="Indietro" routerLink="/helm/orders" matTooltipPosition="below">
          <i class="material-icons">close</i></button>
      </div>
    </mat-card-header>
    <mat-card-content>
      <div class="internalMatGrid">
        <mat-grid-list [cols]=10  rowHeight="100px">
          <mat-grid-tile [colspan]=2>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Codice</mat-label>
                <input matInput type="text" name="code" [(ngModel)]="viewOrder.code" readonly>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=2>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Nome</mat-label>
                <input matInput type="text" name="name" [(ngModel)]="viewOrder.name" readonly>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=3>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Proprietario</mat-label>
                <input matInput type="text" name="owner" [(ngModel)]="owner" readonly>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=3 [rowspan]=5>
            <div class="col-int">
              <div *ngIf="viewOrder.members.length > 0" [ngfo]="{standalone: true}">
                <div class="col-int">
                  <span class="underline-text-nebula2 left" style="align-self: flex-start;">
                    <h2 class="mdc-text ">Membri</h2>
                  </span>
                  <div id="member-table">
                    <table mat-table [dataSource]="dataSource">
                      <ng-container matColumnDef="memb" >
                        <th mat-header-cell *matHeaderCellDef > Utente </th>
                        <td mat-cell *matCellDef="let element"> {{element.member.text}} </td>
                      </ng-container>
                      <ng-container matColumnDef="permission" >
                        <th mat-header-cell *matHeaderCellDef> Permessi </th>
                        <td mat-cell *matCellDef="let element" > {{element.permission ? "Lettura/Scrittura" : "Lettura"}} </td>
                      </ng-container>
                      <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=2>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Anno</mat-label>
                <input matInput type="number" name="year" [(ngModel)]="viewOrder.year" readonly>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=2>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Descrizione</mat-label>
                <input matInput type="text" name="description" [(ngModel)]="viewOrder.description" readonly>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=3>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Cliente</mat-label>
                <input matInput type="text" name="client" [(ngModel)]="viewOrder.client.name"  readonly>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=2>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Data offerta</mat-label>
                <input matInput type="text" name="date_offer" [ngModel]="date_offer" readonly>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=2>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Data Consegna</mat-label>
                <input matInput type="text" name="date_delivery" [ngModel]="date_delivery" readonly>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=3>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Commerciale</mat-label>
                <input matInput type="text" name="commerciale" [(ngModel)]="commerciale"  readonly>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=2>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Costi previsti</mat-label>
                <span matPrefix>€ &nbsp;</span>
                <input matInput type="number" name="cost_tot" [ngModel]="viewOrder.cost_tot" readonly>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=2>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Valore</mat-label>
                <span matPrefix>€ &nbsp;</span>
                <input matInput type="number" name="price_awarded" [ngModel]="viewOrder.price_awarded" readonly>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=3>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Note</mat-label>
                <input matInput type="text" name="note" [ngModel]="viewOrder.note" readonly>
              </mat-form-field>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
    </mat-card-content>
  </mat-card>
</form>
