<form #fm="ngForm">
  <mat-card class="helmcard">
    <mat-card-header>
      <span class="underline-text-nebula">
        <h5 class="mdc-text left" class="assettitle">Visualizza prodotto</h5>
      </span>
      <div class="right">
        <button mat-fab class="menubtn" color="primary" *ngIf="userprivilege == 'admin' || viewProd.owner.userId == userId" matTooltip="Edita prodotto" matTooltipPosition="below" [routerLink]="['/helm/products/edit/', viewProdId]">
          <i class="material-icons">edit</i></button>
        <button mat-fab class="menubtn" color="primary" matTooltip="Indietro" routerLink="/helm/products" matTooltipPosition="below">
          <i class="material-icons">close</i></button>
      </div>
    </mat-card-header>
    <mat-card-content>
      <div class="internalMatGrid">
        <mat-grid-list [cols]=5  rowHeight="450px">
          <mat-grid-tile [colspan]=1>
            <div class="col-int">
              <button type="button" id="loadFileBtn">
                <!-- <input type="file" id="loadFileInput" hidden (change)="readUrl($event)" accept=".png,.jpg"/> -->
                <label for="loadFileInput"><img id="prodimg" [src]="viewProd.image"></label>
              </button>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=2>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Codice</mat-label>
                <input matInput type="text" name="code" [ngModel]="viewProd.code" readonly>
              </mat-form-field>
              <mat-form-field class="row">
                <mat-label>Nome</mat-label>
                <input matInput type="text" name="name" [ngModel]="viewProd.name" readonly>
              </mat-form-field>
              <mat-form-field class="row">
                <mat-label>Descrizione</mat-label>
                <input matInput type="text" name="description" [ngModel]="viewProd.description" readonly>
              </mat-form-field>
              <mat-form-field class="row">
                <mat-label>Indirizzo</mat-label>
                <input matInput type="text" name="address" [ngModel]="viewProd.address" readonly>
              </mat-form-field>
              <mat-form-field class="row">
                <mat-label>Proprietario</mat-label>
                <input matInput type="text" name="owner" [ngModel]="prodowner" readonly>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=2>
            <div class="col-int">
              <div *ngIf="viewProd.members.length > 0" [ngfo]="{standalone: true}">
                <div class="col-int">
                  <span class="underline-text-nebula2 left">
                    <h2 class="mdc-text ">Membri</h2>
                  </span>
                  <div id="member-table">
                    <table mat-table [dataSource]="dataSource">
                      <ng-container matColumnDef="memb" >
                        <th mat-header-cell *matHeaderCellDef > Utente </th>
                        <td mat-cell *matCellDef="let element"> {{element.member.text}} </td>
                      </ng-container>
                      <ng-container matColumnDef="permission" >
                        <th mat-header-cell *matHeaderCellDef> Permessi </th>
                        <td mat-cell *matCellDef="let element"> {{element.literalpermission}} </td>
                      </ng-container>
                      <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                  </div>

                </div>
              </div>

            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=1 *ngIf="links.length > 0">
            <div class="col-int">
              <span class="underline-text-nebula2 left">
                <h2 class="mdc-text ">Links</h2>
              </span>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=4 *ngIf="links.length > 0">
            <div class="col-int">
              <div id="link-container">
                <table mat-table [dataSource]="linkDataSource" id="link-table">
                  <ng-container matColumnDef="folder" >
                    <th mat-header-cell *matHeaderCellDef style="width: 30%;"> File/Folder </th>
                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                  </ng-container>
                  <ng-container matColumnDef="link" style="width: 60%;">
                    <th mat-header-cell *matHeaderCellDef > Link </th>
                    <td mat-cell *matCellDef="let element"> {{element.link}} </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="linkDisplayedColumns sticky: true"></tr>
                  <tr mat-row *matRowDef="let row; columns: linkDisplayedColumns;"></tr>
                </table>
              </div>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
    </mat-card-content>
  </mat-card>
</form>
