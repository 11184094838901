<form class="example-form">
    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>Link</mat-label>
      <input matInput [ngModel]="genLink" name="link" readonly>
    </mat-form-field>
    <mat-grid-list [cols]="breakpoint" rowHeight="100px" (window:resize)="onResize($event)">
        <mat-grid-tile class="custom-height">
            <mat-radio-group color="primary"
                aria-labelledby="example-radio-group-label"
                class="example-radio-group"
                [(ngModel)]="link.expired.private" name="private">
                    <mat-radio-button class="example-radio-button" [value]="true">
                        Privato
                    </mat-radio-button>
                    <mat-radio-button class="example-radio-button" [value]="false">
                        Pubblico
                    </mat-radio-button>
            </mat-radio-group>
        </mat-grid-tile>
        <mat-grid-tile class="custom-height">
            <mat-radio-group color="primary"
                aria-labelledby="example-radio-group-label"
                class="example-radio-group"
                [(ngModel)]="link.expired.read" name="read">
                    <mat-radio-button class="example-radio-button" [value]="'r'">
                        Lettura
                    </mat-radio-button>
                    <mat-radio-button class="example-radio-button" [value]="'rw'">
                        Lettura/Scrittura
                    </mat-radio-button>
                    <mat-radio-button *ngIf="data.abs_path.includes('.fbx')" class="example-radio-button" [value]="'v'">
                      Visualizzazione
                  </mat-radio-button>
            </mat-radio-group>
        </mat-grid-tile>
        <mat-grid-tile class="custom-height">
            <mat-radio-group color="primary"
            aria-labelledby="example-radio-group-label"
            class="example-radio-group"
            [(ngModel)]="link.expired.never" name="never">
                <mat-radio-button class="example-radio-button" [value]="false">
                    Senza Scadenza
                </mat-radio-button>
                <mat-radio-button class="example-radio-button" [value]="true">
                    Data di scadenza
                </mat-radio-button>
            </mat-radio-group>
        </mat-grid-tile >
        <mat-grid-tile *ngIf="link.expired.never == true || link.expired.private == true">
            <mat-form-field *ngIf="link.expired.private == true" class="example-full-width" appearance="fill">
                <mat-label>Password</mat-label>
                <input matInput type="text" class="left-input"  name="password" [(ngModel)]="link.expired.password" >
            </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile *ngIf="link.expired.never == true || link.expired.private == true">
        </mat-grid-tile>
        <mat-grid-tile *ngIf="link.expired.never == true || link.expired.private == true">
            <div class="full-width">
                <mat-form-field *ngIf="link.expired.never == true" class="example-full-width" appearance="fill">
                    <mat-label>
                        Data Scandenza
                    </mat-label>
                    <input matInput [matDatepicker]="linkExpire" class="left-input" [(ngModel)]="link.expired.date" name="date" style="width: 165px;">
                    <mat-datepicker #linkExpire></mat-datepicker>
                </mat-form-field>
            </div>
        </mat-grid-tile>
      </mat-grid-list>

    <mat-dialog-actions align="end">
        <button mat-raised-button color="primary" class="confbtn menubtn" position="right" (click)="createLink()" cdkFocusInitial>Crea</button>
        <button mat-raised-button color="accent" mat-dialog-close class="confbtn menubtn">Chiudi</button>
      </mat-dialog-actions>
</form>
