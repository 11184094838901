import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Product } from 'src/app/models/shared/product';
import { HelmService } from 'src/app/services/helm.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-menu-prodotto',
  templateUrl: './menu-prodotto.component.html',
  styleUrls: [
    './menu-prodotto.component.scss',
    '../../../../app.component.scss'
  ]
})
export class MenuProdottoComponent implements OnInit {

  // selectedProdId: string = localStorage.getItem('selectedProd');
  @Input() selectedProdId: string;

  selectedProd: Product;


  constructor(private helmsrv: HelmService, private router: Router, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.LoadProduct();
  }

  ngOnChanges(){
    this.LoadProduct();
  }

  LoadProduct(){
    this.selectedProdId = localStorage.getItem('selectedProd');
    if(this.selectedProdId != "" && this.selectedProdId != "TUTTI I PRODOTTI"){
      this.helmsrv.LoadSelectedProduct(this.selectedProdId)
      .subscribe( res => {
          this.selectedProd = res;
        },
        (error) => {
          console.log("Errore caricamento prodotto", error);
        });
    }
  }

  Load3D(){
    if(this.selectedProd.model3d == ''){
      alert('Nessun modello 3d associato al prodotto')
    }

    if(this.selectedProd.model3d.includes('.fbx')){
      window.open('/#/threeviewer?path=' + this.selectedProd.model3d + '&prodline=f', '_blank');
    }
    else if(this.selectedProd.model3d.includes('.stp')){
      window.open('/#/3dviewer?path=' + this.selectedProd.model3d, '_blank');
    }
  }

  LoadGallery(){
    var path = `/REPO/PRODUCTS/${this.selectedProd.code}/${this.selectedProd.code}_GALLERY/`;
    window.open('/#/cde?path=' + path, '_blank');
  }

  LoadMap(){
    const dialogRef = this.dialog.open(MapDialogContent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  navigateTo(): void {
    localStorage.setItem("CLOUD", "P");
    this.router.navigate(["/cde"], { queryParams: { path: '/REPO/PRODUCTS/' + this.selectedProd.code + '/'} });
  }

}

@Component({
  selector: 'map-dialog-content',
  templateUrl: 'map-dialog.html',
  styles: [
    `
    ::ng-deep .mat-dialog-content{
    height: 100%;
    width: 100%
  }`]
})
export class MapDialogContent {}
