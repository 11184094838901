import { Component, ElementRef, EventEmitter, Input, NgZone, Output, ViewChild } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import * as saveAs from 'file-saver';
import { WbsInfo } from 'src/app/models/wbs/wbs-info';
import { WbsPlanned } from 'src/app/models/wbs/wbs-planned';
import { CommonService } from 'src/app/services/common.service';
import { WbsService } from 'src/app/services/wbs.service';
import { FormControl } from '@angular/forms';
import * as _moment from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MY_FORMATS } from 'src/app/models/shared/datepickerformat';
import { TreeItem } from 'src/app/models/shared/tree-item';
import { MatDialog } from '@angular/material/dialog';
import { ZoomChartWbsComponent } from '../zoom-chart-wbs/zoom-chart-wbs.component';
import { ConfirmDialogComponent } from 'src/app/components/shared/confirm-dialog/confirm-dialog.component';

const moment =  _moment;

@Component({
  selector: 'app-info-wbs',
  templateUrl: './info-wbs.component.html',
  styleUrls: ['./info-wbs.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
  ]
})

export class InfoWbsComponent {

  loaded:boolean = false;
  editing:boolean = false;

  wbsVisualized: string = 'planned';
  allWbsVis: WbsPlanned[] = [];
  treeWbsVis: TreeItem[] = [];

  selOrderWbs: WbsInfo;
  selectedPlannedWbs: WbsPlanned;
  chartdata: any[] = [];
  moneydata: any[] = [];
  wbsType: string;

  start = new FormControl(moment());
  end = new FormControl(moment());
  startString: string;
  endString: string;

  view: any[] = [700, 300];
  legend: boolean = true;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'WEEK';
  yAxisLabel: string = '%';
  timeline: boolean = false;
  yScaleMin: number = 0;
  yScaleMax: number = 100;
  colorScheme = {
    domain: ['#505ba1', 'red', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };

  private _query: string = '';
  public get query(): string {
    return this._query;
  }
  public set query(value: string) {
    this._query = value;
  }

  @Input() selectedOrderId: string;
  @ViewChild('fileInput') fileIn: ElementRef;
  @Output() setWbsEnvType = new EventEmitter<string>();

  constructor(private wbssrv: WbsService, private cmnsrv: CommonService, private zone: NgZone, public dialog: MatDialog) { }

  ngOnChanges(){
    this.LoadWbsInfo();
  }

  LoadWbsInfo(){
    if(this.selectedOrderId != 'TUTTE LE COMMESSE' && this.selectedOrderId != undefined && this.selectedOrderId != ''){
      this.wbssrv.LoadWbsInfo(this.selectedOrderId)
      .subscribe({next: (res) => {
        this.selOrderWbs = res;
        console.log(this.selOrderWbs);
        this.zone.runOutsideAngular(() => {
          this.ElaborateDataForChart();
        })
        this.SetWbsVisType();
        this.loaded = true;
      }, error: (error) => {
        console.log(error);
      }})
    }
  }

  ElaborateDataForChart(){
    this.chartdata = [];
    let series = [];
    let moneyseries = [];
    let perc = 0;
    for(let week of this.selOrderWbs.wbsPlanned.weeks){
      let name = week.name;
      let wbsweeks = [];
      for(let wbc of this.selOrderWbs.wbsPlanned.wbsforchart){
        for(let w of wbc.weeks){
          if(w.id == week.id){
            wbsweeks.push(wbc);
            break;
          }
        }
      }
      for(let wb of wbsweeks){
        perc += wb.weekalloc * 100;
      }
      series.push({name: name, value: perc});
      moneyseries.push({name: name, value: perc/100*this.selOrderWbs.wbsPlanned.total});
    }
    // if(this.chartdata.filter(dt => dt.name === 'planned').length == 0 )
      this.chartdata.push({name: 'planned', series: series});
      this.moneydata.push({name: 'planned', series: moneyseries});

    if(this.selOrderWbs.wbsExecuted.allwbs.length > 0){
      let eseries = [];
      let meseries = [];
      let eperc = 0;
      for(let week of this.selOrderWbs.wbsExecuted.weeks){
        let ename = week.name;
        let wbsweeks = [];
        for(let wbc of this.selOrderWbs.wbsExecuted.wbsforchart){
          for(let w of wbc.weeks){
            if(w.id == week.id){
              wbsweeks.push(wbc);
              break;
            }
          }
        }

        for(let wb of wbsweeks){
          eperc += wb.weekalloc * 100;
        }
        eseries.push({name: ename, value: eperc});
        meseries.push({name: ename, value: eperc/100*this.selOrderWbs.wbsExecuted.total});
      }
      // if(this.chartdata.filter(dt => dt.name === 'executed').length == 0 )
        this.chartdata.push({name: 'executed', series: eseries});
        this.moneydata.push({name: 'executed', series: meseries});
    }
  }

  UploadTemplate(event: any): void{
    const reader = new FileReader();
    if (event.target.files && event.target.files.length){
      const formData = new FormData();
      const[file] = event.target.files;
      formData.append('file', file);
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.wbssrv.UploadWbsTemplate(formData, this.selectedOrderId, this.wbsType)
        .subscribe(res => {
          this.loaded = false;
          console.log(res);
          this.LoadWbsInfo();
          window.location.reload();
          this.fileIn.nativeElement.value = "";
        })
      };
    }
  }

  DownloadTemplate(){
    this.cmnsrv.DownloadTemplate('wbs')
      .subscribe((response) => {
        const blob = new Blob([response], {type: response.type});
        saveAs(blob, 'WbsTemplate.xlsx');
      })
  }

  SetWbsVisType(){
    this.loaded = false;
    this.selectedPlannedWbs = null;
    this.allWbsVis = this.wbsVisualized === 'executed' ? this.selOrderWbs.wbsExecuted.allwbs : this.selOrderWbs.wbsPlanned.allwbs;
    this.treeWbsVis = this.wbsVisualized === 'executed' ? this.selOrderWbs.wbsExecuted.wbstree : this.selOrderWbs.wbsPlanned.wbstree;

    this.loaded = true;
  }

  GetSelectedWbsInfo(id: string){

    this.selectedPlannedWbs = this.allWbsVis.filter(wb => wb.wbsId === id)[0];
    console.log(this.selectedPlannedWbs)
    this.startString = new Date(this.selectedPlannedWbs.start).toLocaleDateString();
    this.endString = (new Date(this.selectedPlannedWbs.end)).toLocaleDateString();

  }

  EditWbs(){
    console.log(this.selectedPlannedWbs)
    if(this.selectedPlannedWbs === null) return;
    this.editing = true;
    let lastSel = this.selectedPlannedWbs;
    let selType = this.wbsVisualized;
    this.wbsVisualized = lastSel.type;
    this.SetWbsVisType();
    //this.selectedPlannedWbs = selType === 'executed' ? lastSel : null;
    this.selectedPlannedWbs = lastSel;
  }

  SaveEditing(){
    if(this.selectedPlannedWbs === null){
      this.editing = false;
      return;
    }

    this.wbssrv.EditWbs(this.selectedPlannedWbs)
      .subscribe(res => {
        console.log(res);
        window.location.reload();
      })
  }

  ResetWbs(){
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '0280px'
    });
    dialogRef.componentInstance.message = "Sicuro di voler resettare le WBS?\n\n\nTutti i dati andranno persi";
    dialogRef.componentInstance.confirm.subscribe(res => {
      if(res == "SI"){
        this.wbssrv.DeleteAllWbs(this.selectedOrderId)
          .subscribe(res => {
            console.log(res);
            window.location.reload();
          })
      }
    })
  }

  SetWbsEnv(type: string){
    this.setWbsEnvType.emit(type);
  }

  ZoomChart(type: string){
    const dialogRef = this.dialog.open(ZoomChartWbsComponent, {
      width: '90%',
      height: '90%'
    });
    let maxTotal = this.selOrderWbs.wbsPlanned.total > this.selOrderWbs.wbsExecuted.total ? this.selOrderWbs.wbsPlanned.total :  this.selOrderWbs.wbsExecuted.total;

    dialogRef.componentInstance.code = this.selOrderWbs.orderCode;
    dialogRef.componentInstance.chartdata = type==='money' ? this.moneydata : this.chartdata;
    dialogRef.componentInstance.yScaleMax = type==='money' ? maxTotal : 100;
    dialogRef.componentInstance.yAxisLabel = type==='money' ? '€' : '%';
  }

}
