<mat-grid-list cols="20" rowHeight="90px" >
  <!--LOGO / HOME-->
  <mat-grid-tile [colspan]=2>
    <a class="brand-logo left" routerLink="home"><img [src]="nebulaImg" height=50 class="navimg"></a>
  </mat-grid-tile>
  <!--Navigazione-->
  <mat-grid-tile [colspan]=9>
    <mat-toolbar >
      <button mat-flat-button class="mynav" routerLink="/helm" *ngIf="userprivilege != 'viewer' && role !== 'Amministrativo'">HELM</button>
      <button mat-flat-button class="mynav" routerLink="/products" *ngIf="userprivilege != 'viewer' && role !== 'Amministrativo'">PRODOTTI</button>
      <button mat-flat-button class="mynav" routerLink="/productlines" *ngIf="userprivilege != 'viewer' && role !== 'Amministrativo'">LINEE</button>
      <button mat-flat-button class="mynav" routerLink="/activities"  *ngIf="userprivilege != 'viewer' && role !== 'Amministrativo'">ATTIVITA'</button>
      <button mat-flat-button class="mynav" routerLink="/wbs"  *ngIf="userprivilege != 'viewer' && role !== 'Amministrativo'">WBS</button>
      <button mat-flat-button class="mynav" routerLink="/placer"  *ngIf="userprivilege != 'viewer' && role !== 'Amministrativo'">PLACER</button>
      <button mat-flat-button class="mynav" routerLink="/coder"  *ngIf="userprivilege != 'viewer'">CODER</button>
      <!-- <a mat-flat-button style="margin-top: 29px; margin-left: 30px;" target="_blank" class="mynav" href="https://nebulateam.bimspace.it/messageHub?path=TST&type=vault" >ATTIVITA'</a> -->
    </mat-toolbar>
  </mat-grid-tile>
  <!--App e ricerca-->
  <mat-grid-tile [colspan]=7 >
<!--     <a mat-mini-fab routerLink="iosApp" class="appbtn" matTooltip="iOS" matTooltipPosition="below" ><img [src]="iosImg" class="center-vertical appimg"/></a>
    <a mat-mini-fab routerLink="androidApp" class="appbtn" matTooltip="Android" matTooltipPosition="below"><img [src]="androidImg" class="center-vertical appimg"/></a>
    <app-ricerca-tot ></app-ricerca-tot> -->
  </mat-grid-tile>
  <!--cartella aziendale-->
  <mat-grid-tile [colspan]=1>
    <ul style="margin-top: 1rem; margin-right: 0.5rem;">
      <li>
          <a mat-icon-button (click)="navigateTo()" matTooltip="Cartella Corporate" matTooltipPosition="below">
            <i class="material-icons nebula-main" style="font-size: 3em;">folder</i></a>
      </li>
    </ul>
  </mat-grid-tile>
  <!--Menù utente-->
  <mat-grid-tile [colspan]=1>
    <button mat-fab [matMenuTriggerFor]="menu" class="usrmenubtn"><img [src]="usrimg" class="usrimg"/></button>
    <mat-menu #menu="matMenu" xPosition="before" >
      <app-user-menu></app-user-menu>
    </mat-menu>
  </mat-grid-tile>
</mat-grid-list>

