import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Breadcumbs } from 'src/app/models/cde/breadcumbs';
import { CloudItem } from 'src/app/models/cde/cloud-item';
import { CdeServiceService } from 'src/app/services/cde-service.service';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { LinkDialogComponent } from '../dialog/link-dialog/link-dialog.component';
import { RevisionComponent, RevSnackBarComponent } from '../revision/revision.component';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { CreateDialogFolderComponent } from '../dialog/create-dialog-folder/create-dialog-folder.component';
import { UploadFileDialogComponent } from '../dialog/upload-file-dialog/upload-file-dialog.component';
import { DeleteDialogComponent } from '../dialog/delete-dialog/delete-dialog.component';
import { PreviewDialogComponent } from '../dialog/preview-dialog/preview-dialog.component';
import { Download } from 'src/app/models/cde/common/download';
import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { HttpEventType } from '@angular/common/http';
import { DeletePermanentlyDialogComponent } from '../dialog/delete-permanently-dialog/delete-permanently-dialog.component';
import { MatDrawerMode, MatSidenav } from '@angular/material/sidenav';
import { MediaMatcher } from '@angular/cdk/layout';
import { OnDestroy } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';
import { CommentReq } from 'src/app/models/cde/comment-req';
import { ShareWithDialogComponent } from '../dialog/share-with-dialog/share-with-dialog.component';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-cde-table',
  templateUrl: './cde-table.component.html',
  styleUrls: [
    './cde-table.component.scss'
  ]
})
export class CdeTableComponent implements OnInit, OnDestroy {

  showLoadingBar: boolean = false;
  loaded: boolean = false;
  mainUrl: string = environment.mainUrl;
  uploadMesssage: string = '';

  displayedColumns: string[] = ['select', 'Nome', 'Dimensione', 'Ultima modifica'];
  permissionColumns: string[] = ['utenza', 'r', 'rw', 'share'];

  nameFolder: string;
  dataSource = new MatTableDataSource<CloudItem>();
  dataSourceSharing = new MatTableDataSource<any>();

  selection = new SelectionModel<CloudItem>(true, []);
  directories: CloudItem[];
  breadcumbs: Breadcumbs[] = [];
  mainPath: string;
  srcPath: string;
  srcItem: CloudItem;
  selectItem: CloudItem;
  action: string;
  showPasteButton: boolean = false;
  showDeleted: boolean = false;
  emptyFolder: boolean = false;
  showProgressBar: boolean = false;
  download$: Observable<Download>
  files: any[] = [];
  uploadProgress: number;
  uploadSub: Subscription;
  formData: FormData;
  // over: MatDrawerMode = "over";
  opened: boolean = false;
  mobileQuery: MediaQueryList;
  moreInfo: any;
  activities: any;
  comments: any;
  topTooltip: TooltipPosition = "above";
  commentSection: boolean = false;
  user_id: string;
  outMessage: string;
  searchText: string;
  showBar: boolean = false;

  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;

  constructor(private cdeservices: CdeServiceService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  private _mobileQueryListener: () => void;

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  menuTopLeftPosition = { x: '0', y: '0' }
  // reference to the MatMenuTrigger in the DOM
  @ViewChild('rMenu') matMenuTrigger: MatMenuTrigger;
  @ViewChild('snav') public sidenav: MatSidenav;

  /**
   * Method called when the user click with the right button
   * @param event MouseEvent, it contains the coordinates
   * @param item Our data contained in the row of the table
   */
  onRightClick(event: MouseEvent, item: CloudItem) {
    // preventDefault avoids to show the visualization of the right-click menu of the browser
    for (let d of this.dataSource.data) {
      d.selected = false;
    }
    event.preventDefault();
    item.selected = true;
    // we record the mouse position in our object
    this.menuTopLeftPosition.x = event.clientX + 'px';
    this.menuTopLeftPosition.y = event.clientY + 'px';

    // we open the menu
    // we pass to the menu the information about our object
    this.matMenuTrigger.menuData = { item: item }
    this.selectItem = { ...item };
    if (this.selectItem.type == "file" && this.selectItem.name.includes(".enc"))
      this.selectItem.name = this.selectItem.name.substring(1, this.selectItem.name.indexOf(".enc"))
    else if (this.selectItem.type == "file" && !this.selectItem.name.includes(".enc"))
      this.selectItem.name = this.selectItem.name.substring(1);
    else if (this.selectItem.type == "folder")
      this.selectItem.name = this.selectItem.name.substring(1)

    // we open the menu
    this.matMenuTrigger.openMenu();
  }

  resetInfo() {
    for (let d of this.dataSource.data) {
      d.selected = false;
    }
  }

  ngOnInit(): void {
    this.route
      .queryParams
      .subscribe(params => {
        // cancello gli elmenti del breadcumbs
        this.breadcumbs = [];
        let mypath = '/';
        if(params.path != undefined) mypath = params.path;
        this.mainPath = mypath;
        let array = mypath.split("/");
        // elimino le stringhe vuote

        array = array.filter(item => item);

        // inserisco la route di root
        //this.breadcumbs.push({ name: "Home", path: "/" });

        let currentPath = "/";
        for (let par of array) {
          if(par != 'REPO' && par != 'PRODUCTS' && par != 'PRODUCTLINES'&& par != 'ORDERS'){
            currentPath += par + "/"
            // inserisco tutte le route presenti
            this.breadcumbs.push({ name: par, path: currentPath });
          }

        }
        if (localStorage.getItem("showDelete")) {
          this.showDeleted = localStorage.getItem("deleted") == "true" ? true : false
        } else {
          localStorage.setItem("deleted", "false")
          this.showDeleted = false;
        }

        return this.cdeservices
          .getCloud(mypath, this.showDeleted, true)
          .subscribe(res => {
            this.dataSource = new MatTableDataSource<CloudItem>(res.dir);
            //this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }, error => {
            //console.log(error);
            this._snackBar.openFromComponent(RevSnackBarComponent, {
              duration: 4 * 1000,
              data: error.error.message
            });

            this.loaded = true;
            setTimeout(() => {

            }, 4000)
          });
      })
  }

  navigateTo(path: string): void {
    let cloudtype = localStorage.getItem("CLOUD");
    let fullpath = '';
    switch(cloudtype){
      case 'P':
        fullpath = '/REPO/PRODUCTS' + path;
        break;
      case 'PL':
        fullpath = '/REPO/PRODUCTLINES' + path;
        break;
      case 'O':
        fullpath = '/REPO/ORDERS' + path;
        break;
      case 'C':
        fullpath = '/REPO' + path;
        break;
    }
    this.router.navigate(["/cde"], { queryParams: { path: fullpath } });
  }

  navigate(row: CloudItem): void {
    if (row.type == "folder") {
      this.router.navigate(["/cde"], { queryParams: { path: row.abs_path } });
      /* this.cdeservices
      .getCloud(row.abs_path, true, false)
        .subscribe(res => {
          this.dataSource = new MatTableDataSource<CloudItem>(res.dir);
          this.dataSource.paginator = this.paginator;
        }) */
    }
  }

  download(item: CloudItem): void {
    this.showProgressBar = true;
    this.download$ = this.cdeservices
      .dowloadFile(item)
    /*  .subscribe((response) => {
       const blob = new Blob([response], {type:response.type});
       const url= window.URL.createObjectURL(blob);
       window.open(url); */
    /* let name = item.name.substring(1)
    if (name.indexOf(".enc") > 0)
      name = item.name.substring(1, item.name.indexOf(".enc"))
    saveAs(blob, name);
  }),
  (error: any) => console.log('Error downloading the file') */
  }

  deleteItem(item: CloudItem): void {
    this.cdeservices
      .deleteCdeItem(item)
      .subscribe(res => {
        this.dataSource = new MatTableDataSource<CloudItem>(res);
      })
  }

  deleteItemPermanently(item: CloudItem): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '30%',
      data: item
    });

    // dopo aver creato la cartella aggiorno la tabella
    dialogRef
      .componentInstance
      .confirm
      .subscribe(dir => {
        this.dataSource = new MatTableDataSource<CloudItem>(dir);
      })
  }

  renameItem(item: CloudItem): void {
    this.cdeservices
      .renameCdeItem(item, this.nameFolder)
      .subscribe(res => {
        if (res.message != null)
          console.log(res.message)
        else
          this.dataSource = new MatTableDataSource<CloudItem>(res.dir);
      })
  }

  cancelRename(item: CloudItem): void {
    item.renameActive = undefined;
  }

  activeRename(item: CloudItem): void {
    item.renameActive = true;
    //console.log(item.name.indexOf("/"))
    if (item.type == "file") {
      this.nameFolder = item.name.substring(1, item.name.indexOf(item.icon))
    } else {
      this.nameFolder = item.name.substring(1);
    }

  }

  openLinkCreation(item: CloudItem): void {
    const dialogRef = this.dialog.open(LinkDialogComponent, {
      width: '40%',
      data: item
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
    });

  }

  openHistoryFile(item: CloudItem): void {

    const dialogRef = this.dialog.open(RevisionComponent, {
      width: '60%',
      data: item
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
    });

  }

  openDialogFolder(): void {

    const dialogRef = this.dialog.open(CreateDialogFolderComponent, {
      width: '30%',
      data: this.mainPath
    });

    // dopo aver creato la cartella aggiorno la tabella
    dialogRef
      .componentInstance
      .confirm
      .subscribe(dir => {
        this.dataSource = new MatTableDataSource<CloudItem>(dir);
      })
  }

  openUpdateDialog(): void {
    const dialogRef = this.dialog.open(UploadFileDialogComponent, {
      width: '30%',
      data: { path: this.mainPath, link: false }
    });

    // dopo aver creato la cartella aggiorno la tabella
    dialogRef
      .componentInstance
      .uplaoded
      .subscribe(dir => {
        this.dataSource = new MatTableDataSource<CloudItem>(dir);
      })
  }

  copyItem(item: CloudItem): void {
    this.action = 'copy';
    this.showPasteButton = true;
    this.srcItem = item;
  }

  cutItem(item: CloudItem): void {
    this.action = 'cut';
    this.showPasteButton = true;
    this.srcItem = item;
  }

  pastItem(): void {
    if (this.srcItem.type == "file") {
      if (this.action == 'copy') {
        this.cdeservices
          .pasteCdeItem(this.srcItem.abs_path, this.mainPath + this.srcItem.abs_path.substring(this.srcItem.abs_path.lastIndexOf("/") + 1), "file/copy")
          .subscribe(res => {
            this.showPasteButton = false;
            this.dataSource = new MatTableDataSource<CloudItem>(res)
            this._snackBar.openFromComponent(RevSnackBarComponent, {
              duration: 4 * 1000,
              data: "Contenuto copiato correttamete"
            });
          })
      } else {
        this.cdeservices
          .pasteCdeItem(this.srcItem.abs_path, this.mainPath + this.srcItem.abs_path.substring(this.srcItem.abs_path.lastIndexOf("/") + 1), "file/move")
          .subscribe(res => {
            this.showPasteButton = false;
            this.dataSource = new MatTableDataSource<CloudItem>(res)
            this._snackBar.openFromComponent(RevSnackBarComponent, {
              duration: 4 * 1000,
              data: "Contenuto spostato correttamete"
            });
          })
      }
    } else {
      if (this.action == 'copy') {
        this.cdeservices
          .pasteCdeItem(this.srcItem.abs_path, this.mainPath + this.srcItem.name.substring(1) + "/", "directory/copy")
          .subscribe(res => {
            this.showPasteButton = false;
            this.dataSource = new MatTableDataSource<CloudItem>(res)
            this._snackBar.openFromComponent(RevSnackBarComponent, {
              duration: 4 * 1000,
              data: "Contenuto copiato correttamete"
            });
          })
      } else {
        this.cdeservices
          .pasteCdeItem(this.srcItem.abs_path, this.mainPath + this.srcItem.name.substring(1) + "/", "directory/move")
          .subscribe(res => {
            this.showPasteButton = false;
            this.dataSource = new MatTableDataSource<CloudItem>(res)
            this._snackBar.openFromComponent(RevSnackBarComponent, {
              duration: 4 * 1000,
              data: "Contenuto spostato correttamete"
            });
          })
      }
    }

  }

  restoreItem(item: CloudItem): void {
    this.cdeservices
      .restoreCdeItem(item)
      .subscribe(res => {
        this.dataSource = new MatTableDataSource<CloudItem>(res.dir)
        this._snackBar.openFromComponent(RevSnackBarComponent, {
          duration: 4 * 1000,
          data: "Elemento rispristinato correttamete"
        });
      })
  }

  OnShareCdeItem(item: CloudItem): void {
    const dialogRef = this.dialog.open(ShareWithDialogComponent, {
      width: '30%',
      data: { cloud: item, share: true }
    });

    // dopo aver creato la cartella aggiorno la tabella
    dialogRef
      .componentInstance
        .confirm
          .subscribe(message => {
            //console.log(message);
            this.cdeservices
              .getCloud(this.mainPath, this.showDeleted, true)
              .subscribe(res => {
                this.dataSource = new MatTableDataSource<CloudItem>(res.dir);
                //this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
              }, error => {
                //console.log(error);
                this._snackBar.openFromComponent(RevSnackBarComponent, {
                  duration: 4 * 1000,
                  data: error.error.message
                });
              })
          })
  }

  OnUnShareCdeItem(item: CloudItem): void {
    const dialogRef = this.dialog.open(ShareWithDialogComponent, {
      width: '30%',
      data: { cloud: item, share: false }
    });

    // dopo aver creato la cartella aggiorno la tabella
    dialogRef
      .componentInstance
      .confirm
      .subscribe(message => {
        this.cdeservices
          .getCloud(this.mainPath, this.showDeleted, true)
          .subscribe(res => {
            this.dataSource = new MatTableDataSource<CloudItem>(res.dir);
            //this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }, error => {
            //console.log(error);
            this._snackBar.openFromComponent(RevSnackBarComponent, {
              duration: 4 * 1000,
              data: error.error.message
            });
          })
      })
  }

  downloadSelected() {
    this.selectItem = { name: "/Archivio.zip", type: "folder" }
    this.showProgressBar = true;
    this.download$ = this.cdeservices
      .dowloadZip(this.selection.selected, this.mainPath)
    this.selection.clear();
  }

  deleteSelected() {
    //console.log(this.selection.selected)
    this.cdeservices
      .deleteMultipleItem(this.selection.selected, this.mainPath)
      .subscribe(res => {
        this.dataSource = new MatTableDataSource<CloudItem>(res)
        this.selection.clear()
      })
  }

  OnSelectedDeletePermanently() {
    this.deleteSelectedPermanently(this.selection.selected);
  }

  onTabChanged($event) {
    this.commentSection = false;
    if ($event.tab.textLabel === "Dettagli") {
      this.cdeservices
        .getMoreInfo(this.selectItem)
        .subscribe(res => {
          this.moreInfo = res.info;
          let clearpath = this.moreInfo.abs_path.slice(this.getAllIndexes(this.moreInfo.abs_path, '/')[2], this.moreInfo.abs_path.length);
          this.moreInfo.abs_path = clearpath;
          this.cdeservices
            .getSharingInfo(this.selectItem)
            .subscribe(res => {
              this.dataSourceSharing = new MatTableDataSource<any>(res.table);
            })
        })
    }
    else {
      this.getActivitiesItem();
    }
  }

  OnOpenInfo(item: CloudItem) {
    this.opened = true;
    this.commentSection = false;
    this.sidenav.open();
    this.cdeservices
      .getMoreInfo(item)
      .subscribe(res => {
        this.moreInfo = res.info;
        let clearpath = this.moreInfo.abs_path.slice(this.getAllIndexes(this.moreInfo.abs_path, '/')[2], this.moreInfo.abs_path.length);
        this.moreInfo.abs_path = clearpath;
        this.cdeservices
          .getSharingInfo(item)
          .subscribe(res => {
            //console.log(res.table)
            this.dataSourceSharing = new MatTableDataSource<any>(res.table);
          })
      })
  }

  OnOpenComments(item: CloudItem) {
    this.opened = true;
    this.user_id = localStorage.getItem("bs_id");
    this.commentSection = true;
    this.sidenav.open();
    this.cdeservices
      .getComments(item)
      .subscribe(res => {
        //console.log(res);
        this.comments = res.comments;
      })
  }

  OnSendComment(item: CloudItem) {
    if (this.outMessage && this.outMessage.length > 0) {
      let data: CommentReq = { id: item.info, message: this.outMessage }
      this.cdeservices
        .sendComment(data)
        .subscribe(res => {
          //console.log(res);
          this.comments = res.comments;
          this.outMessage = "";
        })
    } else {
      this._snackBar.openFromComponent(RevSnackBarComponent, {
        duration: 4 * 1000,
        data: "Non si possono messaggi vuoti!"
      });
    }
  }

  getActivitiesItem() {
    this.cdeservices
      .getActivities(this.selectItem)
      .subscribe(res => {
        //console.log(res)
        this.activities = res.attivita
      })
  }

  deleteSelectedPermanently(items: CloudItem[]): void {
    const dialogRef = this.dialog.open(DeletePermanentlyDialogComponent, {
      width: '30%',
      data: { items: items, path: this.mainPath }
    });

    // dopo aver creato la cartella aggiorno la tabella
    dialogRef
      .componentInstance
      .confirm
      .subscribe(dir => {
        this.dataSource = new MatTableDataSource<CloudItem>(dir);
        this.selection.clear();
      })
  }

  OnRestoreSelectedPermanently(): void {
    this.cdeservices
      .restoreMultipleItem(this.selection.selected, this.mainPath)
      .subscribe(res => {
        this.dataSource = new MatTableDataSource<CloudItem>(res.dir);
        this.selection.clear();
      })
  }

  /**
  * on file drop handler
  */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files.taget.files);
  }

  onShowDeleted() {
    localStorage.setItem("deleted", this.showDeleted == true ? "true" : "false")
    this.cdeservices
      .getCloud(this.mainPath, this.showDeleted, false)
      .subscribe(res => {
        this.dataSource = new MatTableDataSource<CloudItem>(res.dir);
        //this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
  }

  onPreviewItem(item: CloudItem): void {
    if(item.abs_path.includes('.fbx') ){
      console.log(item.abs_path.slice(item.abs_path.lastIndexOf('/') + 1, item.abs_path.lastIndexOf('.')));
      window.open('/#/threeviewer?path=' + item.abs_path + '&prodline=f', '_blank');
/*       this.router.navigate(["/threeviewer"], { queryParams: { path: item.abs_path, prodline: 'f' }}).then((result) => {
      }); */
    }
    else if(item.contentType.includes('octet-stream')){
      //this.router.navigate(["/3dviewer"], { queryParams: { path: item.abs_path }} );
      this.router.navigate(["/cde"], { queryParams: { path: this.mainPath }}).then((result) => {
        console.log(item.abs_path);
        window.open('/#/3dviewer?path=' + item.abs_path, '_blank');
      });
    }
    else if(item.contentType.includes('image/vnd.dwg')){
      //this.router.navigate(["/3dviewer"], { queryParams: { path: item.abs_path }} );
      this.router.navigate(["/cde"], { queryParams: { path: this.mainPath }}).then((result) => {
        window.open('/#/svgviewer?path=' + item.abs_path, '_blank');
      });
    }
    else{
      const dialogRef = this.dialog.open(PreviewDialogComponent, {
        width: '80%',
        data: { cloud: item, share: "" }
      });
    }
  }

  onCloseProgress() {
    this.showProgressBar = false;
  }

  reset() {
    this.uploadProgress = null;
    this.uploadSub = null;
    this.files = []
    this.cdeservices
      .getCloud(this.mainPath, false, false)
      .subscribe(res => {
        this.dataSource = new MatTableDataSource<CloudItem>(res.dir);
        //this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      })
  }

  OpenSearch() {
    this.showBar = !this.showBar;
  }

  OpenSearchResult() {
    this.cdeservices
      .searchItems(this.searchText)
      .subscribe(res => {
        this.dataSource = new MatTableDataSource<CloudItem>(res);
        this.selection.clear();
      }, error => {
        this._snackBar.openFromComponent(RevSnackBarComponent, {
          duration: 4 * 1000,
          data: error.error
        });
      })
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: CloudItem): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row}`;
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
   prepareFilesList(files: Array<any>) {
    this.showLoadingBar = true;
    //console.log(files);
    this.formData = new FormData();
    for (const item of files) {
      this.files.push(item);
    }
    if (this.files.length > 0) {
      this.formData.append("path", this.mainPath);
      this.formData.append("type", "file");
      for (let file of this.files) {
        this.formData.append("file", file);
      }
      const upload$ = this.cdeservices
        .uploadFiles(this.formData)
        .pipe(
          finalize(() => {
            this.reset()
            this.showLoadingBar = false;
            this.uploadMesssage = "Upload In Progress"
          })
        );
      this.uploadSub = upload$.subscribe(event => {
        if (event.type == HttpEventType.UploadProgress) {
          this.uploadProgress = Math.round(100 * (event.loaded / event.total));
          console.log(this.uploadProgress)
          if (this.uploadProgress == 100) {
            this.uploadMesssage = "Take a moment, Nebul@Team is completing the last operations!"
          }
        }
      })
    } else {
      this._snackBar.openFromComponent(RevSnackBarComponent, {
        duration: 4 * 1000,
        data: "Seleziona i file da caricare"
      });
    }
    this.fileDropEl.nativeElement.value = "";
  }

  /**
 * format bytes
 * @param bytes (File size in bytes)
 * @param decimals (Decimals point)
 */
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  getAllIndexes(str, val){
    var indexes = [], i;
    for(i = 0; i < str.length; i++){
      if(str[i] === val){
        indexes.push(i);
      }
    }
    return indexes;
  }
}

@Component({
  selector: 'app-snackbarcde-component',
  template: `
    <span class="cde-snackbar">
      {{data}}
    </span>
  `,
  styles: [`
    .revision-snackbar {
      color: white;
    }
  `],
})
export class CdeSnackBarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: string) { }
}
