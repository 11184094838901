<mat-card id="assetcontainer">
  <div id="headsrc">
    <mat-form-field  appearance="outline" id="search">
      <mat-label>Ricerca commessa</mat-label>
      <input matInput type="text" [(ngModel)]="searchinput" (keydown.enter)="searchOrders()">
      <button mat-button *ngIf="searchinput" matSuffix mat-icon-button aria-label="Clear" (click)="searchinput=''; allorderssrc = allorders;">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field appearance="outline" id="year">
      <mat-label>Anno di riferimento</mat-label>
      <mat-select #ysel name="annorif" [(ngModel)]="refyear" (valueChange)="YearChanged(ysel)">
        <mat-option value="2020">2020</mat-option>
        <mat-option value="2021">2021</mat-option>
        <mat-option value="2022">2022</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div id="allassetcont">
    <button mat-raised-button color="primary" id="singleasset" [style.background-color]="ord == selectedorder ? '#505ba1' : 'rgb(210, 210, 210)'"
      *ngFor="let ord of allorderssrc" (click)="SelOrder(ord)">{{ord.code}}</button>
  </div>
</mat-card>
