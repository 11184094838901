import { HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CloudItem } from 'src/app/models/cde/cloud-item';
import { CdeServiceService } from 'src/app/services/cde-service.service';
import { RevSnackBarComponent } from '../../revision/revision.component';


@Component({
  selector: 'app-upload-file-dialog',
  templateUrl: './upload-file-dialog.component.html',
  styleUrls: ['./upload-file-dialog.component.scss']
})
export class UploadFileDialogComponent implements OnInit {

  fileName: string  = '';
  uploadProgress:number;
  uploadSub: Subscription;
  formData: FormData;
  files: any[] = [];
  link: boolean = false;
  upload$ : any;


  @Output() uplaoded = new EventEmitter<CloudItem[]>();

  constructor(
    public dialogRef: MatDialogRef<UploadFileDialogComponent>,
    private cdeservices: CdeServiceService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.link = this.data.link;
  }

  onFileSelected(event) {
    console.log("Main path", this.data.path)
    this.formData = new FormData();
    for(let file of event.target.files ){
      this.files.push(file)
      console.log(file)
    }
    console.log(this.files)
  }

  uploadRealFiles(): void{
    if (this.files.length > 0) {
      this.formData.append("path", this.data.path);
      this.formData.append("type", "file");
      for (let file of this.files){
        this.formData.append("file", file);
      }



      if (this.link == false){
        this.upload$ = this.cdeservices
        .uploadFiles(this.formData)
          .pipe(
              finalize(() => {
                this.reset()
              })
          );
      }else{
        this.upload$ = this.cdeservices
        .uploadLinkFiles(this.formData)
          .pipe(
              finalize(() => {
                this.reset()
              })
          );
      }

      this.uploadSub = this.upload$.subscribe(event => {
        if (event.type == HttpEventType.UploadProgress) {
          this.uploadProgress = Math.round(100 * (event.loaded / event.total));
          console.log(this.uploadProgress )
          if (this.uploadProgress == 100){
          }
        }
      })
    }else{
      this._snackBar.openFromComponent(RevSnackBarComponent, {
        duration: 4 * 1000,
        data: "Seleziona i file da caricare"
      });
    }
  }

  cancelUpload() {
    this.uploadSub.unsubscribe();
    this.reset();
    this.files = [];
  }

  reset() {
    this.uploadProgress = null;
    this.uploadSub = null;
    this.files = []
    this.cdeservices
      .getCloud(this.data.path, false, false)
        .subscribe(res => {
          this.uplaoded.emit(res.dir);
        })
  }

  deleteFile(index: number): void{
    if (index > -1) {
      this.files.splice(index, 1)
    }
  }


}
