<form #fm="ngForm">
  <mat-card class="helmcard">
    <mat-card-header>
      <span class="underline-text-nebula">
        <h5 class="mdc-text left" class="assettitle">Anagrafica Commessa</h5>
      </span>
      <div class="right">
        <button mat-fab class="menubtn" color="primary" *ngIf="userprivilege == 'admin' || commessa.manager.userId == userId" matTooltip="Edita commessa"
            matTooltipPosition="below" [routerLink]="['/helm/commesse/edit/', commessaId]">
          <i class="material-icons">edit</i></button>
        <button mat-fab class="menubtn" color="primary" matTooltip="Indietro" routerLink="/helm/commesse/all" matTooltipPosition="below">
          <i class="material-icons">close</i></button>
      </div>
    </mat-card-header>
    <mat-card-content>
      <div class="internalMatGrid">
        <mat-grid-list [cols]=10  rowHeight="100px">
          <mat-grid-tile [colspan]=2>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Codice</mat-label>
                <input matInput type="text" name="code" [ngModel]="commessa.code" readonly>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=2>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Nome</mat-label>
                <input matInput type="text" name="name" [ngModel]="commessa.name" readonly>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=3>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Descrizione</mat-label>
                <input matInput type="text" name="description" [ngModel]="commessa.description" readonly>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=3 [rowspan]=4>
            <div class="col-int">
              <div *ngIf="members.length > 0" [ngfo]="{standalone: true}">
                <div class="col-int">
                  <span class="underline-text-nebula2 left">
                    <h2 class="mdc-text ">Membri</h2>
                  </span>
                  <div id="member-table">
                    <table mat-table [dataSource]="dataSource">
                      <ng-container matColumnDef="memb" >
                        <th mat-header-cell *matHeaderCellDef > Utente </th>
                        <td mat-cell *matCellDef="let element"> {{element.member.text}} </td>
                      </ng-container>
                      <ng-container matColumnDef="permission" >
                        <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Permessi </th>
                        <td mat-cell *matCellDef="let element" style="text-align: center;"> {{element.literalpermission}} </td>
                      </ng-container>
                      <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                  </div>

                </div>
              </div>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=2>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Anno</mat-label>
                <input matInput type="number" name="year" [ngModel]="commessa.year" readonly>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=2>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Color</mat-label>
                <input matInput type="color" name="color" [ngModel]="commessa.color" readonly>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=3>
            <div class="col-int">
              <mat-form-field class="row" >
                <mat-label>Proprietario</mat-label>
                <input matInput type="text" name="owner" [ngModel]="manager" readonly>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=2>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Data offerta</mat-label>
                <input matInput type="text" name="date_offer" [ngModel]="date_offer" readonly>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=2>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Data Consegna</mat-label>
                <input matInput type="text" name="date_delivery" [ngModel]="date_delivery" readonly>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=3>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Cliente</mat-label>
                <input matInput type="text" name="client" [ngModel]="client" readonly>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=2>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Costi previsti</mat-label>
                <span matPrefix>€ &nbsp;</span>
                <input matInput type="number" name="cost_tot" [ngModel]="commessa.cost_tot" readonly>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=2>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Valore</mat-label>
                <span matPrefix>€ &nbsp;</span>
                <input matInput type="number" name="price_awarded" [ngModel]="commessa.price_awarded" readonly>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=3>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Note</mat-label>
                <input matInput type="text" name="note" [ngModel]="commessa.note" readonly>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=1 *ngIf="links.length > 0">
            <div class="col-int">
              <span class="underline-text-nebula2 left">
                <h2 class="mdc-text ">Links</h2>
              </span>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=8 [rowspan]=4 *ngIf="links.length > 0">
            <div class="col-int">
              <div id="link-container">
                <table mat-table [dataSource]="linkDataSource" id="link-table">
                  <ng-container matColumnDef="folder" >
                    <th mat-header-cell *matHeaderCellDef style="width: 30%;"> File/Folder </th>
                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                  </ng-container>
                  <ng-container matColumnDef="link" style="width: 60%;">
                    <th mat-header-cell *matHeaderCellDef > Link </th>
                    <td mat-cell *matCellDef="let element"> {{element.link}} </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="linkDisplayedColumns sticky: true"></tr>
                  <tr mat-row *matRowDef="let row; columns: linkDisplayedColumns;"></tr>
                </table>
              </div>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
    </mat-card-content>
  </mat-card>
</form>
