import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Breadcumbs } from 'src/app/models/cde/breadcumbs';
import { CloudItem } from 'src/app/models/cde/cloud-item';
import { CdeServiceService } from 'src/app/services/cde-service.service';
import { saveAs } from 'file-saver';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { UploadFileDialogComponent } from '../dialog/upload-file-dialog/upload-file-dialog.component';
import { PreviewDialogComponent } from '../dialog/preview-dialog/preview-dialog.component';
import { Observable } from 'rxjs';
import { Download } from 'src/app/models/cde/common/download';
import { TooltipPosition } from '@angular/material/tooltip';
import { AuthenticationService } from 'src/app/services/Login/authentication.service';

@Component({
  selector: 'app-link-handler',
  templateUrl: './link-handler.component.html',
  styleUrls: ['./link-handler.component.scss']
})
export class LinkHandlerComponent implements OnInit {

  displayedColumns: string[] = ['select', 'Nome', 'Dimensione', 'Ultima modifica'];
  shareLink: string;
  codeLink: string;
  dataSource = new MatTableDataSource<CloudItem>();
  selection = new SelectionModel<CloudItem>(true, []);
  directories: CloudItem[];
  selectItem: CloudItem;
  breadcumbs: Breadcumbs[] = [];
  mainPath: string;
  rootPath: string;
  action: string;
  flags: any;
  download$: Observable<Download>
  showProgressBar: boolean = false;
  positionTooltip: TooltipPosition = 'above';

  constructor(
    private cdeservices: CdeServiceService,
    private auth: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
    ) {}

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  menuTopLeftPosition =  {x: '0', y: '0'}
  // reference to the MatMenuTrigger in the DOM
  @ViewChild('rMenu') matMenuTrigger: MatMenuTrigger;

  /**
   * Method called when the user click with the right button
   * @param event MouseEvent, it contains the coordinates
   * @param item Our data contained in the row of the table
   */
  onRightClick(event: MouseEvent, item: CloudItem) {
      // preventDefault avoids to show the visualization of the right-click menu of the browser
      for (let d of this.dataSource.data){
        d.selected = false;
      }
      event.preventDefault();
      item.selected = true;
      // we record the mouse position in our object
      this.menuTopLeftPosition.x = event.clientX + 'px';
      this.menuTopLeftPosition.y = event.clientY + 'px';

      // we open the menu
      // we pass to the menu the information about our object
      this.matMenuTrigger.menuData = {item: item}
      this.selectItem = {...item};
      if (this.selectItem.type == "file" && this.selectItem.name.includes(".enc"))
        this.selectItem.name = this.selectItem.name.substring(1, this.selectItem.name.indexOf(".enc"))
      else if (this.selectItem.type == "file" && !this.selectItem.name.includes(".enc"))
        this.selectItem.name = this.selectItem.name.substring(1);
      else if (this.selectItem.type == "folder")
        this.selectItem.name = this.selectItem.name.substring(1)

      // we open the menu
      this.matMenuTrigger.openMenu();
  }

  resetInfo() {
      for (let d of this.dataSource.data){
        d.selected = false;
      }
  }

  ngAfterViewInit() {}

  ngOnInit(): void {
    this.route
    .queryParams
      .subscribe(params => {
        console.log(params);
        // cancello gli elmenti del breadcumbs
        this.breadcumbs = [];
        this.shareLink = "share="+params.share;
        this.codeLink = params.share;
        if (params.path){
          localStorage.setItem("flags", JSON.stringify(this.flags));
          let array = params.path.split("/");
          // elimino le stringhe vuote
          array = array.filter(item => item);
          if (!params.path.endsWith("/")) array = array.slice(0, -1)

          // inserisco la route di root
          // this.breadcumbs.push({name : "Home", path : "/"});

          let currentPath = "/";
          for (let par of array) {
            if(par != 'REPO' && par != 'PRODUCTS' && par != 'PRODUCTLINES'){
              currentPath += par + "/"
              // inserisco tutte le route presenti
              this.breadcumbs.push({ name: par, path: currentPath });
            }
          }
          return this.cdeservices
          .getLinkCloud(params.path, params.share)
            .subscribe(res => {
              this.flags = {view : res.onlyview, read : res.read, write : res.write}
              console.log(this.flags);
              this.mainPath = res.root;
              this.dataSource = new MatTableDataSource<CloudItem>(res.repo);
              //this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;

              if(this.flags.view && this.mainPath.includes('.fbx')){
                if(array.includes('PRODUCTS')){
                  this.router.navigate(['/threeviewerlink'], { queryParams: { path : this.mainPath, prodline: 'f', link: true}});
                }
                else if (array.includes('PRODUCTLINES')){
                  this.router.navigate(['/threeviewerlink'], { queryParams: { path : this.mainPath, prodline: 'f', link: true}});
                }
              }

            }, error => {
              this._snackBar.openFromComponent(CdeSnackBarComponent, {
                duration: 4 * 1000,
                data: error.error
              });
            });

        }else{
          return this.cdeservices
            .getLinkInfo(this.shareLink)
              .subscribe(res => {
                localStorage.setItem('CLOUD', res.prod_type)
                this.flags = {view : res.onlyview, read : res.read, write : res.write}
                console.log(this.flags);
                let array = res.root.split("/");
                localStorage.setItem("linkPath", res.root)
                this.mainPath = res.root;
                // elimino le stringhe vuote
                array = array.filter(item => item);

                if (!res.root.endsWith("/")) array = array.slice(0, -1)

                // inserisco la route di root
                // this.breadcumbs.push({name : "Home", path : "/"});

                let currentPath = "/";
                for (let par of array) {
                  if(par != 'REPO' && par != 'PRODUCTS' && par != 'PRODUCTLINES'){
                    currentPath += par + "/"
                    // inserisco tutte le route presenti
                    this.breadcumbs.push({ name: par, path: currentPath });
                  }
                }
                this.dataSource = new MatTableDataSource<CloudItem>(res.repo);
                //this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;

                if(this.flags.view && this.mainPath.includes('.fbx')){
                  if(array.includes('PRODUCTS')){
                    this.router.navigate(['/threeviewerlink'], { queryParams: { path : this.mainPath, prodline: 'f', link: true}});
                  }
                  else if (array.includes('PRODUCTLINES')){
                    this.router.navigate(['/threeviewerlink'], { queryParams: { path : this.mainPath, prodline: 'f', link: true}});
                  }

                }
              }, error => {
                if (error.error === "private")
                {
                  this.router.navigate(["private"], { queryParams: {share : this.codeLink}})
                }else{
                  this._snackBar.openFromComponent(CdeSnackBarComponent, {
                    duration: 4 * 1000,
                    data: error.error.message || error.error,
                  });
                }
            });
        }
    })
  }

  navigateTo(path: string): void {
    let cloudtype = localStorage.getItem("CLOUD");
    let fullpath = '';
    switch(cloudtype){
      case 'P':
        fullpath = '/REPO/PRODUCTS' + path;
        break;
      case 'PL':
        fullpath = '/REPO/PRODUCTLINES' + path;
        break;
      case 'C':
        fullpath = '/REPO' + path;
        break;
    }
    if (this.mainPath.length <= path.length)
      this.router.navigate(['/link'], { queryParams: { share : this.codeLink, path: fullpath}});
  }

  navigate(row: CloudItem): void{
    if (row.type == "folder" && this.mainPath.length <= row.abs_path.length){
      this.router.navigate(['/link'], { queryParams: {share : this.codeLink, path: row.abs_path}});
      /* this.cdeservices
      .getCloud(row.abs_path, true, false)
        .subscribe(res => {
          this.dataSource = new MatTableDataSource<CloudItem>(res.dir);
          this.dataSource.paginator = this.paginator;
        }) */
    }
  }

  download(item: CloudItem): void{
    this.showProgressBar = true;
    this.download$ = this.cdeservices
      .dowloadFile(item)
   /*  this.cdeservices
      .dowloadFile(item)
        .subscribe((response) => {
          const blob = new Blob([response], {type:response.type}); */
          /* const url= window.URL.createObjectURL(blob); da non decommentare
          window.open(url); */
        /*   let name = item.name.substring(1)
          if (name.indexOf(".enc") > 0)
            name = item.name.substring(1, item.name.indexOf(".enc"))
          saveAs(blob, name);
        }),
        (error: any) => console.log('Error downloading the file') */
  }

  deleteItem(item: CloudItem): void{
    this.cdeservices
      .deleteCdeItem(item)
        .subscribe(res => {
          this.dataSource = new MatTableDataSource<CloudItem>(res);
        })
  }

  openUpdateDialog(): void{
    const dialogRef = this.dialog.open(UploadFileDialogComponent, {
      width: '30%',
      data: {path: this.mainPath, link: true}
    });

    // dopo aver creato la cartella aggiorno la tabella
    dialogRef
      .componentInstance
        .uplaoded
          .subscribe(dir => {
            this.dataSource = new MatTableDataSource<CloudItem>(dir);
    })
  }

  onPreviewItem(item: CloudItem): void{
    const dialogRef = this.dialog.open(PreviewDialogComponent, {
      width: '60%',
      data: {cloud : item, share : this.codeLink}
    });
  }

  onCloseProgress(){
    this.showProgressBar = false;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: CloudItem): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row}`;
  }

}

@Component({
  selector: 'app-snackbarlink-component',
  template: `
    <span class="cde-snackbar">
      {{data}}
    </span>
  `,
  styles: [`
    .revision-snackbar {
      color: white;
    }
  `],
})
export class CdeSnackBarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: string){}
}
