import { Component, OnInit} from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Commessa } from 'src/app/models/helm/commessa';
import { CompanyLight } from 'src/app/models/helm/company-light';
import { ProdMemb } from 'src/app/models/helm/prod-memb';
import { AuthUser } from 'src/app/models/shared/auth-user';
import { AutocompObj } from 'src/app/models/shared/autocomp-obj';
import { HelmService } from 'src/app/services/helm.service';
import { OrderService } from 'src/app/services/order.service';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import * as _moment from 'moment';
import { MY_FORMATS } from 'src/app/models/shared/datepickerformat';
import { CloudItem } from 'src/app/models/cde/cloud-item';

@Component({
  selector: 'app-view-commessa',
  templateUrl: './view-commessa.component.html',
  styleUrls: ['./view-commessa.component.scss']
})
export class ViewCommessaComponent implements OnInit {

  commessaId: string;
  commessaCode: string;
  commessa: Commessa = {
    client: {
      address: '',
      companyId: '',
      logo: '',
      name: '',
      type: ''
    },
    code: '',
    color: '',
    cost_tot: null,
    description: '',
    date_delivery: null,
    date_offer: null,
    manager: null,
    name: '',
    note: '',
    orderId: null,
    price_awarded: null,
    members: [],
    status: 'active',
    year: 2022
  };

  userId: string = localStorage.getItem('id');
  userprivilege: string = localStorage.getItem('priv');

  members: ProdMemb[] = [];
  links: CloudItem[] = [];

  linkDisplayedColumns = ['folder', 'link'];
  linkDataSource = new MatTableDataSource<CloudItem>();

  manager:string;
  client: string;

  date_offer: string;
  date_delivery: string;

  users: AuthUser[] = [];
  usersForSel: AutocompObj[] = [];
  ownerCtrl = new FormControl();
  filteredusrsowner: Observable<AutocompObj[]>;
  selectedowner: AutocompObj;

  memberCtrl = new FormControl();
  filteredusrsmember: Observable<AutocompObj[]>;
  selectedmember: AutocompObj;

  companies: CompanyLight[]= [];
  companiesForSel: AutocompObj[] = [];
  companiesCtrl = new FormControl();
  filteredcompanies: Observable<AutocompObj[]>;
  selectedcompany: AutocompObj;

  displayedColumns = ['memb', 'permission'];
  dataSource = new MatTableDataSource<ProdMemb>();

  constructor(private helmsrv: HelmService, private activatedRoute: ActivatedRoute, private ordsrv: OrderService, private router: Router, public snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.commessaId = this.activatedRoute.snapshot.params.id;
    this.LoadSettings();
  }

  LoadSettings(){
    this.ordsrv.LoadSelectedCommessa(this.commessaId)
      .subscribe(res => {
        this.commessa = res;
        this.commessaCode = this.commessa.code;
        for(let memb of this.commessa.members){
          let member: ProdMemb= {
            member: {id: memb.user.userId, text: `${memb.user.surname} ${memb.user.name}  - ${memb.user.company.name}`},
            permission: memb.permission,
            literalpermission: memb.permission ? 'Lettura/Scrittura' : 'Lettura',
            privilege: memb.user.privilege
          }
          this.members.push(member);
        }
        this.dataSource.data = this.members;
        this.manager = `${this.commessa.manager.surname} ${this.commessa.manager.name}  - ${this.commessa.manager.company.name}`;
        this.date_offer = this.commessa.date_offer.toLocaleDateString();
        this.date_delivery = this.commessa.date_delivery.toLocaleDateString();
        this.client = this.commessa.client.name;
        this.helmsrv.GetLinks(this.commessaId, 'O')
          .subscribe(res => {
            this.links = res;
            this.linkDataSource.data = this.links;
          })
      })
  }
}
