<mat-card class="helmcard">
  <mat-card-header>
    <span class="underline-text-nebula">
      <h5 class="mdc-text left" class="assettitle">Linee</h5>
    </span>
    <div class="right">
      <button mat-fab class="menubtn" color="primary" matTooltip="Nuovo prodotto" routerLink="/helm/productlines/new" matTooltipPosition="below">
        <i class="material-icons">add</i></button>
      <button mat-fab class="menubtn" color="primary" matTooltip="Indietro" routerLink="/helm" matTooltipPosition="below">
        <i class="material-icons">close</i></button>
    </div>
  </mat-card-header>
  <mat-card-content>
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="image">
        <th mat-header-cell *matHeaderCellDef> Immagine </th>
        <td mat-cell *matCellDef="let element"><img class="profile-circle" [src]="element.image"></td>
      </ng-container>
      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef> Codice </th>
        <td mat-cell *matCellDef="let element"> {{element.code}} </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Nome </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>
      <ng-container matColumnDef="desc">
        <th mat-header-cell *matHeaderCellDef> Descrizione </th>
        <td mat-cell *matCellDef="let element"> {{element.description}} </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Azioni </th>
        <td mat-cell *matCellDef="let element">
          <a *ngIf="userprivilege == 'admin' || element.owner.userId == userId" matTooltip="Edita linea" matTooltipPosition="below" [routerLink]="['/helm/productlines/edit/', element.prodlineId]"><i class="material-icons nebula-dark">edit</i></a>
          <a *ngIf="userprivilege == 'admin' || element.owner.userId == userId" matTooltip="Elimina linea" matTooltipPosition="below" routerLink="/helm/productlines" (click)="openDialog(element.prodlineId)"><i class="material-icons nebula-dark">delete</i></a>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </mat-card-content>
</mat-card>
