import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LastLevelCode } from 'src/app/models/syscoder/last-level-codes';
import { SingleCode } from 'src/app/models/syscoder/single-code';
import { CoderService } from 'src/app/services/coder.service';

@Component({
  selector: 'app-coder-creator',
  templateUrl: './coder-creator.component.html',
  styleUrls: ['./coder-creator.component.scss']
})
export class CoderCreatorComponent implements OnInit {

  loaded = false;
  alllevel1: SingleCode[];
  level1selected;
  alllevel2: SingleCode[];
  level2forsel: SingleCode[];
  level2selected: SingleCode;
  alllevel3: SingleCode[];
  level3forsel: SingleCode[];
  level3selected: SingleCode;
  alllevel4: SingleCode[];

  newcode: SingleCode

  codepreview:string;
  codetoval: string;

  historycodes: LastLevelCode[];

  constructor(private codesrv: CoderService, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.LoadLevels()
  }

  LoadLevels(){
    this.codesrv.loadCodesLeveled()
    .subscribe(res => {
      this.level1selected = {
        code: '',
        description: '',
        father: null,
        id: ''
      }
        this.alllevel1 = res[0]
        this.alllevel1.unshift(this.level1selected)
        this.alllevel2 = res[1]
        this.alllevel3 = res[2]
        this.alllevel4 = res[3]

        this.newcode = {
          code: '',
          description: '',
          father: null,
          id: ''
        }
        this.level2selected = null;
        this.level2forsel = [];
        this.level3selected = null;
        this.level3forsel = [];

        this.codepreview = '';

        this.codesrv.loadHistoryCodes()
          .subscribe(hc => {
            this.historycodes = hc
            this.loaded = true;
          })
    })
  }

  loadCodes(level: number){
    let newc: SingleCode = {
      code: '',
      description: '',
      father: null,
      id: ''
    }

    if(level === 1){
      this.level3forsel = [];
      this.level3selected = newc;
      this.level2selected = newc;
      this.level2forsel = this.alllevel2.filter(lv => lv.father === this.level1selected.id)
      this.level2forsel.unshift(newc)
    }
    else if (level === 2){
      this.level3selected = newc;
      this.level3forsel = this.alllevel3.filter(lv => lv.father === this.level2selected.id)
      this.level3forsel.unshift(this.level3selected)
    }

    this.PreviewCode()
  }

  PreviewCode(){
    if(this.newcode.code !== '' && this.newcode.description !== ''){
      if(this.newcode.code.length > 2){
        this.snackBar.open("Il codice non può avere più di 2 cifre", '', {duration: 3000});
        this.codepreview = ''
      }
      else if(isNaN(Number(this.newcode.code))){
        this.snackBar.open("Il codice deve essere un numero", '', {duration: 3000})
        this.codepreview = ''
      }
      else{
        this.codetoval = this.newcode.code;
        if(this.newcode.code.length === 1) this.codetoval = "0" + this.newcode.code;
        if(this.level3selected && this.level3selected.code !== ''){
          let existingcode = this.alllevel4.filter(lv => lv.code === this.codetoval && lv.father === this.level3selected.id)[0]
          let existingdesc = this.alllevel4.filter(lv => lv.description.toUpperCase() === this.newcode.description.toUpperCase() && lv.father === this.level3selected.id)[0]
          if(existingcode){
            this.snackBar.open(`Il codice esiste già e corrsiponde a: ${this.level1selected.description}-${this.level2selected.description}-${this.level3selected.description}-${existingcode.description}`, '', {duration: 5000})
            this.codepreview = ''
          }
          else if(existingdesc){
            this.snackBar.open(`Questa descrizione esiste già e corrsiponde al codice: ${this.level1selected.code}-${this.level2selected.code}-${this.level3selected.code}-${existingdesc.code}`, '', {duration: 5000})
            this.codepreview = ''
          }
          else{
            this.codepreview = `${this.level1selected.code}-${this.level2selected.code}-${this.level3selected.code}-${this.codetoval}`
            this.newcode.id = `${this.level1selected.code}${this.level2selected.code}${this.level3selected.code}${this.codetoval}`
            this.newcode.father = this.level3selected.id;
          }
        }
        else if(this.level2selected && this.level2selected.code !== ''){
          let existingcode = this.alllevel3.filter(lv => lv.code === this.codetoval && lv.father === this.level2selected.id)[0]
          let existingdesc = this.alllevel3.filter(lv => lv.description.toUpperCase() === this.newcode.description.toUpperCase() && lv.father === this.level2selected.id)[0]
          if(existingcode){
            this.snackBar.open(`Il codice esiste già e corrsiponde a: ${this.level1selected.description}-${this.level2selected.description}-${existingcode.description}`, '', {duration: 5000})
            this.codepreview = ''
          }
          else if(existingdesc){
            this.snackBar.open(`Questa descrizione esiste già e corrsiponde al codice: ${this.level1selected.code}-${this.level2selected.code}-${existingdesc.code}`, '', {duration: 5000})
            this.codepreview = ''
          }
          else{
            this.codepreview = `${this.level1selected.code}-${this.level2selected.code}-${this.codetoval}-00`
            this.newcode.id = `${this.level1selected.code}${this.level2selected.code}${this.codetoval}`
            this.newcode.father = this.level2selected.id;
          }
        }
        else if(this.level1selected && this.level1selected.code !== ''){
          let existingcode = this.alllevel2.filter(lv => lv.code === this.codetoval && lv.father === this.level1selected.id)[0]
          let existingdesc = this.alllevel2.filter(lv => lv.description.toUpperCase() === this.newcode.description.toUpperCase() && lv.father === this.level1selected.id)[0]
          if(existingcode){
            this.snackBar.open(`Il codice esiste già e corrsiponde a: ${this.level1selected.description}-${existingcode.description}`, '', {duration: 5000})
            this.codepreview = ''
          }
          else if(existingdesc){
            this.snackBar.open(`Questa descrizione esiste già e corrsiponde al codice: ${this.level1selected.code}-${existingdesc.code}`, '', {duration: 5000})
            this.codepreview = ''
          }
          else{
            this.codepreview = `${this.level1selected.code}-${this.codetoval}-00-00`
            this.newcode.id = `${this.level1selected.code}${this.codetoval}`
            this.newcode.father = this.level1selected.id;
          }
        }
        else{
          let existingcode = this.alllevel1.filter(lv => lv.code === this.codetoval)[0]
          let existingdesc = this.alllevel1.filter(lv => lv.description.toUpperCase() === this.newcode.description.toUpperCase())[0]
          if(existingcode){
            this.snackBar.open(`Il codice esiste già e corrsiponde a: ${existingcode.description}`, '', {duration: 5000})
            this.codepreview = ''
          }
          else if(existingdesc){
            this.snackBar.open(`Questa descrizione esiste già e corrsiponde al codice: ${existingdesc.code}`, '', {duration: 5000})
            this.codepreview = ''
          }
          else{
            this.codepreview = `${this.codetoval}-00-00-00`
            this.newcode.id = this.codetoval
            this.newcode.father = null
          }
        }
      }
    }
    else{
      this.codepreview = ''
      this.newcode.id = ''
      this.newcode.father = null
    }
  }

  SaveCode(){
    this.newcode.code = this.codetoval;
    this.codesrv.addNewCode(this.newcode)
      .subscribe(() => {
        this.LoadLevels()
      })
  }
}
