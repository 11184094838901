import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrderStatus } from 'src/app/models/helm/order-status';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-status-order-dialog',
  templateUrl: './status-order-dialog.component.html',
  styleUrls: ['./status-order-dialog.component.scss']
})
export class StatusOrderDialogComponent implements OnInit {

  @Input() ordstatus: OrderStatus;
  @Input() venduto: number = 0;
  @Input() orderId: string = "";
  @Output() confirm = new EventEmitter();

  constructor(private ordsrv: OrderService, public dialogRef: MatDialogRef<StatusOrderDialogComponent>, public snackBar: MatSnackBar) { }

  ngOnInit(): void {

  }

  Ricalcola(change: string, input){
    if(change === 'erogato'){
      this.ordstatus.erogato = input.value;
      this.ordstatus.da_erogare = this.venduto - this.ordstatus.erogato;
    }
    else{
      this.ordstatus.da_erogare = input.value;
      this.ordstatus.erogato = this.venduto - this.ordstatus.da_erogare;
    }
  }

  SaveStatus(form: NgForm){
    this.ordstatus = {...form.value};
    this.ordstatus.perc_chiusura = this.ordstatus.perc_chiusura > 100 ? 100 : this.ordstatus.perc_chiusura;
    this.ordsrv.UpdateStatus(this.orderId, this.ordstatus)
      .subscribe( () => {
        this.confirm.emit('OK');
        this.dialogRef.close();
      })
  }
}
