import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogComponent } from 'src/app/components/shared/confirm-dialog/confirm-dialog.component';
import { Prodline } from 'src/app/models/shared/prodline';
import { HelmService } from 'src/app/services/helm.service';

@Component({
  selector: 'app-all-productlines',
  templateUrl: './all-productlines.component.html',
  styleUrls: [
    './all-productlines.component.scss',
    '../../../../app.component.scss'
  ]
})
export class AllProductlinesComponent implements OnInit {

  allProdlines: Prodline[];
  displayedColumns = ['image', 'code', 'name', 'desc', 'actions'];
  dataSource = new MatTableDataSource<Prodline>();
  userId: string = localStorage.getItem('id');
  userprivilege: string = localStorage.getItem('priv');

  constructor(private helmsrv: HelmService, public dialog: MatDialog, public snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.LoadAllProductlines();
  }

  LoadAllProductlines(){
    this.helmsrv.LoadAllProductlines().subscribe(
      (res) => {
        this.allProdlines = res;
        this.dataSource.data = this.allProdlines;
        console.log(res)
      },
      (error) => {
        console.log("Errore caricamento prodotti", error);
      });
  }

  openDialog(productlineId: string): void {
    this.helmsrv.CheckOrderProd(productlineId)
      .subscribe(res => {
        if(res.confirm){
          this.snackBar.open(res.message, "OK", {
            duration: 5000,
            horizontalPosition: "center",
            verticalPosition: "bottom"
          })
        }
        else{
          const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '250px'
          });
          dialogRef.componentInstance.message = "Sicuro di voler cancellare la linea selezionata?";
          dialogRef.componentInstance.confirm.subscribe(res => {
            if(res == "SI"){
              this.helmsrv.DeleteProductline(productlineId).subscribe(res=>{
                console.log(res);
                localStorage.setItem('selectedProdline', 'TUTTE LE LINEE');
                this.LoadAllProductlines();
              }, (err) => {
                console.log(err);
              })
            }
          })
        }
      })
  }
}
