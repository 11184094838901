import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { CloudItem } from 'src/app/models/cde/cloud-item';
import { ProdMemb } from 'src/app/models/helm/prod-memb';
import { Product } from 'src/app/models/shared/product';
import { HelmService } from 'src/app/services/helm.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-product-helm',
  templateUrl: './view-product-helm.component.html',
  styleUrls: ['./view-product-helm.component.scss']
})
export class ViewProductHelmComponent implements OnInit {

  userId: string = localStorage.getItem('id');
  userprivilege: string = localStorage.getItem('priv');

  viewProdId: string;
  viewProdCode: string;
  viewProd: Product = {
    productId: "",
    code: "",
    image: environment.imageUrl + "/defaultproduct.png",
    address: "",
    name: "",
    description: "",
    owner: {
      userId: "",
      name: "",
      surname: "",
      company: {
        companyId: "",
        name: "",
        address: "",
        type: "",
        logo: ""
      },
      privilege: "",
      user_type: ""
    },
    members: []
  }
  prodowner:string;

  links: CloudItem[] = [];

  members: ProdMemb[] = [];
  displayedColumns = ['memb', 'permission'];
  dataSource = new MatTableDataSource<ProdMemb>();

  linkDisplayedColumns = ['folder', 'link'];
  linkDataSource = new MatTableDataSource<CloudItem>();

  constructor(private helmsrv: HelmService, private activatedRoute: ActivatedRoute) { }


  ngOnInit(): void {
    this.viewProdId = this.activatedRoute.snapshot.params.id;
    this.LoadProduct();
  }

  LoadProduct(){
    this.helmsrv.LoadSelectedProduct(this.viewProdId)
      .subscribe(res => {
        this.viewProd = res;
        console.log(res)
        this.viewProdCode = this.viewProd.code;
        this.prodowner = `${this.viewProd.owner.surname} ${this.viewProd.owner.name}  - ${this.viewProd.owner.company.name}`;

        for(let memb of this.viewProd.members){
          let member: ProdMemb= {
            member: {id: memb.user.userId, text: `${memb.user.surname} ${memb.user.name}  - ${memb.user.company.name}`},
            permission: memb.permission,
            literalpermission: memb.permission ? 'Lettura/Scrittura' : 'Lettura',
            privilege: memb.user.privilege
          }
          this.members.push(member);
        }

        this.helmsrv.GetLinks(this.viewProdId, 'P')
          .subscribe(res => {
            console.log(res)
            this.links = res;
            this.linkDataSource.data = this.links;
            this.dataSource.data = this.members;
          })
      })

  }
}
