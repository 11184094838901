<mat-card class="helmcard">
  <mat-card-header>
    <span class="underline-text-nebula">
      <h5 class="mdc-text left" class="assettitle">Commesse</h5>
    </span>
    <div class="right">
      <button *ngIf="userprivilege == 'admin'" mat-fab class="menubtn" color="primary" matTooltip="Nuova commessa" routerLink="/helm/commesse/new" matTooltipPosition="below">
        <i class="material-icons">add</i></button>
      <button mat-fab class="menubtn" color="primary" matTooltip="Indietro" routerLink="/helm" matTooltipPosition="below">
        <i class="material-icons">close</i></button>
    </div>
  </mat-card-header>
  <mat-card-content>
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef> Codice </th>
        <td mat-cell *matCellDef="let element"> {{element.code}} </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Nome </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>
      <ng-container matColumnDef="company">
        <th mat-header-cell *matHeaderCellDef> Cliente </th>
        <td mat-cell *matCellDef="let element"> {{element.client.name}} </td>
      </ng-container>
      <ng-container matColumnDef="date_delivery">
        <th mat-header-cell *matHeaderCellDef> Data consegna </th>
        <td mat-cell *matCellDef="let element"> {{element.date_delivery | date: 'dd/MM/yyyy'}} </td>
      </ng-container>
      <ng-container matColumnDef="price_awarded">
        <th mat-header-cell *matHeaderCellDef> Valore </th>
        <td mat-cell *matCellDef="let element"> {{element.price_awarded | currency: ' € '}} </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Azioni </th>
        <td mat-cell *matCellDef="let element" style="text-align: center;">
          <a *ngIf="userprivilege != 'viewer'" matTooltip="Visualizza commessa" matTooltipPosition="below" [routerLink]="['/helm/commesse/view/', element.orderId]"><i class="material-icons nebula-dark">remove_red_eye</i></a>
          <a *ngIf="userprivilege != 'viewer'" matTooltip="Visualizza repository" matTooltipPosition="below" style="cursor: pointer;" (click)="navigateTo(element.code)"><i class="material-icons nebula-dark">folder</i></a>
          <a *ngIf="userprivilege == 'admin'" matTooltip="Edita commessa" matTooltipPosition="below" [routerLink]="['/helm/commesse/edit/', element.orderId]"><i class="material-icons nebula-dark">edit</i></a>
          <a *ngIf="userprivilege == 'admin'" matTooltip="Elimina commessa" matTooltipPosition="below" routerLink="/helm/commesse/all" (click)="openDialog(element.orderId)"><i class="material-icons nebula-dark">delete</i></a>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </mat-card-content>
</mat-card>
