import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { IntesaService } from 'src/app/services/intesa.service';

@Component({
  selector: 'app-elenco-plan',
  templateUrl: './elenco-plan.component.html',
  styleUrls: ['./elenco-plan.component.scss']
})
export class ElencoPlanComponent implements OnInit {

  allplans: string[]= [];
  selectedplan: string;
  allplansrc: string[] = [];
  searchinput: string = "";

  @Output() setSelPlan = new EventEmitter<string>();

  constructor(private intsrv: IntesaService) {
    this.LoadPlans();
  }

  ngOnInit(): void {

  }

  SelPlan(plan:string){
    this.selectedplan = plan;
    localStorage.setItem('selectedPlan', plan);
    this.setSelPlan.emit(this.selectedplan);
  }

  private LoadPlans(){
    this.intsrv.LoadAllPlans()
      .subscribe( res => {
        if(res != undefined && res.length > 0){
          this.allplans = res;
          this.allplansrc = res;
          this.selectedplan = this.allplans[0];
          localStorage.setItem('selectedPlan', this.selectedplan);
        }
      },
      (error) => {
        console.log("Errore caricamento planimetrie", error);
      });
  }

  ricercaPlan(){
    this.searchinput == '' ? this.allplansrc = this.allplans : this.allplansrc = this.allplans.filter(plan => {return plan.toUpperCase().includes(this.searchinput.toUpperCase())});
  }
}
