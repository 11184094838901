<div class="maincontainer">
  <app-navbar></app-navbar>
  <mat-grid-list [cols]=12 class="appcontainer" rowHeight='fit'>
    <mat-grid-tile [colspan]=1>
      <!-- <app-menu-productline *ngIf="selectedProdlineId != 'TUTTE LE LINEE'" [selectedProdlineId]="selectedProdlineId"></app-menu-productline> -->
    </mat-grid-tile>
    <mat-grid-tile [colspan]=8>
      <img *ngIf="selectedOrderId == 'TUTTE LE COMMESSE'" [src]="nebulaImg" id="nebulaImg">
      <app-info-wbs *ngIf="selectedOrderId !== 'TUTTE LE COMMESSE' && wbsEnviromentType === 'info'" [selectedOrderId]="selectedOrderId"
        (setWbsEnvType)="SelEnviroment($event)"></app-info-wbs>
      <app-calendar-wbs *ngIf="selectedOrderId !== 'TUTTE LE COMMESSE' && wbsEnviromentType === 'calendar'" [selectedOrderId]="selectedOrderId"
        (setWbsEnvType)="SelEnviroment($event)"></app-calendar-wbs>
    </mat-grid-tile>
    <mat-grid-tile [colspan]=3>
      <app-elenco-orders (setSelOrder)="SelOrder($event)"></app-elenco-orders>
    </mat-grid-tile>
  </mat-grid-list>
</div>
