import { Component, OnInit} from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Commessa } from 'src/app/models/helm/commessa';
import { CompanyLight } from 'src/app/models/helm/company-light';
import { ProdMemb } from 'src/app/models/helm/prod-memb';
import { AuthUser } from 'src/app/models/shared/auth-user';
import { AutocompObj } from 'src/app/models/shared/autocomp-obj';
import { HelmService } from 'src/app/services/helm.service';
import { OrderService } from 'src/app/services/order.service';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import * as _moment from 'moment';
import { MY_FORMATS } from 'src/app/models/shared/datepickerformat';
import { CloudItem } from 'src/app/models/cde/cloud-item';

const moment =  _moment;


@Component({
  selector: 'app-edit-commessa',
  templateUrl: './edit-commessa.component.html',
  styleUrls: ['./edit-commessa.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
  ]
})
export class EditCommessaComponent implements OnInit {

  editableCommessaId: string;
  editableCommessaCode: string;
  editableCommessa: Commessa = {
    client: {
      address: '',
      companyId: '',
      logo: '',
      name: '',
      type: ''
    },
    code: '',
    color: '',
    cost_tot: null,
    description: '',
    date_delivery: null,
    date_offer: null,
    manager: null,
    name: '',
    note: '',
    orderId: null,
    price_awarded: null,
    members: [],
    status: 'active',
    year: 2022
  };

  members: ProdMemb[] = [];

  date_offer = new FormControl(moment());
  date_delivery = new FormControl(moment());

  links: CloudItem[] = [];

  linkDisplayedColumns = ['folder', 'link', 'actions'];
  linkDataSource = new MatTableDataSource<CloudItem>();

  users: AuthUser[] = [];
  usersForSel: AutocompObj[] = [];
  ownerCtrl = new FormControl();
  filteredusrsowner: Observable<AutocompObj[]>;
  selectedowner: AutocompObj;

  memberCtrl = new FormControl();
  filteredusrsmember: Observable<AutocompObj[]>;
  selectedmember: AutocompObj;

  companies: CompanyLight[]= [];
  companiesForSel: AutocompObj[] = [];
  companiesCtrl = new FormControl();
  filteredcompanies: Observable<AutocompObj[]>;
  selectedcompany: AutocompObj;

  displayedColumns = ['memb', 'permission', 'actions'];
  dataSource = new MatTableDataSource<ProdMemb>();

  constructor(private helmsrv: HelmService, private activatedRoute: ActivatedRoute, private ordsrv: OrderService, private router: Router, public snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.editableCommessaId = this.activatedRoute.snapshot.params.id;
    this.LoadSettings();
  }

  LoadSettings(){
    this.ordsrv.LoadSelectedCommessa(this.editableCommessaId)
      .subscribe(res => {
        this.editableCommessa = res;
        this.editableCommessaCode = this.editableCommessa.code;
        for(let memb of this.editableCommessa.members){
          let member: ProdMemb= {
            member: {id: memb.user.userId, text: `${memb.user.surname} ${memb.user.name}  - ${memb.user.company.name}`},
            permission: memb.permission,
            privilege: memb.user.privilege
          }
          this.members.push(member);
        }
        this.dataSource.data = this.members;
        this.helmsrv.GetLinks(this.editableCommessaId, 'O')
          .subscribe(res => {
            this.links = res;
            this.linkDataSource.data = this.links;
            this.helmsrv.LoadAllUsersAuth()
            .subscribe((res) => {
              this.users = res
              for(let usr of this.users){
                this.usersForSel.push({id: usr.userId, text: `${usr.surname} ${usr.name}  - ${usr.company.name}`})
                if(this.editableCommessa.manager == null && usr.userId == localStorage.getItem('id'))
                  this.selectedowner = {id: usr.userId, text: `${usr.surname} ${usr.name}  - ${usr.company.name}`};
              }
              this.filteredusrsowner = this.ownerCtrl.valueChanges
                .pipe(
                  startWith(''),
                  map(usr => this._filterUsr(usr))
                );
              this.filteredusrsmember = this.memberCtrl.valueChanges
                .pipe(
                  startWith(''),
                  map(usr => this._filterUsr(usr))
                );
              this.helmsrv.LoadAllCompaniesLight()
                .subscribe(res => {
                  this.companies = res;
                  for(let comp of this.companies){
                    this.companiesForSel.push({id: comp.companyId, text: comp.name});
                  }
                  if(this.selectedowner == undefined &&  this.editableCommessa.manager != null)
                    this.selectedowner = {id: this.editableCommessa.manager.userId, text: `${this.editableCommessa.manager.surname} ${this.editableCommessa.manager.name}  - ${this.editableCommessa.manager.company.name}`};
                  if(this.selectedcompany == undefined && this.editableCommessa.client != undefined)
                    this.selectedcompany = {id: this.editableCommessa.client.companyId, text: this.editableCommessa.client.name};
                  this.filteredcompanies = this.companiesCtrl.valueChanges
                    .pipe(
                      startWith(''),
                      map(comp => this._filterComp(comp))
                    );
                })
            });
          })
      })
  }

  SaveOrder(form: NgForm){
    if(typeof(this.selectedowner) != 'object'){
      this.snackBar.open("Nessun proprietario selezionato", "OK", {
        duration: 5000,
        horizontalPosition: "center",
        verticalPosition: "bottom"
      })
    }
    else{
      let doff = this.editableCommessa.date_offer;
      let ddel = this.editableCommessa.date_delivery;
      this.editableCommessa = {...form.value};
      this.editableCommessa.members = [];
      if(this.selectedowner != undefined && typeof(this.selectedowner) == 'object')
        this.editableCommessa.manager = this.users.filter(usr => usr.userId == this.selectedowner.id)[0];
      if(this.selectedcompany != undefined && typeof(this.selectedcompany) == 'object')
        this.editableCommessa.client = this.companies.filter(comp => comp.companyId == this.selectedcompany.id)[0];
      for(let memb of this.members){
        this.editableCommessa.members.push({user: this.users.filter(usr => usr.userId == memb.member.id)[0], permission: memb.permission});
      }
      this.editableCommessa.orderId = this.editableCommessaId;
      this.editableCommessa.date_offer = doff;
      this.editableCommessa.date_delivery = ddel;

      this.ordsrv.UpdateCommess(this.editableCommessa)
        .subscribe(res => {
          console.log(res);
          this.router.navigateByUrl('/helm/commesse/all');
        })
    }
    //console.log("COMMESSA ",this.editableCommessa);
  }

  addMember(){
    if(typeof(this.selectedmember) === 'object'){
      let usr = this.users.filter(u => u.userId == this.selectedmember.id);
      let newmemb = {member: this.selectedmember, privilege: usr[0].privilege, permission: false}
      if(this.members.filter(mem => mem.member.id === newmemb.member.id).length === 0 && this.selectedmember.id != this.selectedowner.id){
        this.members.push(newmemb);
        this.dataSource.data = this.members;
      }
      this.memberCtrl.setValue('');
    }
    else this.memberCtrl.setValue('');
  }

  remMemb(usr: ProdMemb){
    let delusr = this.members.filter(mem => mem.member.id == usr.member.id)[0];
    let index = this.members.indexOf(delusr)
    this.members.splice(index, 1);
    this.dataSource.data = this.members;
  }

  private _filterUsr(value: string): AutocompObj[] {
    if(typeof(value) === 'string'){
      const filterValue = value?.toLowerCase() ?? '';
      return this.usersForSel.filter(usr => usr.text.toLowerCase().indexOf(filterValue) > -1);
    }
  }

  private _filterComp(value: string): AutocompObj[] {
    if(typeof(value) === 'string'){
      const filterValue = value?.toLowerCase() ?? '';
      return this.companiesForSel.filter(comp => comp.text.toLowerCase().indexOf(filterValue) > -1);
    }
  }

  public getDisplayFn() {
    return (val) => this.display(val);
  }

  private display(user): string {
    //access component "this" here
    return user ? user.text : user;
  }
}
