import { Component, OnInit } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Router } from '@angular/router';
import { CostSet } from 'src/app/models/helm/cost-set';
import { Order } from 'src/app/models/helm/order';
import { OrderCost } from 'src/app/models/helm/order-cost';
import { MY_FORMATS } from 'src/app/models/shared/datepickerformat';
import * as _moment from 'moment';
import { OrderService } from 'src/app/services/order.service';
import { MatDialog } from '@angular/material/dialog';
import { CostSetDialogComponent } from '../../shared/cost-set-dialog/cost-set-dialog.component';

const moment =  _moment;

@Component({
  selector: 'app-eo-cost',
  templateUrl: './eo-cost.component.html',
  styleUrls: [
    './eo-cost.component.scss',
    '../../../../../app.component.scss'
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
  ]
})
export class EoCostComponent implements OnInit {
  date_offer = new FormControl(moment());
  date_delivery = new FormControl(moment());
  date_acquisition = new FormControl(moment());

  editableOrder: Order;
  newCost: OrderCost;
  allcostset: CostSet[];

  constructor(private ordsrv: OrderService, private router: Router, public dialog: MatDialog) {
    this.editableOrder = this.ordsrv.GetOrder();
  }

  ngOnInit(): void {
    this.newCost = this.editableOrder.costs;
    this.SetDaysPlannned();
    if(this.newCost.ext_supp_cost == 0) this.newCost.ext_supp_cost = undefined;
    if(this.newCost.gest_clnt_cost == 0) this.newCost.gest_clnt_cost = undefined;
    if(this.newCost.hw_sw_cost == 0) this.newCost.hw_sw_cost = undefined;
    if(this.newCost.days_cost == 0) this.newCost.days_cost = undefined;
    if(this.newCost.proj_manag_cost == 0) this.newCost.proj_manag_cost = undefined;
    if(this.newCost.rapp_expenses == 0) this.newCost.rapp_expenses = undefined;
    if(this.newCost.var_expenses == 0) this.newCost.var_expenses = undefined;
  }

  SetDaysPlannned(){
    this.newCost.days_assigned = 0;
    for(let act of this.editableOrder.activity_planned){
      if(act.durata != undefined) this.newCost.days_assigned = this.newCost.days_assigned + parseFloat((act.durata/8).toFixed(1));
    }
  }

  navigate(form: NgForm, area: string){
    this.editableOrder.costs = {
      date_acquisition: this.date_acquisition.value,
      days_assigned: this.newCost.days_assigned,
      days_done: this.newCost.days_done ? this.newCost.days_done : 0,
      days_cost: this.newCost.days_cost ? this.newCost.days_cost : 0,
      days_planned: this.newCost.days_planned,
      ext_supp_cost: this.newCost.ext_supp_cost ? this.newCost.ext_supp_cost : 0,
      gest_clnt_cost: this.newCost.gest_clnt_cost ? this.newCost.gest_clnt_cost : 0,
      hw_sw_cost: this.newCost.hw_sw_cost ? this.newCost.hw_sw_cost : 0,
      price_offered: this.newCost.price_offered,
      proj_manag_cost: this.newCost.proj_manag_cost ? this.newCost.proj_manag_cost : 0,
      rapp_expenses: this.newCost.rapp_expenses ? this.newCost.rapp_expenses : 0,
      var_expenses: this.newCost.var_expenses ? this.newCost.var_expenses : 0
    } ;
    this.editableOrder.date_offer = this.date_offer.value;
    this.editableOrder.date_delivery = this.date_delivery.value;
    // this.editableOrder = {...form.value};
    this.ordsrv.SetOrder(this.editableOrder);
    this.router.navigateByUrl(`/helm/orders/edit/${area}/${this.editableOrder.orderId}`);
  }


}
