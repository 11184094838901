<div class="maincontainer">
  <app-navbar *ngIf="!link"></app-navbar>
  <app-navbar-light *ngIf="link"></app-navbar-light>
  <div class="appcontainer">

    <div class="flex-container">
      <mat-card id="tree-card" *ngIf="!link">
        <mat-card-title id="tree-card-title">
          <span class="underline-text-nebula">
            <h5 class="nebula-dark left" class="assettitle">{{filename}}</h5>
          </span>
        </mat-card-title>
        <mat-card-content>

        </mat-card-content>
      </mat-card>

      <div id="funcBtns" *ngIf="!link">
        <a mat-mini-fab color="primary" matTooltip="Ricarica modello" ><i class="material-icons">refresh</i></a>
        <!-- <a mat-mini-fab color="primary" matTooltip="Evidenzia parti" (click)="enlightParts()"><i class="material-icons">search</i></a> -->
        <a *ngIf="!link" mat-mini-fab color="primary" matTooltip="Markup" (click)="loadMarkup()">
          <i class="material-icons">photo_camera</i></a>
        <a *ngIf="!link" mat-mini-fab color="primary" matTooltip="Download elenco ricambi">
          <i class="material-icons">download</i></a>
      </div>
      <div id="viewer-container" [style]="hidecanvas">
        <div class="scene">
          <div class="cube" #cube>
            <div class="cube__face cube__face--front noselect" #Front id="cfront">F</div>
            <div class="cube__face cube__face--back noselect" #Back id="cback">Ba</div>
            <div class="cube__face cube__face--right noselect" #Right id="cright">R</div>
            <div class="cube__face cube__face--left noselect" #Left id="cleft">L</div>
            <div class="cube__face cube__face--top noselect" #Top id="ctop">T</div>
            <div class="cube__face cube__face--bottom noselect" #Bottom id="cbottom">Bo</div>
          </div>
        </div>
        <div class="spinner" #spinner  id="spinner"></div>
        <canvas id="canvas" #canvas style="height:100%; width:100%;"></canvas>
      </div>
      <app-markup-draw class="viewer-container" style="max-height: 650px; max-width: 1000px;" [background]="markupImg" [modelName]="filename" [prodcode]="prodcode"
        (exit)="exitMarkup($event)" *ngIf="markup"></app-markup-draw>
    </div>
  </div>
</div>

