<div id="smenucontainer">
  <button mat-fab class="menubtn" color="primary"routerLink="/activities" matTooltip="ATTIVITA'" matTooltipPosition="right">
    <i class="material-icons">fact_check</i></button>
  <a mat-fab class="menubtn" color="primary" (click)="navigateTo()"  matTooltip="DOCUMENTI" matTooltipPosition="right">
    <i class="material-icons">folder</i></a>
  <button mat-fab class="menubtn" color="primary" (click)="LoadGallery()" matTooltip="MULTIMEDIA" matTooltipPosition="right">
    <i class="material-icons">mms</i></button>
  <button mat-fab class="menubtn" color="primary" (click)="Load3D()" matTooltip="3D" matTooltipPosition="right">
    <i class="material-icons">3d_rotation</i></button>
</div>

