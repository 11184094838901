<div id="codecontainer" *ngIf="loaded">
  <mat-card id="codercard">
    <mat-card-header>
      <span class="underline-text-nebula">
        <h5 class="mdc-text left" class="assettitle">Nuovo codice</h5>
      </span>
      <div class="right">
        <button mat-fab class="menubtn" color="primary" matTooltip="Seleziona codice" matTooltipPosition="below" routerLink="/coder/generator">
          <i class="material-icons">list</i></button>
      </div>
    </mat-card-header>
    <mat-card-content>
      <form #fm="ngForm">
        <div class="internalMatGrid">
          <div id="col-left">
            <mat-form-field class="row">
              <mat-label>Livello 1</mat-label>
              <mat-select name="level1selected" [(ngModel)]="level1selected" (selectionChange)="loadCodes(1)">
                <mat-option *ngFor="let lev1 of alllevel1" [value]="lev1">{{lev1.code}} - {{lev1.description}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="row">
              <mat-label>Livello 2</mat-label>
              <mat-select name="level2selected" [(ngModel)]="level2selected" (selectionChange)="loadCodes(2)">
                <mat-option *ngFor="let lev2 of level2forsel" [value]="lev2">{{lev2.code}} - {{lev2.description}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="row">
              <mat-label>Livello 3</mat-label>
              <mat-select name="level3selected" [(ngModel)]="level3selected" (selectionChange)="loadCodes(3)">
                <mat-option *ngFor="let lev3 of level3forsel" [value]="lev3">{{lev3.code}} - {{lev3.description}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div id="col-right">
            <div class="row">
              <mat-form-field id="code">
                <mat-label>Codice</mat-label>
                <input matInput type="text" name="code" [(ngModel)]="newcode.code" required (input)="PreviewCode()">
              </mat-form-field>
              <mat-form-field id="description">
                <mat-label>Descrizione</mat-label>
                <input matInput type="text" name="description" [(ngModel)]="newcode.description" required (input)="PreviewCode()">
              </mat-form-field>

            </div>
            <div class="row">
              <mat-form-field *ngIf="codepreview !== ''" id="newcode">
                <mat-label>Nuovo codice</mat-label>
                <input matInput type="text" name="codepreview" [ngModel]="codepreview" readonly>
              </mat-form-field>
              <button mat-fab class="menubtn" color="primary" *ngIf="codepreview !== ''" matTooltip="Salva" matTooltipPosition="below" (click)="SaveCode()" [disabled]="fm.invalid" type="submit">
                <i class="material-icons">save</i></button>
            </div>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>

  <mat-card id="historycard">
    <mat-card-header>
      <span class="underline-text-nebula">
        <h5 class="mdc-text left" class="assettitle">Codici generati</h5>
      </span>
    </mat-card-header>
    <mat-card-content>
      <div class="historycardcontent" *ngFor="let hc of historycodes">
        <div class="c1">
          <h5>{{hc.description}}</h5>
          <h3>{{hc.code}}</h3>
          <h5>{{hc.date}}</h5>
        </div>
        <button mat-fab class="menubtn" color="primary" matTooltip="Copia codice" matTooltipPosition="below" (click)="CopyCode(hc)">
          <i class="material-icons">content_copy</i></button>
      </div>
    </mat-card-content>
  </mat-card>
</div>


