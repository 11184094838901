import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { CloudItem } from 'src/app/models/cde/cloud-item';
import { LinkModel } from 'src/app/models/cde/link-model';
import { CdeServiceService } from 'src/app/services/cde-service.service';
import * as _moment from 'moment';
import { MY_FORMATS } from 'src/app/models/shared/datepickerformat';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

@Component({
  selector: 'app-link-dialog',
  templateUrl: './link-dialog.component.html',
  styleUrls: ['./link-dialog.component.scss'],
  providers: [{
    provide: DateAdapter,
    useClass: MomentDateAdapter,
    deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
  },
  {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}]
})

export class LinkDialogComponent implements OnInit {

  link: LinkModel = {
    path : "",
    prod_id: "",
    prod_type: "",
    expired : {
      read: 'r',
      private: false,
      password: "",
      never: false,
      date: new Date(),
      view: false
    }
  };

  breakpoint: number = 3;
  genLink: string = "";

  constructor(public dialogRef: MatDialogRef<LinkDialogComponent>,
    private cdeService: CdeServiceService,
    @Inject(MAT_DIALOG_DATA) public data: CloudItem,
    private _snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.breakpoint = (window.innerWidth <= 800) ? 1 : 3;
    this.cdeService.getCdeLinkInfo(this.data.link.slice(this.data.link.indexOf('link?')+5))
      .subscribe(res => {
        this.link.expired.private = res.expired.private;
        this.link.expired.read = res.expired.read;
        this.link.expired.password = res.expired.password;
      })
    this.genLink = this.data.link.replace("https://nebulateam.bimspace.it", "http://localhost:4200");

  }

  createLink(): void {
    this.link.path = this.data.abs_path;
    this.link.prod_id = this.data.prod_id;
    this.link.prod_type = this.data.prod_type;
    this.cdeService
      .createCdeLink(this.link)
        .subscribe(data => {
          this.genLink = data.link;
          this._snackBar.openFromComponent(SnackBarComponent, {
            duration: 4 * 1000,
            data: data.link
          });
        })

  }

  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 800) ? 1 : 3;
  }

}

@Component({
  selector: 'app-snack-bar-component',
  template: `
    <span class="example-snackbar">
      {{data}}
    </span>
  `,
  styles: [`
    .example-snackbar{
      color: white;
    }
  `],
})
export class SnackBarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: string){}
}
