<style type='text/css'>

  body {
    touch-action: none;
    margin: 0;
    border: 0 none;
    padding: 0;
    text-align: center;
    background-color: black;
  }

  #canvas {
    display: block;
    margin: 0;
    color: white;
  }

  #canvas:focus {
    outline: none;
  }

  .godot {
    font-family: 'Noto Sans', 'Droid Sans', Arial, sans-serif;
    color: #e0e0e0;
    background-color: #3b3943;
    background-image: linear-gradient(to bottom, #403e48, #35333c);
    border: 1px solid #45434e;
    box-shadow: 0 0 1px 1px #2f2d35;
  }


  /* Status display
   * ============== */

  #status {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    /* don't consume click events - make children visible explicitly */
    visibility: hidden;
  }

  #status-progress {
    width: 366px;
    height: 7px;
    background-color: #38363A;
    border: 1px solid #444246;
    padding: 1px;
    box-shadow: 0 0 2px 1px #1B1C22;
    border-radius: 2px;
    visibility: visible;
  }

  @media only screen and (orientation:portrait) {
    #status-progress {
      width: 61.8%;
    }
  }

  #status-progress-inner {
    height: 100%;
    width: 0;
    box-sizing: border-box;
    transition: width 0.5s linear;
    background-color: #202020;
    border: 1px solid #222223;
    box-shadow: 0 0 1px 1px #27282E;
    border-radius: 3px;
  }

  #status-indeterminate {
    visibility: visible;
    position: relative;
  }

  #status-indeterminate > div {
    width: 4.5px;
    height: 0;
    border-style: solid;
    border-width: 9px 3px 0 3px;
    border-color: #2b2b2b transparent transparent transparent;
    transform-origin: center 21px;
    position: absolute;
  }

  #status-indeterminate > div:nth-child(1) { transform: rotate( 22.5deg); }
  #status-indeterminate > div:nth-child(2) { transform: rotate( 67.5deg); }
  #status-indeterminate > div:nth-child(3) { transform: rotate(112.5deg); }
  #status-indeterminate > div:nth-child(4) { transform: rotate(157.5deg); }
  #status-indeterminate > div:nth-child(5) { transform: rotate(202.5deg); }
  #status-indeterminate > div:nth-child(6) { transform: rotate(247.5deg); }
  #status-indeterminate > div:nth-child(7) { transform: rotate(292.5deg); }
  #status-indeterminate > div:nth-child(8) { transform: rotate(337.5deg); }

  #status-notice {
    margin: 0 100px;
    line-height: 1.3;
    visibility: visible;
    padding: 4px 6px;
    visibility: visible;
  }
</style>

<div class="maincontainer">
  <app-navbar></app-navbar>
  <div class="appcontainer">

    <div class="flex-container">
      <mat-card id="tree-card">
        <mat-card-title id="tree-card-title">
          <span class="underline-text-nebula">
            <h5 class="nebula-dark left" class="assettitle">{{filename}}</h5>
          </span>
        </mat-card-title>
        <mat-card-content>

        </mat-card-content>
      </mat-card>

      <div id="funcBtns">
        <a mat-mini-fab color="primary" matTooltip="Ricarica modello" ><i class="material-icons">refresh</i></a>
        <!-- <a mat-mini-fab color="primary" matTooltip="Evidenzia parti" (click)="enlightParts()"><i class="material-icons">search</i></a> -->
        <a mat-mini-fab color="primary" matTooltip="Markup">
          <i class="material-icons">photo_camera</i></a>
        <a mat-mini-fab color="primary" matTooltip="Download elenco ricambi">
          <i class="material-icons">download</i></a>
      </div>
      <div id="viewer-container">
        <iframe width="90%" height="90%" [src]="modelsrc | safe">
        </iframe>
      </div>
    </div>
  </div>
</div>
