import { Component, OnInit } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ProdMemb } from 'src/app/models/helm/prod-memb';
import { AuthUser } from 'src/app/models/shared/auth-user';
import { AutocompObj } from 'src/app/models/shared/autocomp-obj';
import { ProdLight } from 'src/app/models/shared/prod-light';
import { Prodline } from 'src/app/models/shared/prodline';
import { HelmService } from 'src/app/services/helm.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-new-productline',
  templateUrl: './new-productline.component.html',
  styleUrls: [
    './new-productline.component.scss',
    '../../../../app.component.scss'
  ]
})
export class NewProductlineComponent implements OnInit {

  newImg: string;
  newProdline: Prodline = {
    prodlineId: "",
    code: "",
    image: environment.imageUrl + "/defaultproductline.png",
    name: "",
    description: "",
    products: [],
    owner: {
      userId: "",
      name: "",
      surname: "",
      company: {
        companyId: "",
        name: "",
        address: "",
        type: "",
        logo: ""
      },
      privilege: "",
      user_type: ""
    },
    members: []
  }

  esiste: boolean = false;

  selectedprod: string;
  prodsForSel: string[] = [];
  prodCtrl = new FormControl();
  filteredprods: Observable<string[]>;

  usersForSel: AutocompObj[] = [];
  ownerForSel: AutocompObj[] = [];
  ownerCtrl = new FormControl();
  filteredusrsowner: Observable<AutocompObj[]>;
  selectedowner: AutocompObj;

  products: ProdLight[] = [];
  members: ProdMemb[] = [];
  users: AuthUser[];

  tempProdMemb: {product: ProdLight, members: ProdMemb[]}[] = [];

  proddisplayedColumns = ['code', 'name', 'actions'];
  proddataSource = new MatTableDataSource<ProdLight>();

  userproddisplayedColumns = ['memb', 'permission'];
  userproddataSource = new MatTableDataSource<ProdMemb>();

  constructor(private helmsrv: HelmService, private router: Router, public snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.LoadAllProducts();
  }

  LoadAllProducts(){
    this.helmsrv.LoadAllProductsLight()
      .subscribe((res) => {
        this.products = res
        for(let prod of this.products){
          this.prodsForSel.push(prod.code);
        }
        this.proddataSource.data = this.newProdline.products;
        this.userproddataSource.data = this.members;
        this.helmsrv.LoadAllUsersAuth()
          .subscribe(res => {
            this.users = res;
            this.filteredprods = this.prodCtrl.valueChanges
            .pipe(
              startWith(''),
              map(prod => this._filterProd(prod))
            );
            this.helmsrv.LoadAllUsersAuth()
              .subscribe((res) => {
                this.users = res
                for(let usr of this.users){
                  this.usersForSel.push({id: usr.userId, text: `${usr.surname} ${usr.name}  - ${usr.company.name}`})
                  if(usr.privilege == 'admin' || usr.privilege == 'superuser') this.ownerForSel.push({id: usr.userId, text: `${usr.surname} ${usr.name}  - ${usr.company.name}`})
                  if(usr.userId == localStorage.getItem('id')) this.selectedowner = {id: usr.userId, text: `${usr.surname} ${usr.name}  - ${usr.company.name}`};
                }
                this.filteredusrsowner = this.ownerCtrl.valueChanges
                .pipe(
                  startWith(''),
                  map(usr => this._filterOwnUsr(usr))
                );
              });
          })
      });
  }

  addProd(){
    let prod = this.products.filter(p => p.code == this.selectedprod)[0];
    if(prod != undefined && this.newProdline.products.filter(p => p.code == prod.code).length == 0){
      this.newProdline.products.push(prod);
      this.proddataSource.data = this.newProdline.products;
      this.helmsrv.GetProdMembers(prod.productId)
        .subscribe(res => {
          this.tempProdMemb.push({product: prod, members: res});
          for (let tpm of this.tempProdMemb){
            for(let memb of tpm.members){
              if(this.members.filter(m => m.member.id == memb.member.id).length == 0) this.members.push(memb);
            }
          }
          this.userproddataSource.data = this.members;
        })
    }
    this.prodCtrl.setValue('');
  }

  remProd(prod: ProdLight){
    this.newProdline.products.splice(this.newProdline.products.indexOf(prod), 1);
    this.tempProdMemb.splice(this.tempProdMemb.indexOf(this.tempProdMemb.filter(p => p.product.productId == prod.productId)[0]),1);
    this.members = [];
    for (let tpm of this.tempProdMemb){
      for(let memb of tpm.members){
        if(this.members.filter(m => m.member.id == memb.member.id).length == 0) this.members.push(memb);
      }
    }
    this.userproddataSource.data = this.members;
    this.proddataSource.data = this.newProdline.products;
  }

  readUrl(event: any): void{
    const reader = new FileReader();
    if (event.target.files && event.target.files.length){
      const[file] = event.target.files;
      this.newImg = file;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.newImg = reader.result as string;
        this.newProdline.image = this.newImg;
      };
    }
  }

  CheckCode(form: NgForm){
    this.newProdline = {...form.value};
    if(this.newImg != undefined) this.newProdline.image = this.newImg;
    else this.newProdline.image = environment.imageUrl + "/defaultproduct.png";
    this.helmsrv.CheckCode(this.newProdline.code, 'pl')
      .subscribe(res => {
        this.esiste = res;
      })
  }

  AddNewProdline(form: NgForm){
    let selprods = this.newProdline.products;
    if(this.newProdline.products.length > 0){
      this.newProdline = {...form.value};
      if(this.esiste){
        if(this.newImg != undefined) this.newProdline.image = this.newImg;
        else this.newProdline.image = environment.imageUrl + "/defaultproductline.png";
        this.snackBar.open("Il codice inserito appartiene già ad un'altra linea", "OK", {
          duration: 5000,
          horizontalPosition: "center",
          verticalPosition: "bottom"
        })
      }
      else{
        this.newProdline.owner = this.users.filter(usr => usr.userId == this.selectedowner.id)[0];
        if(this.newProdline.owner.privilege == 'viewer'){
          this.snackBar.open("Il proprietario non può essere un utente VIEWER", "OK", {
            duration: 5000,
            horizontalPosition: "center",
            verticalPosition: "bottom"
          })
        }
        else{
          if(this.newImg != undefined) this.newProdline.image = this.newImg;
          if(this.newProdline.image == environment.imageUrl + "/defaultproductline.png" || this.newProdline.image == undefined) this.newProdline.image = "";
          this.newProdline.members = new Array();
          for(let memb of this.members){
            this.newProdline.members.push({user: this.users.filter(usr => usr.userId == memb.member.id)[0], permission: memb.permission});
          }
          this.newProdline.products = selprods;
          this.helmsrv.AddNewProductline(this.newProdline)
            .subscribe(res => {
              this.router.navigateByUrl('/helm/productlines')},
            (error) => {
              console.log("Errore inserimento nuova linea", error);
            });
        }
      }
    }
    else{
      this.snackBar.open("Selezionare almeno un prodotto", "OK", {
        duration: 5000,
        horizontalPosition: "center",
        verticalPosition: "bottom"
      })
    }
  }

  private _filterProd(value: string): string[] {
    if(typeof(value) === 'string'){
      const filterValue = value?.toLowerCase() ?? '';
      return this.prodsForSel.filter(prod => prod.toLowerCase().indexOf(filterValue) > -1);
    }
  }

  private _filterUsr(value: string): AutocompObj[] {
    if(typeof(value) === 'string'){
      const filterValue = value?.toLowerCase() ?? '';
      return this.usersForSel.filter(usr => usr.text.toLowerCase().indexOf(filterValue) > -1);
    }
  }

  private _filterOwnUsr(value: string): AutocompObj[] {
    if(typeof(value) === 'string'){
      const filterValue = value?.toLowerCase() ?? '';
      return this.ownerForSel.filter(usr => usr.text.toLowerCase().indexOf(filterValue) > -1);
    }
  }

  public getDisplayFn() {
    return (val) => this.display(val);
  }

  private display(user): string {
    //access component "this" here
    return user ? user.text : user;
  }

}
