import { HttpClient } from '@angular/common/http';
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CalendarDateFormatter, CalendarEvent, CalendarEventAction, CalendarView, DAYS_OF_WEEK } from 'angular-calendar';
import { isSameDay, isSameMonth } from 'date-fns';
import { Subject } from 'rxjs';
import { ActivityCalendar } from 'src/app/models/helm/activity-calendar';
import { CustomDateFormatter } from 'src/app/models/shared/custom-date-formatter.provider';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-ao-calendar',
  templateUrl: './ao-calendar.component.html',
  styleUrls: [
    './ao-calendar.component.scss'
  ],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter
    }
  ]
})
export class AoCalendarComponent{

  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;


  annorif: string = (new Date()).getFullYear().toString();

  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();

  loaded = false;
  plannedactivities: ActivityCalendar[] = [];

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [];

  refresh: Subject<any> = new Subject();

  events: CalendarEvent[] = [];

  activeDayIsOpen: boolean = false;

  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  excludeDays: number[] = [0];

  weekendDays: number[] = [DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY];

  constructor(private ordrsrv: OrderService, private router: Router, private http: HttpClient) {
    this.LoadActivities();
  }

   LoadActivities(){
    this.loaded = false;
    this.events = [];
    let data = new Date();
    data.setFullYear(parseInt(this.annorif))
    this.viewDate = data;
    this.ordrsrv.LoadPlannedActivities(this.annorif)
    .subscribe(res => {
      console.log(res);
      this.plannedactivities = res;
      for(let act of this.plannedactivities){
        this.events.push({
          start: act.start,
          end: act.end,
          title: `${act.ordercode} - ${act.user.surname} ${act.user.name}`,
          color: {primary: act.color, secondary: 'black'},
          actions: this.actions,
          allDay: true,
          meta: {orderId: act.orderId}
        })
      }
      this.loaded = true;
    })
   }

   YearChanged(selector){
    this.annorif = selector.value;
    this.LoadActivities();
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    if(action == 'Clicked'){
      console.log(event.meta)
      this.router.navigateByUrl(`helm/orders/view/main/${event.meta.orderId}`)
    }
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }
}
