<div id="codecontainer"  *ngIf="loaded">
  <mat-card id="codercard">
    <mat-card-header>
      <span class="underline-text-nebula">
        <h5 class="mdc-text left" class="assettitle">Seleziona codice</h5>
      </span>
      <div class="right">
        <button *ngIf="userid === '9861887192'" mat-fab class="menubtn" color="primary" matTooltip="Nuovo codice" matTooltipPosition="below" routerLink="/coder/creator">
          <i class="material-icons">add</i></button>
      </div>
    </mat-card-header>
    <mat-card-content>
      <form #fm="ngForm">
        <div class="internalMatGrid">
          <div class="row">
            <mat-form-field>
              <mat-label>Seleziona prodotto</mat-label>
              <input matInput type="text" name="code" [(ngModel)]="selectedcode" [matAutocomplete]="autoMem" [formControl]="codeCtrl" style="font-size: 1.3em;" (input)="LoadCode()">
              <mat-autocomplete  #autoMem="matAutocomplete" [displayWith]="getDisplayFn()">
                <mat-option *ngFor="let code of filteredcodes | async" [value]="code">{{code.text}}</mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="row2" *ngIf="newcode !== null && newcode !== undefined">
            <mat-form-field id="prod">
              <mat-label>Prodotto selezionato</mat-label>
              <input matInput type="text" name="description" [ngModel]="newcode.description" readonly>
            </mat-form-field>
            <mat-form-field id="code">
              <mat-label>Codice</mat-label>
              <input matInput type="text" name="code" [ngModel]="newcode.code" readonly>
            </mat-form-field>
            <button mat-fab class="menubtn" color="primary" matTooltip="Salva codice" matTooltipPosition="below" (click)="RegisterCode()">
              <i class="material-icons">save</i></button>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>

  <mat-card id="historycard">
    <mat-card-header>
      <span class="underline-text-nebula">
        <h5 class="mdc-text left" class="assettitle">Codici generati</h5>
      </span>
    </mat-card-header>
    <mat-card-content>
      <div class="historycardcontent" *ngFor="let hc of historycodes">
        <div class="c1">
          <h5>{{hc.description}}</h5>
          <h3>{{hc.code}}</h3>
          <h5>{{hc.date}}</h5>
        </div>
        <button mat-fab class="menubtn" color="primary" matTooltip="Copia codice" matTooltipPosition="below" (click)="CopyCode(hc)">
          <i class="material-icons">content_copy</i></button>
      </div>
    </mat-card-content>
  </mat-card>
</div>


