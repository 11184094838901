import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-zoom-chart-wbs',
  templateUrl: './zoom-chart-wbs.component.html',
  styleUrls: ['./zoom-chart-wbs.component.scss']
})
export class ZoomChartWbsComponent implements OnInit {

  @Input() chartdata: any[] = [];
  @Input() code: string = '';
  @Input() yScaleMax: number = 10000;
  @Input() yAxisLabel: string = '%';

  view: any[] = [1300, 550];
  legend: boolean = true;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'WEEK';
  yScaleMin: number = 0;

  timeline: boolean = false;
  colorScheme = {
    domain: ['#505ba1', 'red', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };

  constructor() { }

  ngOnInit(): void {

  }

}

