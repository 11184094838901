<form #fm="ngForm">
  <mat-card class="helmcard">
    <mat-card-header>
      <span class="underline-text-nebula">
        <h5 class="mdc-text left" class="assettitle">Edita linea</h5>
      </span>
      <div class="right">
        <button mat-fab class="menubtn" color="primary" matTooltip="Salva" matTooltipPosition="below" (click)="EditProdline(fm)" [disabled]="fm.invalid" type="submit">
          <i class="material-icons">save</i></button>
        <button mat-fab class="menubtn" color="primary" matTooltip="Indietro" routerLink="/helm/productlines" matTooltipPosition="below">
          <i class="material-icons">close</i></button>
      </div>
    </mat-card-header>
    <mat-card-content>
      <div class="internalMatGrid">
        <mat-grid-list [cols]=5  rowHeight="65px">
          <mat-grid-tile [colspan]=1 [rowspan]=5>
            <div class="col-int">
              <button type="button" id="loadFileBtn">
                <input type="file" id="loadFileInput" hidden (change)="readUrl($event)" accept=".png,.jpg"/>
                <label for="loadFileInput"><img id="prodimg" [src]="editableProdline.image"></label>
              </button>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=2 [rowspan]=5>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Codice</mat-label>
                <input matInput type="text" name="code" [ngModel]="editableProdline.code" readonly>
              </mat-form-field>
              <mat-form-field class="row">
                <mat-label>Nome</mat-label>
                <input matInput type="text" name="name" [ngModel]="editableProdline.name" required>
              </mat-form-field>
              <mat-form-field class="row">
                <mat-label>Descrizione</mat-label>
                <input matInput type="text" name="description" [ngModel]="editableProdline.description" required>
              </mat-form-field>
              <mat-form-field class="row">
                <mat-label>Proprietario</mat-label>
                <input matInput type="text" name="owner" [(ngModel)]="selectedowner"  required [matAutocomplete]="autoOwn" [formControl]="ownerCtrl">
                <mat-autocomplete  #autoOwn="matAutocomplete" [displayWith]="getDisplayFn()">
                  <mat-option *ngFor="let usr of filteredusrsowner | async" [value]="usr">{{usr.text}}</mat-option>
                </mat-autocomplete>
              </mat-form-field>

            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=2 [rowspan]=8>
            <div  class="col-int">
              <div class="row" id="role-container">
                <mat-form-field id="role">
                  <mat-label>Aggiungi prodotto</mat-label>
                  <input matInput type="text" name="product" [(ngModel)]="selectedprod" [matAutocomplete]="autoMem" [formControl]="prodCtrl" >
                  <mat-autocomplete  #autoMem="matAutocomplete">
                    <mat-option *ngFor="let prod of filteredprods | async" [value]="prod">{{prod}}</mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <button mat-fab class="utilbtn" color="primary" type="button" (click)="addProd()"  matTooltip="Aggiungi prodotto"  matTooltipPosition="below">
                  <i class="material-icons">add</i></button>
              </div>

              <div *ngIf="editableProdline.products.length > 0" [ngfo]="{standalone: true}">
                <div class="col-int">
                  <table mat-table [dataSource]="proddataSource">
                    <ng-container matColumnDef="code" >
                      <th mat-header-cell *matHeaderCellDef > Codice </th>
                      <td mat-cell *matCellDef="let element"> {{element.code}} </td>
                    </ng-container>
                    <ng-container matColumnDef="name" >
                      <th mat-header-cell *matHeaderCellDef > Nome </th>
                      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                      <th mat-header-cell *matHeaderCellDef></th>
                      <td mat-cell *matCellDef="let element">
                        <a matTooltip="Rimuovi membro" [routerLink]="['/helm/productlines/edit/', editableProdlineId]" matTooltipPosition="below" (click)="remProd(element)"><i class="material-icons nebula-dark">delete</i></a>
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="proddisplayedColumns sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: proddisplayedColumns;"></tr>
                  </table>
                </div>
              </div>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=3 *ngIf="members.length > 0" [rowspan]=3>
            <div class="col-int"  style="overflow-y: auto;">
              <table mat-table [dataSource]="userproddataSource">
                <ng-container matColumnDef="memb" >
                  <th mat-header-cell *matHeaderCellDef > Utente </th>
                  <td mat-cell *matCellDef="let element"> {{element.member.text}} </td>
                </ng-container>
                <ng-container matColumnDef="permission" >
                  <th mat-header-cell *matHeaderCellDef> Permessi </th>
                  <td mat-cell *matCellDef="let element" >
                    <mat-select name="perm{{element.member.id}}" [(ngModel)]="element.permission">
                      <mat-option *ngIf="element.privilege != 'viewer'" [value]="true">Lettura/Scrittura</mat-option>
                      <mat-option [value]="false">Lettura</mat-option>
                    </mat-select>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="userproddisplayedColumns sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: userproddisplayedColumns;"></tr>
              </table>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=5 *ngIf="links.length > 0">
            <div class="col-int">
            </div>
          </mat-grid-tile>
          <mat-grid-tile [rowspan]=3 *ngIf="links.length > 0">
            <div class="col-int">
              <span class="underline-text-nebula2 left" style="align-self: flex-start; margin-left: 18%;">
                <h2 class="mdc-text ">Links</h2>
              </span>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=4 [rowspan]=5 *ngIf="links.length > 0">
            <div class="col-int">
              <div id="link-container">
                <table mat-table [dataSource]="linkDataSource" id="link-table">
                  <ng-container matColumnDef="folder" >
                    <th mat-header-cell *matHeaderCellDef style="width: 30%;"> File/Folder </th>
                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                  </ng-container>
                  <ng-container matColumnDef="link" style="width: 60%;">
                    <th mat-header-cell *matHeaderCellDef > Link </th>
                    <td mat-cell *matCellDef="let element"> {{element.link}} </td>
                  </ng-container>
                  <ng-container matColumnDef="actions" style="width: 10%;">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                      <a matTooltip="Edita link" matTooltipPosition="below" style="cursor: pointer;" (click)="openLinkCreation(element)"><i class="material-icons nebula-dark">edit</i></a>
                      <a matTooltip="Rimuovi link" [routerLink]="['/helm/products/edit/', editableProdId]" matTooltipPosition="below" (click)="openDialog(element)"><i class="material-icons nebula-dark">delete</i></a>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="linkDisplayedColumns sticky: true"></tr>
                  <tr mat-row *matRowDef="let row; columns: linkDisplayedColumns;"></tr>
                </table>
              </div>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
    </mat-card-content>
  </mat-card>
</form>
