<form #fm="ngForm">
  <mat-card class="helmcard">
    <mat-card-header>
      <span class="underline-text-nebula">
        <h5 class="mdc-text left" class="assettitle">Assegnazione risorse</h5>
      </span>
      <div class="right">
        <button mat-fab class="menubtn" color="primary"(click)="navigate(fm, 'cost')" matTooltip="Tempi e Costi" matTooltipPosition="below" type="submit">
          <i class="material-icons">euro</i></button>
        <button mat-fab class="menubtn" color="primary"(click)="navigate(fm, 'main')" matTooltip="Torna all'anagrafica" matTooltipPosition="below" type="button">
          <i class="material-icons">arrow_back</i></button>
        <button mat-fab class="menubtn" color="primary" matTooltip="Indietro" routerLink="/helm/orders" matTooltipPosition="below">
          <i class="material-icons">close</i></button>
      </div>
    </mat-card-header>
    <mat-card-content>
      <div class="internalMatGrid">
        <mat-grid-list [cols]=10  rowHeight="100px">
          <mat-grid-tile [colspan]=3>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Risorsa</mat-label>
                <input matInput type="text" name="res" [(ngModel)]="selectedres"  required [matAutocomplete]="auto" [formControl]="resCtrl">
                <mat-autocomplete  #auto="matAutocomplete" [displayWith]="getDisplayFn()">
                  <mat-option *ngFor="let res of filteredres | async" [value]="res">{{res.text}}</mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Data inizio</mat-label>
                <input matInput type="date" name="start" [ngModel]="daysStart" required>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile >
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Data fine</mat-label>
                <input matInput type="date" name="end" [ngModel]="daysEnd" required>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Luogo</mat-label>
                <mat-select name="luogo" [ngModel]="newActivity.luogo" required>
                  <mat-option value="Cliente">Cliente</mat-option>
                  <mat-option value="Sede">Sede</mat-option>
                  <mat-option value="Smartworking">Smartworking</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Tipo attività</mat-label>
                <mat-select name="tipoAtt" [(ngModel)]="newActivity.tipoAtt" required>
                  <mat-option value="Trainig">Trainig</mat-option>
                  <mat-option value="Trainig Interno">Trainig Interno</mat-option>
                  <mat-option value="Supporto">Supporto</mat-option>
                  <mat-option value="Consulenza">Consulenza</mat-option>
                  <mat-option value="Sviluppo">Sviluppo</mat-option>
                  <mat-option value="R&S - Demo">R&S - Demo</mat-option>
                  <mat-option value="PBIM">PBIM</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Durata</mat-label>
                <mat-select name="fullday" [(ngModel)]="durata" required>
                  <mat-option value="Giornata Intera">Giornata Intera</mat-option>
                  <mat-option value="Mezza giornata">Mezza giornata</mat-option>
                  <mat-option value="Intervento breve">Intervento breve</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Ore</mat-label>
                <input matInput type="number" name="ore" [ngModel]="ore" [required]="durata==='Intervento breve'" [readonly]="durata!=='Intervento breve'">
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <button mat-fab color="primary" class="utilbtn" (click)="AddRes(fm)" [disabled]="fm.invalid" type="submit" matTooltip="Aggiungi risorsa"  matTooltipPosition="below">
                <i class="material-icons">add</i></button>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
      <table mat-table [dataSource]="dataSource" class="internalTable" *ngIf="newOrder.activity_planned != undefined && newOrder.activity_planned.length > 0">
        <ng-container matColumnDef="user">
          <th mat-header-cell *matHeaderCellDef> Risorsa </th>
          <td mat-cell *matCellDef="let element"> {{element.user.surname}} {{element.user.name}}</td>
        </ng-container>
        <ng-container matColumnDef="start">
          <th mat-header-cell *matHeaderCellDef> Data </th>
          <td mat-cell *matCellDef="let element"> {{element.start | date: 'dd/MM/yyyy'}} </td>
        </ng-container>
        <ng-container matColumnDef="luogo">
          <th mat-header-cell *matHeaderCellDef> Luogo </th>
          <td mat-cell *matCellDef="let element"> {{element.luogo}} </td>
        </ng-container>
        <ng-container matColumnDef="tipoAtt">
          <th mat-header-cell *matHeaderCellDef> Tipo di attività</th>
          <td mat-cell *matCellDef="let element"> {{element.tipoAtt}} </td>
        </ng-container>
        <ng-container matColumnDef="days">
          <th mat-header-cell *matHeaderCellDef> Giornate </th>
          <td mat-cell *matCellDef="let element"> {{element.durata/8}} </td>
        </ng-container>
        <ng-container matColumnDef="hours">
          <th mat-header-cell *matHeaderCellDef> Ore </th>
          <td mat-cell *matCellDef="let element"> {{element.durata}} </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> Azioni </th>
          <td mat-cell *matCellDef="let element">
            <a matTooltip="Elimina" matTooltipPosition="below" routerLink="/helm/orders/new/activity" (click)="RemRes(element.tempId)"><i class="material-icons nebula-dark">delete</i></a>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </mat-card-content>
  </mat-card>
</form>

